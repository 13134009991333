import ApButton from "common/ApButton/ApButton";
import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import ApReactTable, { colPreset } from "common/ApReactTable/ApReactTable";
import SvgIcon from "common/SvgIcon/SvgIcon";
import moment from "moment";
import React from "react";
import autobind from "react-autobind";
import api from "services/Api/Api";
import { errorPopper, getExcel, tr } from "services/Helpers/Helpers";
import _ from "lodash";
import axios from "axios";

class TrackingReportsIncomeStatement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cancelToken: axios.CancelToken.source(),
            data: [],

            start: null,
            end: null,

            selectedType: localStorage.getItem('tracking_reports_income_statement_type') ? localStorage.getItem('tracking_reports_income_statement_type') : 'simple',

            unparsedResponseData: []
        };
        autobind(this);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.project && (prevProps.project.id !== this.props.project.id)) {
            this.getData();
        }

        if (prevState.selectedType !== this.state.selectedType) {
            this.getData()
        }
    }

    handleTypeChange(e) {
        localStorage.setItem('tracking_reports_income_statement_type', e.target.value);
        this.setState({ selectedType: e.target.value });
    }

    handleDataParse(data) {
        let parsedData = [];
        switch (this.state.selectedType) {
            case "simple":
            case "simple_no_installations":
                parsedData = this.parseResponseDataSimple(data);
                break;

            case "full":
            case "full_no_installations":
                parsedData = this.parseResponseDataFull(data);
                break;

            default:
                break;
        }
        return parsedData;
    }

    getData() {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        let cancelToken = axios.CancelToken.source();
        this.setState({ loading: true, cancelToken });
        api({
            url: `projects/tracking/incomestatement/${this.props.project.id}/${this.state.selectedType}`,
            method: "POST",
            data: {
                start: this.state.start,
                end: this.state.end,
            },
            cancelToken: cancelToken.token,
        }).then(response => {
            // console.log("res", response);
            let data = this.handleDataParse(response)

            this.setState({ loading: false, cancelToken: null, data, unparsedResponseData: response });
        }).catch(error => {
            this.setState({ loading: false, cancelToken: null });
            if (axios.isCancel(error)) { return null };
            console.error(error);
            errorPopper(error, tr('get_error'));
        });
    }

    parseResponseDataSimple(response) {
        const data = [{
            event: tr('sales'),
            expenses: 0,
            revenue: response.ordersPriceTotal,
            in_total: ''
        }];

        if (this.props.project && !this.props.project.billing_hourly) {
            data.push({
                event: tr('unsent_payment_posts'),
                expenses: 0,
                revenue: response.unsentPaymentPostsTotal,
                in_total: ''
            });
        }

        data.push({
            event: tr('purchases'),
            expenses: response.purchaseOrdersPriceTotal,
            revenue: 0,
            in_total: ''
        });

        // no empty installations row if type is simple_no_installations
        if (this.state.selectedType === 'simple') {
            data.push({
                event: tr('installations'),
                expenses: response.installationsTotal,
                revenue: 0,
                in_total: ''
            });
        }

        data.push(
            {
                event: tr('personnel_costs'),
                expenses: Number(response.salaries),
                revenue: 0,
                in_total: ''
            },
            {
                event: tr('in_total'),
                expenses: Number(response.salaries) + Number(response.purchaseOrdersPriceTotal) + Number(response.installationsTotal),
                revenue: Number(response.unsentPaymentPostsTotal) + Number(response.ordersPriceTotal),
                in_total:
                    (Number(response.unsentPaymentPostsTotal) + Number(response.ordersPriceTotal)) -
                    (Number(response.salaries) + Number(response.purchaseOrdersPriceTotal) + Number(response.installationsTotal))
            }
        );

        return data;
    }

    parseResponseDataFull(response) {
        const data = [];
        let ordersTotal = 0;
        let purchaseOrdersTotal = 0;
        let installationsTotal = 0;

        for (const order of response.orders) {
            let orderTotal = order.row_total;
            ordersTotal += Number(orderTotal);
            data.push({
                event: `${tr('sales_order_number')} ${order.number}`,
                expenses: 0,
                revenue: orderTotal,
                in_total: '',
                orderId: order.id
            });
        }

        if (this.props.project && !this.props.project.billing_hourly) {
            data.push({
                event: tr('unsent_payment_posts'),
                expenses: 0,
                revenue: response.unsentPaymentPostsTotal,
                in_total: ''
            });
        }

        if (Array.isArray(response.purchaseOrders)) {
            for (const po of response.purchaseOrders) {
                purchaseOrdersTotal += Number(po.price);
                data.push({
                    event: `${tr('po_number')} ${po.number}`,
                    expenses: po.price,
                    revenue: 0,
                    in_total: '',
                    poId: po.id
                });
            }
        }

        if (Array.isArray(response.installations)) {
            response.installations = _.orderBy(response.installations, item => item.name ? item.name.toLowerCase() : null, 'asc');
            for (const installation of response.installations) {
                installationsTotal += Number(installation.price);
                data.push({
                    event: `${tr('installation')} ${installation.name}`,
                    expenses: installation.price,
                    revenue: 0,
                    in_total: ''
                });
            }
        }

        let sortedSalaries = _.orderBy(
            response.salaries,
            item => item.full_name
                ? item.full_name.toLowerCase()
                : null,
            'asc'
        );

        const salaryRows = [];
        for (const item of sortedSalaries) {
            salaryRows.push({
                event: `${tr('personnel_costs')} ${item.full_name}`,
                expenses: item.total_money,
                revenue: 0,
                in_total: ''
            })
        }
        if (salaryRows.length) {
            data.push(...salaryRows);
        }

        const salaryTotal = Array.isArray(response.salaries) ? response.salaries.reduce((total, item) => total += Number(item.total_money), 0) : 0;
        const total = {
            event: tr('in_total'),
            expenses: Number(salaryTotal) + Number(purchaseOrdersTotal) + Number(installationsTotal),
            revenue: Number(response.unsentPaymentPostsTotal) + Number(ordersTotal),
            in_total:
                (Number(response.unsentPaymentPostsTotal) + Number(ordersTotal)) -
                (Number(salaryTotal) + Number(purchaseOrdersTotal) + Number(installationsTotal))
        }
        data.push(total);

        return data;
    }

    getColumns() {
        let columns = [
            {
                id: 'event',
                Header: tr('event'),
                accessor: 'event',
                customizable: false,
                showInitially: true,
                customFilter: {
                    type: "text",
                    placeholder: tr('event'),
                },
            },
            colPreset({
                type: 'currency',
                id: 'revenue',
                Header: tr('revenue'),
                accessor: 'revenue',
                customizable: false,
                showInitially: true,
                customFilter: {
                    type: "text",
                    placeholder: tr('revenue'),
                },
            }),
            colPreset({
                type: 'currency',
                id: 'expenses',
                Header: tr('expenses'),
                accessor: 'expenses',
                customizable: false,
                showInitially: true,
                customFilter: {
                    type: "text",
                    placeholder: tr('expenses'),
                },
            }),
            colPreset({
                type: 'currency',
                id: 'in_total',
                Header: tr('in_total'),
                accessor: 'in_total',
                customizable: false,
                showInitially: true,
                customFilter: {
                    type: "text",
                    placeholder: tr('in_total'),
                },
            }),
            {
                id: 'sales_margin_percentage',
                Header: tr('sales_margin_percentage'),
                accessor: 'sales_margin_percentage',
                customizable: false,
                sortable: false,
                showInitially: true,
                align: 'right',
                Cell: row => {
                    if (row.original.in_total && row.original.revenue) {
                        return `${Number((row.original.in_total / row.original.revenue) * 100).toFixed(2).replace('.', ',')}%`;
                    }
                    return '';
                },
                customFilter: {
                    type: "text",
                    placeholder: tr('sales_margin_percentage'),
                },
            },
        ];

        return columns;
    }

    async getExcel() {
        const data = _.cloneDeep(this.state.data);
        const excelData = data.map(row => {
            return {
                event: row.event,
                revenue: Number(Number(row.revenue).toFixed(2)),
                expenses: Number(Number(row.expenses).toFixed(2)),
                in_total: !row.in_total ? '' : Number(Number(row.in_total).toFixed(2)),
                gross_profit_percent: !row.in_total || !row.revenue ? '' : `${Number((row.in_total / row.revenue) * 100).toFixed(2)}%`
            }
        });

        this.setState({ loading: true });
        await getExcel(excelData, `${tr('income_statement')} ${this.props.project.name} ${moment().format("DD.MM.YYYY")}`, tr('income_statement'));
        this.setState({ loading: false });
    }

    render() {
        return (
            <div style={{ marginTop: "1rem" }}>
                <ApInputStack gap="0">
                    <ApAddon width="200px">{tr('type')}</ApAddon>
                    <div style={{ width: "200px" }}>
                        <ApInput
                            type='select'
                            options={[
                                { label: tr('simple'), value: "simple" },
                                { label: tr('simple') + ' (' + tr('no_installations') + ')', value: "simple_no_installations" },
                                { label: tr('full'), value: "full" },
                                { label: tr('full') + ' (' + tr('no_installations') + ')', value: "full_no_installations" },
                            ]}
                            onChange={(e) => this.handleTypeChange(e)}
                            id="selectedType"
                            name="selectedType"
                            value={this.state.selectedType}
                        />
                    </div>
                </ApInputStack>
                <ApInputStack gap="0">
                    <ApInput
                        width="200"
                        type="datetimeV2"
                        id="start"
                        name="start"
                        label={tr('start_date')}
                        value={this.state.start}
                        onChange={(value) => this.setState({ start: value })}
                        clearable
                        autoComplete="off"
                    />
                    <ApInput
                        width="200"
                        type="datetimeV2"
                        id="end"
                        name="end"
                        label={tr('end_date')}
                        value={this.state.end}
                        onChange={(value) => this.setState({ end: value })}
                        clearable
                        autoComplete="off"
                    />
                    <ApButton
                        color='green'
                        onClick={this.getData}
                    >
                        {tr('search')} <SvgIcon type='solid' icon='search' />
                    </ApButton>
                </ApInputStack>

                <ApReactTable
                    loading={this.state.loading}
                    data={this.state.data}
                    columns={this.getColumns()}
                    multiselect={[
                        {
                            icon: 'file-excel',
                            label: tr('create_excel'),
                            action: this.getExcel,
                            unselectAfter: false,
                            closeAfter: true,
                            alwaysOn: true,
                        }
                    ]}
                    rowActions={(row) => {
                        if (row.orderId) {
                            return <div className="apSimpleButton" onClick={() => window.emitter.emit('goTo', { pathname: `/storage/order/id/${row.orderId}` })}>
                                <SvgIcon icon="arrow-right" type="solid" />
                            </div>
                        }
                        else if (row.poId) {
                            return <div className="apSimpleButton" onClick={() => window.emitter.emit('goTo', { pathname: `/storage/purchase/order/id/${row.poId}` })}>
                                <SvgIcon icon="arrow-right" type="solid" />
                            </div>
                        }
                        return null;
                    }}
                />

            </div>
        )
    }
}

export default TrackingReportsIncomeStatement;