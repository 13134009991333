import React from 'react';
import moment from 'moment';
import autoBind from 'react-autobind';
import {apiurl} from 'services/Api/Api.js';

import Measure from 'react-measure';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';

import { Collapse } from 'react-bootstrap';

import api             from 'services/Api/Api.js';
import auth            from 'services/Authed/Authed.js';

import SvgIcon         from 'common/SvgIcon/SvgIcon.js';
import ApButton        from 'common/ApButton/ApButton.js';
import { errorPopper, saveSuccessPopper, tr } from 'services/Helpers/Helpers.js'

// import ComponentTable      from './ComponentTable/ComponentTable.js';
import DataEdit            from './DataEdit/DataEdit.js';
import DataSummary         from './DataSummary/DataSummary.js';

//import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit.js';

import './TransportEdit.css';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit.js';
import ApSteps from 'common/ApSteps/ApSteps.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

class TransportEdit extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            loading: false,
            errorMessage: null,

            editShow: true,

            senderId: null,
            senderType: null,
            senderName: null,

            delivery_name: tr('project_storage'),

            receiver: '',
            sender: null,

            status: '',

            creatorName: auth.getName(),
            date: moment(),

            transport: '',
            components: [],
            events: [],
            id : 0,
            changed: false,

            showConfirm: false,
            showDelete: false,
        };
        autoBind(this);
    }

    componentDidMount()
    {
        this.getTransport();
    }

    getTransport()
    {
        this.setState({loading: true});
        let tId = sessionStorage.getItem('transportId');
        api({
            method: 'get',
            url: `storage/delivery/getTransport/${ tId }`, 

        }).then((response) => {

            this.setState({
                transport: response[0].transport,
                components: response[0].components,
                senderName: response[0].sender_name,
                receiver: response[0].receiver,
                events: response[0].events,
                sender: response[0].sender,
                loading: false,
            });
            //console.log('res.: ', response);
            //console.log('res.transport: ', response[0].transport);
            //console.log('transport: ', this.state.transport);
            //console.log('comps: ', this.state.components);
            //console.log('events: ', this.state.events);
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({
                errorMessage: tr('get_error'),
                loading: false,
            });
        });
    }

    getPdfTemplate()
    {
        let file = '';
        api({
            method: 'get',
            url: `file/pdf/template`, 

        }).then((response) => {
            const file = response;
            //console.log('res: ',response)
            return file;
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('get_error'));
        });
        return file
    }

/*
    doList()
    {
        //let comps = this.state.components;
        
        return 0;
            const id = 1;
            const name='testi';
            //console.log('Get PDF');
    
            this.setState({ loading: true });
            api({
                method: 'get',
                url: `${ apiurl }file/id/${ id }`,
                responseType: 'blob',
            }).then(( response ) => {
    
                const url = window.URL.createObjectURL( new Blob([ response ]) );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name );
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                this.setState({ loading: false });
    
            }).catch( ( error ) => {
                errorPopper(error, 'Tiedoston lataamisessa tapahtui virhe');
                this.setState({ loading: false });
            });
    }
*/
    saveTransport()
    {
        let tId = sessionStorage.getItem('transportId');

        if (this.state.changed) {
            this.saveEdit();
        }

        api({
            method: 'post',
            url: `storage/delivery/updateStatus/${tId}`,
        }).then((response) => {
            response != 6 ? this.getTransport() 
                : window.emitter.emit( 'goTo', { pathname: `/storage/transports/` } ); 
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });

        //this.table.refresh();
    }

    saveEdit() {
        this.setState({loading:true});
        api({
            method: 'post',
            url: 'storage/delivery/transportSave',
            data: {
                transport: this.state.transport,
                components: this.state.components,
            }
        }).then((response) => {
            // console.log("RES", response);
            saveSuccessPopper();
            this.setState({
                transport: response[0].transport,
                components: response[0].components,
                senderName: response[0].sender_name,
                receiver: response[0].receiver,
                events: response[0].events,
                sender: response[0].sender,
                changed: false,
                loading: false
            });
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('save_error'));
            this.setState({loading: false});
        });
    }

    skipSteps() {
        let tId = sessionStorage.getItem('transportId');

        api({
            method: 'post',
            url: `storage/delivery/skip/${tId}`,
        }).then((response) => {
            // console.log("RES", response);
            this.setState({
                transport: response[0].transport,
                components: response[0].components,
                senderName: response[0].sender_name,
                receiver: response[0].receiver,
                events: response[0].events,
                sender: response[0].sender,
            });
            saveSuccessPopper();
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
    }

    componentsChange( components )
    {
        this.setState({ components: components, changed: true });
    }

    getColumns()
    {
        return [
        // {
        //     id: 'id',
        //     Header: 'Id',
        //     accessor: 'component_id',
        //     filterable: false,
        //     sortable: false,
        // },
        {
            id: 'name',
            Header: tr('component'),
            accessor: 'c_name',
            filterable: false,
            sortable: false,
        },
        {
            id: 'count',
            Header: tr('amount'),
            accessor: 'count',
            filterable: false,
            sortable: false,
            width: 150,
        },{

            id: 'sender',
            Header: tr('sender'),
            accessor: 'from_loc_name',
            filterable: false,
            sortable: false,

        },{
            id: 'receiver',
            Header: tr('recipient'),
            accessor: 'to_loc_name',
            filterable: false,
            sortable: false,
          
        }];
    }

    getSteps() {
        let latestEventStatus = 0;
        if (this.state.events && this.state.events.length) {
            const latestEvent = this.state.events[this.state.events.length -1];
            latestEventStatus = latestEvent.event_status;
        }

        return [
            {
                icon: 'edit',
                name: tr('editing'),
                success: latestEventStatus >= 1,
            },
            {
                icon: 'check',
                name: tr('approved_for_collection'),
                success: latestEventStatus >= 2,
            },
            {
                icon: 'luggage-cart',
                name: tr('collected'),
                success: latestEventStatus >= 3,
            },
            {
                icon: 'truck',
                name: tr('delivered'),
                success: latestEventStatus >= 4,
            },
            {
                icon: 'clipboard-list',
                name: tr('received'),
                success: latestEventStatus >= 5,
            },
            {
                icon: 'archive',
                name: tr('archived'),
                success: latestEventStatus >= 6,
            }
        ]
    }

    handleComment(value) {
        const transport = JSON.parse(JSON.stringify(this.state.transport));
        transport.comment = value;
        this.setState({transport, changed: true});
    }

    setFullBalanceAll() {
        const components = this.state.components.slice(0);
        components.map(c => this.setFullBalance(c));
        this.setState({components});
    }

    setFullBalance(component) {
        const foundComponent = this.state.components.find(c => c.id === component.id);
        if (foundComponent) {
            const foundSender = foundComponent.locations.find(location => location.id === component.delivery_from_id);
            if (foundSender) {
                component.delivery_count = foundSender.balance;
            }
        }
        return component;
    }

    getAllComponents() {
        if (!this.state.sender) return;
        const locationId = this.state.sender.id;
        this.setState({loading: true});

        api({
            url: `storage/delivery/${locationId}/getAllComponents`,
            method: 'GET'
        }).then(response => {
            // console.log("response", response);
            if (response.length) {
                const components = response.map((component, index) => {
                    component.id = `new${index}`;

                    const foundSender = component.locations.find(location => {
                        if (location.delivery_locations) {
                            for (let dl of location.delivery_locations) {
                                if (this.state.sender.id === dl.id) return true;
                            }
                        }
                        return false;
                    });
                    const foundReceiver = component.locations.find(location => {
                        if (location.delivery_locations) {
                            for (let dl of location.delivery_locations) {
                                if (this.state.receiver.id === dl.id) return true;
                            }
                        }
                        return false;
                    });

                    if (this.state.sender.type === 'P') {
                        component.delivery_from_id = this.state.sender.id;
                        component.delivery_from_type = 'project';
                        component.delivery_count = foundSender ? foundSender.balance : 0;
                    } else if (foundSender) {
                        component.delivery_from_id = foundSender.id;
                        component.delivery_from_type = 'location';
                        component.delivery_count = foundSender ? foundSender.balance : 0;
                    }

                    if (this.state.receiver.type === 'P') {
                        component.delivery_to_id = this.state.receiver.id;
                        component.delivery_to_type = 'project';
                    } else if(foundReceiver) {
                        component.delivery_to_id = foundReceiver.id;
                        component.delivery_to_type = 'location';
                    }

                    return component;
                })
                this.componentsChange(components);
            }
            this.setState({loading: false});

        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        })
    }

    goBackStep() {
        let tId = sessionStorage.getItem('transportId');

        this.setState({loading:true});

        api({
            method: 'post',
            url: `storage/delivery/revert/${tId}`,
        }).then((response) => {
            // console.log("RES", response);
            if (response.removed) {
                window.emitter.emit( 'goTo', {
                    pathname: `/storage/transports`,
                });
            } else {
                this.setState({
                    transport: response[0].transport,
                    components: response[0].components,
                    senderName: response[0].sender_name,
                    receiver: response[0].receiver,
                    events: response[0].events,
                    sender: response[0].sender,
                    loading:false,
                    changed: false
                });
            }
            saveSuccessPopper();
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({loading:false, changed: false});
        });
    }

    deleteTransport() {
        let tId = sessionStorage.getItem('transportId');

        this.setState({loading:true});

        api({
            method: 'post',
            url: `storage/delivery/delete/${tId}`,
        }).then((response) => {
            // console.log("RES", response);
            if (response) {
                window.emitter.emit( 'goTo', {
                    pathname: `/storage/transports`,
                });
            }
            errorPopper(null, tr('deleted'), 4000, 'success');
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({loading:false, changed: false});
        });
    }

    render()
    {
        let dataEdit = <Collapse in={ this.state.editShow }><div>
            <DataEdit
                summary={ true }
                onSummaryClick={ () => { this.setState({ editShow: false } )} }

                validator={ this.validator }
                onChange={ this.dataChange }

                handleComment={this.handleComment}

                senderName={ this.state.senderName }
                senderId={ this.state.transport.senderId }

               // receiverLocked={ this.state.receiverLocked }
               /// receiverTitle={ this.state.receiverTitle }
              //  receiverType={ this.state.receiverType }
              //  receiverId={ this.state.receiverId }

                receiverName={ this.state.receiver.delivery_name }
                receiverAddress={ this.state.receiver.address }
                receiverZipcode={ this.state.receiver.zip }
                receiverCity={ this.state.receiver.city }
                receiverCountry={ this.state.receiver.country }
                receiverContactName={ this.state.receiver.contact }

                number={ this.state.transport.number }
                //numberReserved={ this.state.numberReserved }

                status = { this.state.transport.state }
                comment={ this.state.transport.comment }
                upd = { this.state.transport.updated_at }
                events = { this.state.events }
                data = { this.state.components }
            />
        </div></Collapse>

        let dataSummary = <Collapse in={ !this.state.editShow }><div>
            <DataSummary
                editable={ !( this.state.type === 'show') }
                onEditClick={ () => { this.setState({ editShow: true } )} }

                senderName={ this.state.senderName }
                senderType={ this.state.senderType }

                receiverTitle={ this.state.receiver.delivery_name }

                receiverName={ this.state.receiver.receiver_name }
                receiverAddress={ this.state.receiver.address }
                receiverZipcode={ this.state.receiver.zip }
                receiverCity={ this.state.receiver.city }
                receiverCountry={ this.state.receiver.country }
                receiverContactName={ this.state.receiver.contact }

                number={ this.state.number }
                //numberReserved={ this.state.numberReserved }

                creatorName={ this.state.creatorName }
                createdDate={ this.state.createdDate }

                relatedNumber={ this.state.relatedNumber }
                relatedType={ this.state.relatedType }
                relatedId={ this.state.relatedId }

                referenceYour={ this.state.referenceYour }
                referenceOur={ this.state.referenceOur }
            />
        </div></Collapse>

        //const validatorMessage = this.validator.getStatusAll();
        const type = this.state.type;

        if( this.state.errorMessage )
            return <div> { this.state.errorMessage  } </div>

        let saveBox = null;
        let napinTeksti='';
        if(this.state.transport.state==1)
            napinTeksti = tr('accept_transfer_ready_to_collect')
        else if(this.state.transport.state==2)
            napinTeksti = tr('acknowledge_collection_waiting_for_shipment')
        else if(this.state.transport.state==3)
            napinTeksti = tr('acknowledge_shipment_en_route')
        else if(this.state.transport.state==4)
            napinTeksti = tr('acknowledge_arrival')
        else if(this.state.transport.state==5)
            napinTeksti = tr('archive')

        let btnTooltip = null;
        this.state.components.forEach(c => {
            if (!c.delivery_count) {
                btnTooltip = tr('amount_cannot_be_empty');
            }
            if (!c.delivery_from_id) {
                btnTooltip = tr('sending_storage_required');
            }
            if (!c.delivery_to_id) {
                btnTooltip = tr('receiving_storage_required');
            }
        })

        saveBox = <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar= node }>
            <div className="saveBar">
                <div className="left">
                    <ApButton onClick={this.goBackStep} color='red' disabled={this.state.loading}>
                        {this.state.transport.state == 1 
                            ? <><SvgIcon type="solid" icon="trash" /> {tr('delete')}</>
                            : <><SvgIcon type="solid" icon="arrow-left" /> {tr('cancel')}</>
                        }
                        
                    </ApButton>
                </div>
                <div className="right">
                    <span className='right-margin'>
                        <ApTooltip text={btnTooltip}>
                            <ApButton 
                            className={this.state.changed ? 'highlight' : ''} 
                            color="blue" 
                            onClick={ this.saveEdit } 
                            disabled={!this.state.changed || Boolean(btnTooltip) || this.state.loading}>
                                <SvgIcon icon="save" type="solid" />
                                { tr('save') }
                            </ApButton>
                        </ApTooltip>
                    </span>
                    {this.state.transport.state < 6 &&
                        <ApTooltip text={btnTooltip}>
                            <ApButton color="blue" onClick={ this.saveTransport.bind(this) } disabled={Boolean(btnTooltip) || this.state.loading}>
                                <SvgIcon icon="arrow-right" type="solid" />
                                { napinTeksti }
                            </ApButton>
                        </ApTooltip>
                    }
                    <ApDropdown
                        button={ <ApButton narrow><SvgIcon icon="ellipsis-h" type="solid" /></ApButton> }
                        actions={
                            [
                                {
                                    label: tr('storage_transfer_skip_steps'),
                                    icon: "truck",
                                    disabled: this.state.transport.state > 4 || this.state.changed,
                                    tooltip: this.state.changed ? tr('save_first') : null,
                                    action: () => this.setState({showConfirm: true}),
                                    closeAfter: true,
                                },
                                {
                                    label: tr('storage_transfer_delete'),
                                    icon: "trash",
                                    // disabled: this.state.transport.state > 4 || this.state.changed,
                                    // tooltip: this.state.changed ? tr('save_first') : null,
                                    action: () => this.setState({showDelete: true}),
                                    closeAfter: true,
                                },
                                {
                                    label: tr('select_all_components'),
                                    icon: "boxes",
                                    disabled: this.state.transport.state > 1,
                                    tooltip: tr('select_all_components_transfer_info'),
                                    action: this.getAllComponents,
                                    closeAfter: true,
                                },
                                {
                                    label: tr('send_all'),
                                    icon: "box-open",
                                    disabled: this.state.transport.state > 1,
                                    tooltip: tr('send_all_transfer_info'),
                                    action: this.setFullBalanceAll,
                                    closeAfter: true,
                                },
                            ]
                        }
                    />
                </div>
            </div>
        </ApStickyBar>


        return <div id='editTransport'>
            <div className="pageBackButton" onClick={ () => window.emitter.emit( 'goTo', { pathname: '/storage/transports' } ) }>
                <SvgIcon icon="arrow-left" type="solid" size="small" />
                { tr('back') }
            </div>
            <Measure
                onResize={ () => {
                    if( this.stickyBar )
                        this.stickyBar.updateBounds();
                }}
            >
            {({ measureRef }) => <div  ref={ measureRef } className="apBox" id="pageDeliveryEdit">
                <div className="apBoxHeader">
                    <h1>
                        { tr('storage_transfers') }
                    </h1>
                    <p> { tr('manage_storage_transfers') } </p>
                </div>
                <div className="padding">
                    <ApSteps
                        flatEnd
                        steps={this.getSteps()}
                    />
                    <div className="apForm">
                        <div className="apFormColumn fullColumn">
                            { dataEdit }
                            { dataSummary }
                            
                        </div>
                    </div>

                    {/* <ApReactTable
                        //rowActions={ changeCount }
                        data={ this.state.components }
                        columns={ this.getColumns() }
                    /> */}
                    <ComponentInstanceTable
                        type={'delivery'}
                        orderType={'delivery'}
                        componentsChange={this.componentsChange}
                        components={this.state.components}

                        removeComponentLocked={this.state.transport.state > 1}
                        addComponentLocked={ this.state.transport.state > 1 }

                        instance={'delivery'}

                        loading={this.state.loading}

                        deliverySenderId={this.state.sender && this.state.sender.id}
                        deliverySenderType={this.state.sender && this.state.sender.type === 'P' ? 'project_storage' : 'location'}

                        deliveryReceiverId={this.state.receiver && this.state.receiver.id}
                        deliveryReceiverType={this.state.receiver && this.state.receiver.type === 'P' ? 'project_storage' : 'location'}
                        deliveryReceiverName={this.state.receiver && this.state.receiver.name}

                        deliveryCountLocked={this.state.transport.state > 1}
                        deliveryToLocked={this.state.transport.state > 1}
                        deliveryFromLocked={this.state.transport.state > 1}

                        // removeComponentLocked={ this.state.assignment.status_id >= 7 }
                    />
                </div>
                { saveBox }
            </div>
            }
            </Measure>
            <ApConfirm 
                show={this.state.showConfirm}
	            header={tr('storage_transfer_skip_steps')}
	            body={ tr('storage_transfer_skip_steps_confirm') }
	            onConfirm={this.skipSteps }
	            onClose={ () => this.setState({ showConfirm: false })}
            />
            <ApConfirm 
                show={this.state.showDelete}
	            header={tr('storage_transfer_delete')}
	            body={ tr('storage_transfer_delete_confirm') }
	            onConfirm={this.deleteTransport }
	            onClose={ () => this.setState({ showDelete: false })}
            />
        </div>
    }
}

export default TransportEdit;
