/* eslint-disable no-template-curly-in-string */
import React from 'react';
import autoBind from 'react-autobind';
import Measure from 'react-measure';

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApDropdown       from 'common/ApDropdown/ApDropdown.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import FileUpload  from 'common/FileUpload/FileUpload.js';
import File        from 'common/File/File.js';
import ApButton    from 'common/ApButton/ApButton.js';
import SvgIcon     from 'common/SvgIcon/SvgIcon.js';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';
import api         from 'services/Api/Api.js';
import ApModal from 'common/ApModal/ApModal.js';

import { errorPopper
       , onlyNumber, tr } from 'services/Helpers/Helpers.js';

import { ApTabs
       , ApTab }       from 'common/ApTabs/ApTabs.js';
import ApTooltip   from 'common/ApTooltip/ApTooltip.js';

import Relations from 'modules/Storage/common/PoAndORelated/PoAndORelated.js';

import { Collapse } from 'react-bootstrap';

import './Settings.css';
import { connect } from 'react-redux';

const templateField = {
        current_date: tr('current_date_form'),
        date: tr('po_creation_date'),
        number: tr('po_number'),
        supplier_name: tr('supplier_name'),
        supplier_taxnumber: tr('supplier_business_id'),
        supplier_details: tr('supplier_additional_info'),

        receiver_name: tr('recipient_name'),
        receiver_address: tr('recipient_address'),
        receiver_zipcode: tr('recipient_postal_code'),
        receiver_city: tr('recipient_city'),
        receiver_country: tr('recipient_country'),
        receiver_contact_name: tr('recipient_contact_person'),
        delivery_term_name: tr('delivery_term'),
        delivery_method_name: tr('delivery_method'),
        payment_condition_name: tr('payment_term'),
        reference_our: tr('reference_number_our'),
        reference_your: tr('reference_number_your'),
        need_date: tr('day_of_need'),
        details: tr('additional_info'),
        price: tr('total_price'),
};

const templateRowField = {
    row_name: tr('storage_component_name'),
    row_code: tr('storage_component_code'),
    row_count: tr('storage_component_amount'),
    row_unit: tr('storage_component_unit'),
    row_price: tr('storage_component_price'),
};

class Settings extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            data: null,
            file: null,
            previousShow: false,
            keepOld: true,

            nextNumber: null,
            nextNumberMin: null,
            numberLength: null,

            docxId: null,
            pdfExampleId: null,

            infoShow: false,

            pc: [],
            dm: [],
            dt: [],

            pc_enabled: false,
            pc_required: false,
            pc_stock_required: false,
            dm_enabled: false,
            dm_required: false,
            dt_enabled: false,
            dt_required: false,
            recipient_required: false,
            storage_required: false,

            changes: false,
        }
        autoBind(this);
    }

    componentDidMount()
    {
        this.getSettings();
    }

    getSettings()
    {
        this.setState({ loading: true });

        return api({
            method: 'get',
            url: 'storage/po/settings',
        }).then((response) => {
            //console.log('r', response );

            if( response.pdf_error )
            {
                window.emitter.emit('popper', {
                    type: 'danger',
                    content: <strong>{ tr('pdf_converter_not_working') }</strong>,
                });
            }

            this.setState({
                docxId: response.docx_id,
                pdfExampleId: response.pdf_example_id,
                nextNumber: response.next_number,
                nextNumberMin: response.next_number,
                numberLength: response.number_length,

                pc: response.pc,
                dm: response.dm,
                dt: response.dt,
                pc_stock_required: response.pc_stock_required,

                pc_enabled:  response.pc_enabled,
                pc_required: response.pc_required,
                dm_enabled:  response.dm_enabled,
                dm_required: response.dm_required,
                dt_enabled:  response.dt_enabled,
                dt_required: response.dt_required,
                recipient_required: response.recipient_required,
                storage_required: response.storage_required,
                find_first_po_number: response.find_first_po_number,
                po_import_need_approval: response.po_import_need_approval,
                po_import_everyone_need_approv: response.po_import_everyone_need_approv,
            });

            // this.setSettings( response );
            this.setState({ loading: false });
            this.props.savePurchaseSettings(response);
        }).catch(( error ) => {
            console.log(error);

            errorPopper( error, tr('get_error') );
            this.setState({ loading: false });
        });
    }

    getTemplate( current = null)
    {
        let url = 'storage/po/settings/template';
        if( current ) url += '/current';
        else url += '/default';

        this.setState({ loading: true });
        api({
            method: 'get',
            url: url,
            responseType: 'blob',
        }).then(( response ) => {
            let fileName = current ? `${tr('po_template')}.docx` : `${tr('po_template_example')}.docx`;
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName );
            document.body.appendChild(link);
            link.click();
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('file_download_error'));
            this.setState({ loading: false });
        });
    }

    fileUpload( file )
    {
        this.setState({
            file: {
                name: file.original_name,
                id: file.id,
                extension: file.extension,
            },
            changes: true
        });
    }

    saveClicked()
    {
        let  data = {};

        if( this.state.file )
            data.template_id = this.state.file.id;

        data.next_number = this.state.nextNumber;
        data.number_length = this.state.numberLength;

        data.pc = this.state.pc;
        data.dm = this.state.dm;
        data.dt = this.state.dt;

        data.pc_enabled  = this.state.pc_enabled;
        data.pc_required = this.state.pc_required;
        data.pc_stock_required = this.state.pc_stock_required;
        data.dm_enabled  = this.state.dm_enabled;
        data.dm_required = this.state.dm_required;
        data.dt_enabled  = this.state.dt_enabled;
        data.dt_required = this.state.dt_required;
        data.recipient_required = this.state.recipient_required;
        data.storage_required = this.state.storage_required;
        data.find_first_po_number = this.state.find_first_po_number;
        data.po_import_need_approval = this.state.po_import_need_approval;
        data.po_import_everyone_need_approv = this.state.po_import_everyone_need_approv;

        return api({
            method: 'post',
            url: 'storage/po/settings',
            data: data,
        }).then((response) => {
            //console.log('saver', response );
            this.getSettings();
            this.setState({ loading: false, changes: false });
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });
        }).catch(( error ) => {
            console.log(error);

            errorPopper( error, tr('save_error') );
            this.setState({ loading: false });
        });

    }

    handleNextNumberChange( value, blur = false )
    {
        value = onlyNumber( value )
        if( blur )
        {
            value = parseInt( value , 10 );
            if( !value || value < this.state.nextNumberMin )
            {
                window.emitter.emit('popper', {
                    type: 'warning',
                    content: <strong>{ tr('next_number_min_warning') }</strong>,
                });
                value = this.state.nextNumberMin;
            }
        }
        this.setState({ nextNumber: value, changes: true });
    }

    handlNumberLengthChange( value, blur = false )
    {
        value = onlyNumber(value )

        if( parseInt( value, 10 ) > 50 ) return null;

        if( blur )
        {
            value = parseInt( value , 10 );
            if( !value ) value = null;
        }
        this.setState({ numberLength: value, changes: true });
    }


    renderNumberEdit()
    {
        let previewNumber = this.state.nextNumber;
        if( previewNumber )
        {
            previewNumber = String( previewNumber );
            previewNumber = previewNumber.padStart( this.state.numberLength, '0' );
        }


        return <div>
            <h4 className="formHeader">
                <SvgIcon icon="list-ol" type="solid" /> { tr('po_number') }
            </h4>
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('po_number_info') }
            </div>

            <ApInputStack gap="0">
                <ApAddon width="200px" noRightBorder>
                    { tr('po_number') }
                </ApAddon>
                <ApInput
                    type="text"
                    id="poNumber"
                    name="poNumber"
                    label={ tr('next_po_number') }
                    value={ this.state.nextNumber ? this.state.nextNumber : '' }
                    onChange={ ( e ) => this.handleNextNumberChange( e.target.value ) }
                    onBlur={ ( e ) => this.handleNextNumberChange( e.target.value, true ) }
                />
                <ApInput
                    type="text"
                    id="poNumberLenght"
                    name="poNumberLenght"
                    label={ tr('number_length') }
                    value={ this.state.numberLength ? this.state.numberLength : '' }
                    onChange={ ( e ) => this.handlNumberLengthChange( e.target.value ) }
                    onBlur={ ( e ) => this.handlNumberLengthChange( e.target.value, true ) }
                />
            </ApInputStack>

            <div className="previewContainer">
                <div className="top-left">{ tr('next_po_number') }</div>
                { previewNumber }
            </div>
        </div>
    }

    renderTemplateEdit()
    {
        let pdfDom = null;
        if( this.state.pdfExampleId )
        {
            const name = `${tr('po_example')}.pdf`;
            pdfDom = <div>
                <File fileId={ this.state.pdfExampleId } type="pdf" name={ name } />
                <File fileId={ this.state.pdfExampleId } name={ name }>{ tr('download') } { name }</File>
            </div>
        }

        let menuActions = [];

        if( this.state.docxId )
        {
            menuActions.push({
                label: tr('download_current_template'),
                icon: 'file-alt',
                action: ( item, closeFunc ) => {
                    this.getTemplate( 'current' );
                    closeFunc();
                },
            });
        }

        menuActions.push({
            label: tr('download_general_base_template'),
            icon: 'file',
            action: ( item, closeFunc ) => {
                this.getTemplate();
                closeFunc();
            },
        });


        let menuDom = <ApAddon noRightBorder width="40px">
            <ApDropdown
                actions={ menuActions }
                button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
                position="right"
            />
        </ApAddon>

        const DOCX = <ApTooltip text={ tr('docx_edit_info') }>
            <span className="hasTooltip">.docx</span>
        </ApTooltip>

        return <div className="poTemplateContainer">
            <h4 className="formHeader">
                <SvgIcon icon="file" type="solid" /> { tr('po_template') }
            </h4>
            <div className="poTemplateInfoButton">
                <ApButton size="tiny" color="blue" onClick={ () => this.setState({ infoShow: true }) }>
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('po_template_specs') }
                </ApButton>
            </div>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('template_info', [<strong>.pdf</strong>, <strong>{ DOCX }</strong>]) }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="pen-square" type="solid" />
                { tr('template_edit_info', [DOCX]) }
            </div>

            <div className="apInfo small warning">
                <SvgIcon icon="exclamation-circle" type="solid" />
                { tr('po_template_font_info') }
            </div>

            <ApInputStack gap="0">
                { menuDom }
                <ApAddon width="200px" noRightBorder>
                    { tr('new_template') }
                </ApAddon>
                <FileUpload
                    onUpload={this.fileUpload }
                    description="po-template"
                    accept=".docx"
                    label={ `${tr('select_docx_file')}...` }
                    value={ this.state.file }
                    onClear={ () => { this.setState({ file: null, changes: true }) }}
                />
            </ApInputStack>
            { pdfDom }
        </div>

    }

    renderInfoModalRows( template = false )
    {
        const values = template ? templateRowField : templateField;

        let rows = [];
        for( let key in values )
        {
            rows.push(<tr key={ key }>
                <td className="text">{ '${'+key+'}' }</td>
                <td className="description">{ values[ key ] }</td>
            </tr>);
        }
        return rows;
    }

    renderTemplateInfoModal()
    {
        return <div id="PoTemplateInfo">
            <ApModal
                show={ this.state.infoShow }
                handleClose={ () => this.setState({ infoShow: false }) }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { tr('template_specs') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <p>
                            { tr('template_specs_info', [<a href="https://en.wikipedia.org/wiki/Office_Open_XML" target="_blank" rel="noopener noreferrer">Microsoft Office Open XML</a>, <strong>.docx</strong>]) }
                        </p>
                        <p>
                            <strong>{ tr('listing_of_storage_components') }</strong>
                        </p>
                        <p>
                            { tr('listing_of_storage_components_info1', ['${row_name}']) }
                        </p>
                        <p>
                            { tr('listing_of_storage_components_info2') }
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>{ tr('text') }</th>
                                    <th>{ tr('description') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderInfoModalRows() }
                            </tbody>
                        </table>
                        <table>
                            <thead>
                                <tr>
                                    <th>{ tr('storage_component_text') }</th>
                                    <th>{ tr('description') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderInfoModalRows( 'rows' ) }
                            </tbody>
                        </table>
                    </div>
                }
            />
        </div>
    }

    handleAddItem(data) {
        this.setState({...data, changes:true});
    }

    render()
    {
        const getRelatedSwitch = ( args ) => {
            //Jos tyyppi annetaan
            const sName = args.type != null ? `${ args.related }_${ args.type }` : args.related;

            return <div className="apSwitchBlock">
                <label htmlFor={`${sName}-switch`} className="info">
                    { args.title }
                    <small> { args.sTitle }</small>
                </label>
                <ApSwitch
                    id={`${sName}-switch`}
                    on={ this.state[ sName ] }
                    onChange={ () => { this.setState({ [ sName ]: !this.state[ sName ], changes: true  }) } }
                    disabled={ this.props.loading }
                />
            </div>
        }


        return <div>
            <Measure
                onResize={ () => {
                    if( this.stickyBar )
                        this.stickyBar.updateBounds();
                }}
            >
            {({ measureRef }) =>
                <div className="apBox" id="poSettings" ref={ measureRef }>
                    <div className="apBoxHeader">
                        <h1>{ tr('purchase_settings') }</h1>
                        <p></p>
                    </div>
                    <ApTabs selected={ this.state.selectedTab } onChange={ this.tabChange } >
                        <ApTab icon="file" label={ tr('template') }>
                            <div className="padding">
                                {this.renderTemplateEdit() }
                            </div>
                        </ApTab>
                        <ApTab icon="list-ol" label={ tr('numbering') }>
                            <div className="padding">
                                { this.renderNumberEdit() }
                            </div>
                        </ApTab>

                        <ApTab icon="money-check" label={ tr('payment_terms') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                        { tr('payment_terms_info') }
                                </div>
                                { getRelatedSwitch({
                                    related: 'pc',
                                    type: 'enabled',
                                    title: tr('payment_terms_in_use'),
                                    sTitle: tr('payment_terms_in_use_info'),
                                }) }
                                <Collapse in={ this.state.pc_enabled }>
                                    <div className="indentleft">
                                        { getRelatedSwitch({
                                            related: 'pc',
                                            type: 'required',
                                            title: tr('payment_term_selection_mandatory'),
                                            sTitle: tr('payment_term_selection_mandatory_info'),
                                        }) }
                                        
                                        { getRelatedSwitch({
                                            related: 'pc_stock_required',
                                            type: null,
                                            title: tr('hide_components_without_storage'),
                                            sTitle: tr('hide_components_without_storage_info'),
                                        }) }

                                        <Relations
                                            instance="pc"
                                            loading={ this.state.loading }
                                            rows={ this.state.pc }
                                            onChange={ this.handleAddItem }
                                        />
                                    </div>
                                </Collapse>
                            </div>
                        </ApTab>
                        <ApTab icon="truck" label={ tr('delivery_terms') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                        { tr('delivery_terms_info') }
                                </div>
                                { getRelatedSwitch({
                                    related: 'dt',
                                    type: 'enabled',
                                    title: tr('delivery_terms_in_use'),
                                    sTitle: tr('delivery_terms_in_use_info'),
                                }) }
                                <Collapse in={ this.state.dt_enabled }>
                                    <div className="indentleft">
                                        { getRelatedSwitch({
                                            related: 'dt',
                                            type: 'required',
                                            title: tr('delivery_term_selection_mandatory'),
                                            sTitle: tr('delivery_term_selection_mandatory_info'),
                                        }) }
                                        <Relations
                                            instance="dt"
                                            loading={ this.state.loading }
                                            rows={ this.state.dt }
                                            onChange={ this.handleAddItem }
                                        />
                                    </div>
                                </Collapse>
                            </div>
                        </ApTab>

                        <ApTab icon="dolly-flatbed" label={ tr('delivery_methods') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                        { tr('delivery_methods_info') }
                                </div>
                                { getRelatedSwitch({
                                    related: 'dm',
                                    type: 'enabled',
                                    title: tr('delivery_methods_in_use'),
                                    sTitle: tr('delivery_methods_in_use_info'),
                                }) }
                                <Collapse in={ this.state.dm_enabled }>
                                    <div className="indentleft">
                                        { getRelatedSwitch({
                                            related: 'dm',
                                            type: 'required',
                                            title: tr('delivery_method_selection_mandatory'),
                                            sTitle: tr('delivery_method_selection_mandatory_info'),
                                        }) }
                                        <Relations
                                            instance="dm"
                                            loading={ this.state.loading }
                                            rows={ this.state.dm }
                                            onChange={ this.handleAddItem }
                                        />
                                    </div>
                                </Collapse>
                            </div>

                            <div className="padding">
                                { getRelatedSwitch({
                                    related: 'recipient_required',
                                    title: tr('po_recipient_mandatory'),
                                    sTitle: tr('po_recipient_mandatory_info'),
                                }) }
                            </div>
                            <div className="padding">
                                { getRelatedSwitch({
                                    related: 'storage_required',
                                    title: tr('po_storage_mandatory'),
                                    sTitle: tr('po_storage_mandatory_info'),
                                }) }
                            </div>
                            </ApTab>
                            
                            <ApTab icon="plane-arrival" label={tr('import')}>
                                <div className="padding">
                                    <div className="apInfo small">
                                        <SvgIcon icon="info-circle" type="solid" />
                                        {tr('po_import_settigns_info')}
                                    </div>
                                    
                                </div>

                                <div className="padding">
                                    {getRelatedSwitch({
                                        related: 'find_first_po_number',
                                        title: tr('try_first_po_number'),
                                        sTitle: tr('try_first_po_number_info'),
                                    })}
                                    {getRelatedSwitch({
                                        related: 'po_import_need_approval',
                                        title: tr('po_import_need_approval'),
                                        sTitle: tr('po_import_need_approval_info'),
                                    })}
                                    {getRelatedSwitch({
                                        related: 'po_import_everyone_need_approv',
                                        title: tr('po_import_everyone_need_approv'),
                                        sTitle: tr('po_import_everyone_need_approv_info'),
                                    })}
                                </div>
                                
                            </ApTab>
                    </ApTabs>
                    <ApStickyBar bottomMode zIndex={10} ref={ node => this.stickyBar= node }>
                        <div className="saveBar">
                            <div className="left">
                            </div>
                            <div className="right">
                                <ApButton color="blue"
                                    onClick={ this.saveClicked }
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading || !this.state.changes }
                                    className={this.state.changes ? 'highlight': ''}
                                >
                                    <SvgIcon icon="save" type="solid" />
                                    { tr('save') }
                                </ApButton>
                            </div>
                        </div>
                    </ApStickyBar>
                </div>
            }
            </Measure>

            { this.renderTemplateInfoModal() }
        </div>

            /*

        return (
            <Grid fluid={true} className="white" style={{paddingBottom: '2em'}}>
                <Row  style={{marginTop: '2em'}}>
                    <Col sm={12} md={6}>


                        <ApButton className="save" color="green" onClick={ this.save } disabled={ this.state.loading }>
                            <SvgIcon icon="save" type="solid" />
                            Tallenna
                        </ApButton>

                    </Col>
                </Row>


                <Row  style={{marginTop: '2em'}}>
                    <Col sm={12} md={6}>
                        <NextNumber />
                    </Col>
                </Row>

                <Row  style={{marginTop: '1em'}}>
                    <Col sm={12} md={6}>
                        <h4 className="text-primary">Toimitusehdot</h4>
                        <DeliveryTerms />
                    </Col>
                    <Col sm={12} md={6}>
                        <h4 className="text-primary">Toimitustavat</h4>
                        <DeliveryMethods />
                    </Col>
                    <Col sm={12} md={6} style={{marginTop: '2em'}}>
                        <h4 className="text-primary">Maksuehdot</h4>
                        <PaymentConditions />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} style={{marginTop: '2em'}}>
                        <h4 className="text-primary">PDF</h4>
                        <Pdf />
                    </Col>
                </Row>
            </Grid>
        )
        */
    }
}

const mapStateToProps = state => ({
    apPurchaseSettings: state.apPurchaseSettings
});

const mapDispatchToProps = dispatch => ({
    savePurchaseSettings: (settings) => dispatch({type: 'SAVE_PURCHASE_SETTINGS', payload: settings})
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
