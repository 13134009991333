/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import auth from 'services/Authed/Authed.js';
import moment from 'moment';

import api from 'services/Api/Api.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';

import ApButton from 'common/ApButton/ApButton.js';
import ApTree from 'common/ApTree/ApTree.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApSteps from 'common/ApSteps/ApSteps.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApTimeline from 'common/ApTimeline/ApTimeline.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import File from 'common/File/File.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import { Collapse } from 'react-bootstrap';
import { ApInputSmall } from 'common/ApInputSmall/ApInputSmall.js';
import _ from 'lodash'


import Head from '../Head/Head.js';

import ComponentTooltip from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';

import { mapTree
       , iterateTree
       , roundToDecimals
       , editItemById
       , findItemById
       , formatMoney
       , onlyNumber
       , getLastCodePart
       , profitPercentToSellPrice
       , priceToProfitPercent
       , sqlToDateInput
       , dateInputToSql
       , keyExists
       , errorPopper
       , validateEmail
       , tr
       , groupify,
       hasPermissions
} from 'services/Helpers/Helpers.js';


import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApInput,
         ApInputStack,
         ApAddon  } from 'common/ApInput/ApInput.js';

import ApModalInput from 'common/ApModalInput/ApModalInput.js';

import { getTypeIcon, componentTypes } from 'modules/Storage/common/StorageHelpers.js';

import MassList from './MassList/MassList.js';

import BudgetStatistics from './BudgetStatistics/BudgetStatistics.js';

import './OfferCalculation.css';
import PurchaseOrderOffer from '../Management/ComponentNeeds/PurchaseOrderOffer/PurchaseOrderOffer.js';
import SentPurchaseOrderOffers from '../Management/ComponentNeeds/PurchaseOrderOffer/SentPurchaseOrderOffers.js';

const budgetDetailOptions = [
    { value: "price_sell_all", label: tr('offer_price'), decimals: 2, unit: auth.getCurrencySign() },
    { value: "price_sell_all_vat24", label: tr('offer_price_vat24'), decimals: 2, unit: auth.getCurrencySign() },
    { value: "price_all",      label: tr('cost_price'),                    decimals: 2, unit: auth.getCurrencySign() },
    { value: "profit_all",     label: tr('gross_profit_price'),            decimals: 2, unit: auth.getCurrencySign() },
    { value: "profit_percent", label: tr('gross_profit_percent'),          decimals: 1, unit: '%' },
    { value: "items",          label: tr('number_of_components'),          decimals: 0, unit: tr('pcs') },
    { value: "hours",          label: tr('number_of_working_hours'),       decimals: 1, unit: 'h' },
    { value: "outside_storage",label: tr('number_of_external_components'), decimals: 0, unit: tr('pcs') },
    { value: "work_percent", label: tr('work_percent'),                        decimals: 1, unit: '%' },
    { value: "items_percent", label: tr('component_percent'), decimals: 1, unit: '%' },
    { value: "comment_count", label: tr('comment_count'), decimals: 0, unit: tr('pcs') },
];

const tabIds = {
    0: "calculation",
    1: "schedule",
    2: "send",
    3: "buy",
};



const round = ( value, decimal = 2 ) => {
    value = roundToDecimals( value, decimal );
    if( isNaN( value ) ) return null;
    return value;
}


class OfferCalculation extends React.Component
{
    constructor( props )
    {
        super( props );

        this.newCounter = 0;
        //this.selectedTab = tabIds[ 0 ];

        this.state = {
            selectedTab: 0,

            project: null,
            sheets: [],
            calculatables: [],
            tree: [],
            treeUnsaved: false,

            loading: false,
            locked: true,
            declined: false,
            selectedSheet: null,

            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),


            renameSheetShow: false,
            removeSheetConfirmShow: false,
            /*
            sheetId: this.props.sheetId,
            */

            selectedComponents: [],
            selectedComponentsVisible: false,

            massListShow: false,
            massListProjectList: [],

            subProjectEdit: {},
            subProjectCopy: {},
            ProjectCopyIsOffer: false,

            removeProjectConfirmShow: false,
            removeProjectConfirmData: {},

            removeComponentConfirmShow: false,
            removeComponentConfirmBody: null,
            removeComponentConfirmIds: [],

            showCalculatables: true,
            includeChildCalculatables: true,

            selectedProject: null,
            selectedDetail: 'price_sell_all',
            selectedDetailCumulative: true,

            showProfitAs: 'percent',
            showPriceAs: 'single',
            showPriceSellAs: 'single',

            workSynced: true,
            workSyncedConfirmShow: false,

            componentShowType: 'money',
            componentMoneyFilters: [],

            componentCalculatableVisible: [],

            divideComponentShow: false,
            divideComponent: {},
            divideProject: {},

            projectingConfirmShow: false,
            declinedConfirmShow: false,
            restoreConfirmShow: false,

            contacts: null,

            // Send
            sents: [],
            templates: [],
            selectedTemplateId: null,
            validTill: null,
            description: '',
            selectedFile: null,
            successProbability: 'null', // Select input has a 0 in it, and it is different then null, but program thinks they are same so now null is a string
            confirmShow: false,
            confirmStatus: null,
            doProjecting: false,
            projectDeclined: false,
            eMail: '',
            eMailOtsikko: '',
            eMailText: '',
            sendSelectedProject:null,

            sendNewShow: true,

            decline_reason_enabled: false,
            data: {
                decline_reason: '',
                decline_description: '',
            },
            groups: [],
            
            error: null,

            selectedBuyTab: 0,
        }
        this.newCounter = 0;

        autoBind(this);
    }

    onTabChange( tabId )
    {
        this.props.history.replace( `/projects/${ this.props.projectId }/offer/${ tabIds[ tabId ] }`);
    }
    getTabId( param )
    {

        if( param in tabIds )
            return parseInt( param, 10 );
        return parseInt( Object.keys( tabIds ).find( key => tabIds[ key ] === param ), 10 );
    }

    componentDidMount()
    {
        this.getOffer(); 
    }

    // ----------------------------------------
    // Common functions
    // ----------------------------------------

    getOffer( selectSheetId = null )
    {
        this.setState( {loading: true } );
        api({
            method: 'get',
            url: `project/${ this.props.projectId }/offer`,
        }).then((response) => {

            //console.log('GETOFFER', response );


            // Set first 3 calculatables visible, ones with work attached take preference
            let tmpCalcs = JSON.parse( JSON.stringify ( response.calculatables ) );
            tmpCalcs.sort( ( a, b ) => {
                if( b.work_id ) return 1;
                if( !a.work_id && !b.work_id ) return 0;
                if( a.work_id && b.work_id ) return 0;
                return -1;
            });
            tmpCalcs = tmpCalcs.slice( 0, 3 );
            tmpCalcs = tmpCalcs.map( t => t.id );

            this.setState({
                locked: response.locked,
                project: response.project,
                sheets: response.sheets,
                calculatables: response.calculatables,
                componentCalculatableVisible: tmpCalcs,
                successProbability: response.project.offer.success_probability ? response.project.offer.success_probability : 'null', //null is string for some weird reason

                sents: response.sents,
                templates: this.formatTemplates(  response.templates ),
                sendNewShow: !Boolean( response.sents.length ),

                subproject_code_default_length: response.subproject_code_default_length,
                decline_reason_enabled: response.decline_reason_enabled,
                eMailOtsikko: response.eMailDefaultSubject,
                eMailText: response.eMailDefaultText,
                contacts: response.project.customer_contact,
                component_save_as_new_default: response.component_save_as_new_default,
                validTill: moment().add(response.default_offer_end ? response.default_offer_end : 30, 'days').format('DD.MM.YYYY').toString(),
                loading: false,

                decline_reason_enabled: response.decline_reason_enabled,
            }, () => {

                if( response.sheets.length )
                    this.selectSheet(selectSheetId ? selectSheetId : response.sheets[0].id);
                if (this.state.project.cost_center_is_required && this.state.project.cost_center == null) {
                    this.setState({ error: tr('cost_center_is_required') })
                }
            });

        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        });

    }

    selectSheet( sheetId )
    {
        this.getTree( sheetId );
        this.setState({ sheetId: sheetId });
    }

    getTree( sheetId )
    {
        this.setState({loading: true});
        api({
            method: 'get',
            url: `project/offer/sheet/${sheetId}/tree`,
        }).then((response) => {
            // console.log('Offer tree', response );
            this.setState({ groups: response.groups });
            this.cleanTreeFromDatabase( response.tree );
            //this.setState({contacts: response.kontaktit});
            this.setState({loading: false});
        }).catch((error) => {
            console.error( error );
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        });
    }

    handleChange( key, value )
    {
        let settings = { ...this.state.subProjectCopy };
        settings[ key ] = value;
        settings[tr('editing')]=true;
        this.setState({ "subProjectCopy" : settings });
    }

    handleDeclineDescription( e )
    {
        const decline_description = e.target.name;
        let data = { ...this.state.data };
        data[ decline_description ] = e.target.value;
        this.setState({ data });
    }
    
    // Round existing values and calculate the missing ones
    cleanTreeFromDatabase( tree )
    {
         // Set some default dates ( current date -> 14 days )
        const defaultStart = moment().format('YYYY-MM-DD');
        const defaultEnd = moment().add( 14, 'days' ).format('YYYY-MM-DD');

        tree = mapTree( tree, ( item, parent ) => {
            item.components = item.components.map((c) => {

                // Values we get from backend: count, unit_price, balance_percent
                // Calculate the rest

                const count           = c.count;
                const priceSingle     = c.unit_price;
                const profitPercent   = c.balance_percent;
                const priceAll        = ( priceSingle * count );
                const priceSellSingle = profitPercentToSellPrice( priceSingle, profitPercent );
                const priceSellAll    = profitPercentToSellPrice( priceAll, profitPercent );
                const profitSingle    = ( priceSellSingle - priceSingle );
                const profitAll       = ( priceSellAll - priceAll );

                return {
                    id: c.id,
                    project_id: c.project_id,
                    component: c.component,

                    name: c.name,
                    type_id: c.type_id,
                    type_name: c.type_name,
                    type_text: c.type_text,
                    group_id: c.group_id,
                    comment: c.comment,

                    count:             round( count ),
                    price_single:      round( priceSingle ),
                    price_all:         round( priceAll ),
                    profit_percent:    profitPercent,
                    profit_percent_rounded:    round(profitPercent, 1),
                    profit_single:     round( profitSingle ),
                    profit_all:        round( profitAll ),
                    price_sell_single: round( priceSellSingle ),
                    price_sell_all:    round( priceSellAll )
                }
            })

            let start = defaultStart;
            if( parent && parent.begin_date ) start = parent.begin_date;
            if( item.begin_date ) start = item.begin_date;

            let end = defaultEnd;
            if( parent && parent.end_date ) end = parent.end_date;
            if( item.end_date ) end = item.end_date;

            //if( item.is_continuous ) end = endlessDate;

            // For schedule
            item.childsOpen = false;
            item.start = start;
            item.end = end;



            return item;
        });

        tree[0].__selected = true;
        this.setState({
            selectedComponents: [],
            selectedProject: tree[0],
        });

        this.calculateTree( tree );
        this.setState({ treeUnsaved: false });

        const workSynced = this.isWorkSynced();
        this.setState({ workSynced: workSynced });
    }

    calculateTree( tree = null )
    {
        if(tree === null)
            tree = this.state.tree.slice(0);

        const groups = [ 'item', 'work', 'other' ];

        let selectedProject = null;
        this.setState({error: null});
        tree = mapTree( tree, null, ( item, parent ) => {
            let own = {
                notRemovable: false,
                total: {
                    items: 0,
                    hours: 0,
                    outside_storage: 0,

                    price_all: 0,
                    price_sell_all: 0,
                    profit_all: 0,
                    profit_percent: 0,
                    comment_count: 0,
                    price_sell_all_vat24:0,
                },
                item: {
                    calculatables: {},
                    price_all: 0,
                    price_sell_all: 0,
                    profit_all: 0,
                    profit_percent: 0
                },
                work: {
                    price_all: 0,
                    price_sell_all: 0,
                    profit_all: 0,
                    profit_percent: 0,
                },
                other: {
                    price_all: 0,
                    price_sell_all: 0,
                    profit_all: 0,
                    profit_percent: 0
                },
            };

            this.state.calculatables.forEach( calc => {
                own.item.calculatables[ calc.work_id ] = 0;
            });
            
            item.components.forEach(c => {
                if( !own.notRemovable )
                    own.notRemovable = this.itemNotRemovable( c );
            });

            if (item.works) {
                item.works.forEach(c => {
                    if( !own.notRemovable )
                        own.notRemovable = this.workNotRemovable( c );
                });
            }

            if (item.expenses) {
                item.expenses.forEach(c => {
                    item.components.forEach(c => {
                        if( !own.notRemovable )
                            own.notRemovable = this.expenseNotRemovable( c );
                    });
                });
            }

            // Check siblings
            item.invalid = null;
            if( parent && parent.children )
            {
                let counts = {};
                parent.children.forEach( s => {
                    counts[ s.project_code ] = counts[ s.project_code ] ? counts[ s.project_code ] + 1 : 1;
                });

                if( counts[ item.project_code ] > 1 ) {
                    item.invalid = tr('same_project_code_error');
                    this.setState({error: tr('same_project_code_error')})
                }
            }

            const calculateProfit = ( section ) => {

                section.total.profit_percent = priceToProfitPercent( section.total.price_all, section.total.price_sell_all );
                section.total.profit_all = ( section.total.price_sell_all - section.total.price_all );

                groups.forEach(( group ) => {
                    section[ group ].profit_percent = priceToProfitPercent( section[ group ].price_all, section[ group ].price_sell_all );
                    section[ group ].profit_all = ( section[ group ].price_sell_all - section[ group ].price_all );
                });
                return section;
            }

            // Color tree item
            let status = null;

            item.components.map(( c ) => {
                
                // Get warnings and status
                c.priceWarning = ( c.component && parseFloat( c.price_single ) < parseFloat( c.component.price ) );
                c.profitWarning = ( parseFloat( c.profit_all) <= 0 );
                c.nameError = ( c.name === "" || !c.name );
                c.countError = Boolean( !c.count )
                if( !status )
                {
                    if( c.nameError || c.countError ) status = 'error';
                    else if( c.priceWarning || c.profitWarning ) status = 'warning';
                }

                own['total'].price_all      += parseFloat( c.price_all );
                own['total'].price_sell_all += parseFloat(c.price_sell_all);
                if (c.comment) {
                    own.total.comment_count++;
                }

                groups.forEach(( group ) => {
                    if( c.type_name === group )
                    {
                        own[ group ].price_all      += parseFloat( c.price_all );
                        own[ group ].price_sell_all += parseFloat( c.price_sell_all );
                    }
                })

                if( c.type_name === 'item' )
                {
                    if( c.component )
                    {
                        // Calculatables for items
                        this.state.calculatables.forEach(( calc ) => {
                            const cCalcs = c.component.calculatables;
                            if( !cCalcs ) return null;
                            let found = cCalcs.find( f => f.id === calc.id );
                            if( found )
                            {
                                const value = ( found.pivot.value * c.count );
                                own[ c.type_name ].calculatables[ found.work_id ] += value ;
                            }
                        });
                    }

                    own['total'].items++;
                }
                else if( c.type_name === 'work' )
                    own['total'].hours += parseFloat( c.count );

                if( !c.component )
                    own['total'].outside_storage++;

                return c;
            });
            own = calculateProfit( own );
            own.total.price_sell_all_vat24 = own.total.price_sell_all*1.24;

            // Clone own stuff to total stuff
            let  all = JSON.parse( JSON.stringify( own ) );

            let cStatus = null;
            item.children.forEach(( child ) => {

                if( child.status === 'error' ) cStatus = 'error';
                if( cStatus !== 'error' && child.status === 'warning' ) cStatus = 'warning';

                if( child.calculated )
                {
                    all.total.price_all      += child.calculated.all.total.price_all;
                    all.total.price_sell_all += child.calculated.all.total.price_sell_all;
                    all.total.profit_all += child.calculated.all.total.profit_all;
                    
                    all.total.comment_count += child.calculated.all.total.comment_count;
                    all.total.price_sell_all_vat24 += child.calculated.all.total.price_sell_all_vat24;

                    all.total.items           += child.calculated.all.total.items;
                    all.total.hours           += child.calculated.all.total.hours;
                    all.total.outside_storage += child.calculated.all.total.outside_storage;

                    groups.forEach(( group ) => {
                        all[ group ].price_all      += child.calculated.all[ group ].price_all;
                        all[ group ].price_sell_all += child.calculated.all[ group ].price_sell_all;
                        all[ group ].profit_all     += child.calculated.all[ group ].profit_all;
                    })

                    this.state.calculatables.forEach(( calc ) => {
                        all.item.calculatables[ calc.id ]  += child.calculated.all.item.calculatables[ calc.id ];
                    });
                }
            });
            all = calculateProfit( all );

            item.calculated = {
                own: own,
                all: all,
            }

            // Own status overrides child status
            if( !status ) status = cStatus;
            item.status = status;

            if( this.state.selectedProject && this.state.selectedProject.id === item.id )
                selectedProject = item;

            return item;
        });

        this.setState({
            tree: tree,
            selectedProject: selectedProject,
        });
    }

    itemNotRemovable( c )
    {
        if( parseFloat( c.installed_entry_count ) > 0 ) return tr('recorded_progress');
        return false;
    }
    workNotRemovable( c )
    {
        if( parseFloat( c.entry_count ) > 0 ) return tr('recorded_hours');
        return false;
    }
    expenseNotRemovable( c )
    {
        if( parseFloat( c.expense_entry_count ) > 0 ) return tr('recorded_costs');
        else if( parseFloat( c.chargeable_entry_count ) > 0 ) return tr('recorded_billable');
        return false;
    }

    notRemovable( type, c )
    {
        switch ( type ) {
            case 'item':
                return this.itemNotRemovable( c );
            case 'work':
                return this.workNotRemovable( c );
            case 'expense':
                return this.expenseNotRemovable( c );
            default:
                return false;
        }
    }

    refresh()
    {
        this.getColumns();
    }

    setDeclineReason( decline_reason )
    {
        //console.log('', decline_reason)
        this.setState({
            decline_reason: decline_reason,
        });
    }

    saveTree()
    {

        // Make sure all modifications are updated
        this.updateSelectedProjectSettings();

        let tree = JSON.parse( JSON.stringify( this.state.tree ));

        tree = mapTree( tree, ( item ) => {
            const components = item.components.map((c) => {
                return {
                    id: isNaN( parseInt( c.id, 10 ) ) ? null : c.id,
                    name: c.name,
                    type_id: c.type_id,
                    component_id: c.component ? c.component.id : null,
                    save_as_new: c.save_as_new,
                    count: c.count,
                    unit_price: c.price_single,
                    balance_percent: c.profit_percent,
                    comment: c.comment ? c.comment:null,
                    group_id: c.group_id ? c.group_id:null,
                }
            });
            return {
                id: isNaN( parseInt( item.id, 10 ) ) ? null : item.id,
                project_code: item.project_code,
                name: item.name,
                description: item.description,
                begin_date: item.start,
                end_date: item.end,
                components: components,
                children: item.children,
                project_meters: item.project_meters,
            }
        });

        this.setState({ loading: true });


        const project = this.state.project;

        let pdata = {};
        pdata.id = project.id;
        pdata.customer_id = project.customer ? project.customer.id : null;
        pdata.customer_contact_id = project.customer_contact ? project.customer_contact.id : null;
        pdata.manager_id = project.manager ? project.manager.id : null;
        pdata.workhour_approver_id = project.workhour_approver ? project.workhour_approver.id : null;
		pdata.customer_order_number = project.customer_order_number ? project.customer_order_number : null;
        pdata.date_received = project.offer.date_received || null;
        pdata.date_deadline = project.offer.date_deadline || null;
        pdata.decline_reason_id = project.offer.decline_reason_id || null;
        pdata.decline_description = project.offer.decline_description || null;
        pdata.cost_center_id = project.cost_center ? project.cost_center.id : null;
        pdata.project_visible_to_users = project.project_visible_to_users ? project.project_visible_to_users : [];

        api({
            method: 'post',
            url: `project/offer/sheet/${this.state.sheetId}/tree`,
            data: {
                tree: tree,
                pdata: pdata,
                groups: this.state.groups,
            }
        }).then((response) => {

            //console.log('SAVE SHEET', response );

            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });

            this.setState({
                selectedComponents: [],
                selectedComponentsVisible: false,
                selectedProject: null,
                loading: false,
                groups: response.groups,
            });
            this.cleanTreeFromDatabase( response.tree );
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });
    }

    doProjecting()
    {
        const pId = this.props.projectId;
        
        this.setState({
            projectingConfirmShow: false,
            loading: true,
        });
        return api({
            method: 'post',
            url: `project/offer/sheet/${ this.state.sheetId }/projecting`,
        }).then((response) => {
            this.props.onSave( this.props.projectId );
            this.getOffer();
            this.setState({ loading: false });

            //Päivitä projektivaraston saldot
            this.updateStorageBalance(this.props.projectId);
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });
    }

    updateStorageBalance(pId) {
        api({
            method: 'get',
            url: `project/management/${pId}/updateProjectStorage`,
        }).then((response) => {
            if (response != 0) {
                //console.log('Projektivaraston saldojen päivitys onnistui!');
            }
        }).catch((error) => {
            console.error(`Projektivaraston saldojen päivitys ei onnistunut! ${error}`);
            this.setState({ loading: false });
        });
    }
    

    doDecline()
    {
        this.setState({
            projectingConfirmShow: false,
            loading: true,
        });     
        return api({
            method: 'post',
            url: `project/${ this.state.project.id }/decline`,
            data: { 
                decline_reason: this.state.decline_reason?this.state.decline_reason.id:null,
                decline_description: this.state.data.decline_description
            },
        }).then((response) => {
            this.props.onSave( this.props.projectId );
            this.getOffer();
            this.setState({ loading: false });
            this.refresh();
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });
    }

    doRestoreDeclined()
    {
        this.setState({
            restoreConfirmShow: false,
            loading: true,
        });
        return api({
            method: 'post',
            url: `project/${ this.state.project.id }/undecline`,
        }).then((response) => {
            this.props.onSave( this.props.projectId );
            this.getOffer();
            this.setState({ loading: false });
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });

    }



    // Common function used by all component input edits
    updateTreeItem(projectId, func)
    {
        let tree = this.state.tree.slice(0);
        tree = mapTree( tree, ( item ) => {
            if( projectId === item.id )
                return func( item );
            return item;
        });
        this.calculateTree( tree );
    }

    // ----------------------------------------
    // Sheet management
    // ----------------------------------------

    renameSheet( name )
    {
        if( !name )
            return false;

        this.setState({ loading: true });
        api({
            method: 'post',
            url: `project/offer/sheet/${this.state.sheetId}/rename`,
            data: {
                name: name,
            }
        }).then((response) => {
            this.getOffer( this.state.sheetId );
            this.setState({loading: false});
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({loading: false});
        });
    }

    newSheet( name, cloneSheetId )
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: `project/offer/${this.props.projectId}/sheet/new`,
            data: {
                name: ( name ? name : null ),
                versiocount: this.state.sheets.length,
                sheetId: ( cloneSheetId ? cloneSheetId : null ),
            }
            
        }).then((response) => {
            this.getOffer();
            this.setState({loading: false});
            //console.log('versiocount: ', this.state.sheets.length);
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });
    }

    removeSheet()
    {
        this.setState({ loading: true });
        api({
            method: 'delete',
            url: `project/offer/sheet/${this.state.sheetId}/remove`,
        }).then((response) => {
            this.getOffer();
            this.setState({loading: false});
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('delete_error'));
            this.setState({loading: false});
        });
    }

    getSheetOptions()
    {
        let items = this.state.sheets.map(( item ) => {
            return { value: item.id, label: item.name }
        });
        return items;
    }

    getSheetName( id )
    {
        for( let i = 0; i < this.state.sheets.length; i++ )
            if( this.state.sheets[i].id == id )
                return this.state.sheets[i].name;

        return "";
    }



    // ----------------------------------------
    //  Functions to open and submit dialogs
    // ----------------------------------------

    openMassList()
    {
        let pList = [];
        iterateTree(this.state.tree, ( item ) => {
            pList.push({ id: item.id, code: item.project_code, name: item.name } );
        })
        this.setState({
            massListShow: true,
            massListProjectList: pList,
        });
    }

    massListClose( data )
    {
        this.setState({
            massListShow: false,
            massListProjectList: [],
        });
    }

    addSubProjectClick( parent )
    {
        if( !parent )
            parent = this.state.tree[0];

        this.setState({
            subProjectEdit: {
                show: true,
                parent: parent,
                data: null,
            }
        });
    }
    copyProjectClick( parent )
    {   
        if( !parent )
            parent = this.state.tree[0];
        this.setState({
            subProjectEdit: {
                show: true,
                parent: parent,
                data: parent,
                copy: true,
            }
        });
    }
    copyProjectToAnotherClick( parent )
    {   
        if( !parent )
            parent = this.state.tree[0];
        
        let data = parent;
        let name = '';
        let code = '';
        let id = null;


        this.setState({
            subProjectCopy: {
                show: true,
                parent: parent,
                data: parent,
                name: name,
                code: code
            }
        });
    }
    
    addSubProjectFromExisting( project )
    {   
    	let copyProject = { ...this.state.subProjectCopy };
    	this.setState( {loading: true } );
    	
        let id = null;

        copyProject["project"]=project;
        
        api({
            method: 'get',
            url: `project/management/${ project.id }/tree/copy`,
        }).then((response) => {
        	copyProject["original"]=response.tree[0];
            copyProject["projectSelected"]=true;
            
            this.setState({ "subProjectCopy" : copyProject,
            				"ProjectCopyIsOffer" : response.isOffer });
            this.setState( {loading: false } );
        });
        
    	let data=project;
    	if( data )
	    {
			id = data.id;
			copyProject.origname = data.name;
			copyProject.name = "Kopio "+data.name;
			
		    //console.log("parent",data);
            if( copyProject.parent )
            {
                const siblings = copyProject.parent.children;
                let nextCode = 0;
                siblings.forEach(( s ) => {
                    let code = parseInt( getLastCodePart( s.project_code ), 10);
                    if( code > nextCode ) nextCode = code;
                });
                nextCode = String( nextCode + 1 );

                let defaultLength = this.state.subproject_code_default_length || 0;
                let prefixLength = ( defaultLength - nextCode.length );
                copyProject.code = ( prefixLength > 0 ) ? "0".repeat( prefixLength ) + nextCode : nextCode;
            }
	    }
    }
    
    addSubProjectFromExistingSelected() {
    	if (this.state.subProjectCopy["projectSelected"]) {
	        let tree = this.state.tree.slice(0);
	        let orig = this.state.subProjectCopy.original;
	        let data = this.state.subProjectCopy;
	       	tree = this.projectAddCopyToTree(tree, data, orig);     
	        this.calculateTree( tree );
	    	//console.log("iiiiksks",this.state.subProjectCopy.project,this.state.subProjectCopy);
	    	this.setState({ "subProjectCopy" : {} });
	    }
    }

    editSubProjectClick( item )
    {
        this.setState({
            subProjectEdit: {
                show: true,
                parent: {},
                data: item,
            }
        });
    }

    removeSubProjectClick( project )
    {
        this.setState({
            removeProjectConfirmShow: true,
            removeProjectConfirmData: project,
        });
    }

    removeComponentClick( c )
    {
        let body = null;
        let ids = [];

        if( c === 'selected' )
        {
            const selectedComponents = this.state.selectedComponents;
            body = <div>{ tr('confirm_components_deletion_offer', [<strong>{ selectedComponents.length }</strong>]) }</div>;
            ids = selectedComponents.map( sc => sc.id );
        }
        else
        {
            body = <div>{ tr('confirm_one_component_deletion_offer', [<strong>{ c.name }</strong>]) }</div>;
            ids = c.id;
        }

        this.setState({
            removeComponentConfirmShow: true,
            removeComponentConfirmBody: body,
            removeComponentConfirmIds: ids,
        });
    }
    getComponentsMassList(item) {
        api({
            method: 'get',
            url: 'project/offers/getComponentsMassList/' + item.id,
            responseType: 'blob',
        }).then((response) => {
            //console.log(response);

            let day = moment().format("DD.MM.YYYY");
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', item.name + "_" + item.project_code+"_"+tr("mass_list")+"_" + day +".xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
            });
        }).catch((error) => {
            errorPopper(error, 'Massalistan lataamisessa tapahtui virhe');
        });
    }


    // ----------------------------------------
    //  Smaller functions for adding and removing stuff to tree
    // ----------------------------------------

    subProjectSubmitted( data )
    {
        if (data.name && data.name.length > 255) {
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('project_name_length_error') }</strong>,
            });
            return;
        }

    	if (data.copy) {
            let tree = this.state.tree.slice(0);
            let orig = findItemById(tree, data.id);
           	tree = this.addSubProjectCopyToTree(tree, { name: data.name, code: data.code }, orig, data.parent);     
            this.calculateTree( tree );
    	}
    	else {
	        if( data.id )
	            this.editProject( data );
	        else
	        {
	            let tree = this.state.tree.slice(0);
	            let tmp = this.addSubProjectToTree( tree, { name: data.name, code: data.code }, data.parent );
	            this.calculateTree( tmp.tree );
	        }
    	}
    }

    editProject( project )
    {
        if( !project.id )
            return false;

        let tree = this.state.tree.slice(0);
        tree = mapTree( tree, ( item, parent ) => {
            if( item.id === project.id )
            {
                for( const prop in project )
                {
                    if( prop == "id" ) continue;
                    if( prop === 'code' && parent )
                        item.project_code = `${ parent.project_code }.${ project[ 'code' ] }`;
                    else
                        item[ prop ] = project[ prop ];
                }
            }

            // Update child project codes
            if( parent )
            {
                const value = getLastCodePart( item.project_code);
                item.project_code = `${ parent.project_code }.${ value }`;
            }
            return item;
        });
        this.calculateTree( tree );
        // this.setState({ treeUnsaved: true });
    }

    removeSubProject()
    {
        const project = this.state.removeProjectConfirmData;
        const parentId = project.parent_id;

        let removedComponents = [];

        let tree = this.state.tree.slice(0);
        tree = mapTree( tree, ( item ) => {

            if( item.id === parentId )
            {
                let removedProject = item.children.find( p => p.id === project.id );
                iterateTree( [ removedProject ], ( subRemoved ) => {
                    removedComponents = [ ...removedComponents, ...subRemoved.components ];
                });

                // Remove the project
                item.children = item.children.filter( child => child.id !== project.id );
            }
            return item;
        });

        // Remove the removed components from the selected components array
        const rIds = removedComponents.map( r => r.id );
        let selected = this.state.selectedComponents.slice(0);
        selected = selected.filter( sc => ! rIds.includes( sc.id ) );
        this.setState({ selectedComponents: selected });

        this.calculateTree( tree );

        const parent = findItemById( tree,  parentId, 'children' );
        this.selectProject( parent );
        this.setState({ treeUnsaved: true });
    }

    removeComponent( ids )
    {
        if( !Array.isArray( ids ) ) ids = [ ids ];

        let tree = this.state.tree.slice(0);
        tree = mapTree( tree, ( item ) => {
            item.components = item.components.filter( c => !ids.includes( c.id ) );
            return item;
        });

        this.calculateTree( tree );
        this.syncWorksIfNeeded();


        let selected = this.state.selectedComponents.slice(0);
        selected = selected.filter( sc => ! ids.includes( sc.id ) );
        this.setState({ selectedComponents: selected });
        this.setState({ treeUnsaved: true });
    }

    addSubProjectCopyToTree( tree, project, orig, parent)
    {
    	
        let newComponent = orig.components.slice(0);
        
        this.newCounter++;
        project = {
            ...project,
            project_code: `${parent.project_code}.${project.code}`,
            id: `new${this.newCounter}`,
            parent_id: parent.id,
            components: [],
            children: [],
        };

        tree = mapTree( tree, ( item ) => {
            if( item.id === parent.id )
            {
                item.childsOpen = true;
                item.children.push( project );
            }
            return item;
        });

        if( newComponent.length > 0 )
        {
            newComponent.forEach(( c ) => {
                tree = this.componentAddToTree( tree, c, project.id );
                //console.log("lisatty komponentti -a", c);
            });
        }
        
        if( orig.children.length > 0 )
        {
        	orig.children.forEach(( c ) => {
                // c.parent = _.cloneDeep(project);
        		c.code = getLastCodePart( c.project_code );
                tree = this.addSubProjectCopyToTree(tree, c, c, _.cloneDeep(project));
            });
        }
        this.setState({ treeUnsaved: true });
        return tree;
    }
    
    projectAddCopyToTree( tree, project, orig)
    {
        const parent = project.parent;
        
        let newComponent;
        if (this.state.ProjectCopyIsOffer) {
        	newComponent=orig.components;
        }
	    else {
	    	newComponent=orig.components.slice(0).concat(orig.works.slice(0)).concat(orig.expenses.slice(0));
	    }

        let hourApproverParent = parent.workhour_approver_parent;
        if( parent.workhour_approver ) hourApproverParent = parent.workhour_approver;

        let managerParent = parent.manager_parent;
        if( parent.manager ) managerParent = parent.manager;

        this.newCounter++;
        project = {
            id: `new${this.newCounter}`,
            name: project.name,
            project_code: `${parent.project_code}.${project.code}`,
            parent_id: parent.id,

            workhour_approver: null,
            workhour_approver_parent: hourApproverParent,

            manager: null,
            manager_parent: managerParent,

            description: null,
            end: parent.end,
            start: parent.start,
            is_continuous: parent.is_continuous,

            ended: parent.ended,
            done: parent.done,
            billing_hourly: parent.billing_hourly,

            billing_margin_travel: null,
            billing_margin_expense: null,
            billing_margin_allowance: null,

            billers: [],
            extranet_approvers: [],

            children: [],
            components: [],
            expenses: [],
            works: [],

            __schedule_child_open: false,
        };

        tree = mapTree( tree, ( item ) => {
            if( item.id === parent.id )
            {
                item.childsOpen = true;
                item.children.push( project );
            }
            return item;
        });

        if( newComponent.length > 0 )
        {
            newComponent.forEach(( c ) => {
            	//Fix component values from project -> offer
            	if (c['alloc_count']!== undefined) {
            		c['count']=c['alloc_count'];
            	}
            	if (c['price_single']!== undefined) {
            		c['price_single']=c['offer_price_single'];
            	}
            	else {
            		if (c['unit_price']!== undefined) {
            			c['price_single']=c['unit_price'];
            		}

            	}
            	if (c['price_sell_single']!== undefined) {
            		c['price_sell_single']=c['offer_sell_price_single'];
            	}
            	if (c['balance_percent']!== undefined) {
            		c['profit_percent']=c['balance_percent'];
            	}
            	
                tree = this.componentAddToTree( tree, c, project.id );
                //console.log("lisatty komponentti", c);
            });
        }
        
        if( orig.children.length > 0 )
        {
        	orig.children.forEach(( c ) => {
        		c.parent=project;
        		c.code = getLastCodePart( c.project_code );
        		tree=this.projectAddCopyToTree( tree, c, c );
            });
        }
        this.setState({ treeUnsaved: true });
        return tree;
    }

    componentAdd( component )
    {
        this.setState({ loading: true });

        api({
            method: 'get',
            url: `project/offer/component/${ component.id }/format`,
        }).then(( response ) => {
            this.setState({ loading: false });

            let  newComponent = {
                component: response,
            };

            let tree = this.state.tree.slice(0);
            tree = this.componentAddToTree( tree, newComponent, this.state.selectedProject.id );
            this.calculateTree( tree );
            this.syncWorksIfNeeded();
            this.setState({ treeUnsaved: true });

        }).catch( ( error ) => {
            errorPopper(error, tr('get_error') );
            this.setState({ loading: false });
        });
    }

    componentAddOutsideStorage( cType )
    {
    
        const tmpComponent = {
            type_id: cType.id,
            type_name: cType.name,
            type_text: cType.text,
        }
        if (cType.id==1) {
        	tmpComponent['save_as_new']=this.state.component_save_as_new_default;
        }
        let tree = this.state.tree.slice(0);
        tree = this.componentAddToTree( tree, tmpComponent, this.state.selectedProject.id );
        this.calculateTree( tree );

        this.setState({ treeUnsaved: true });
    }


    // ----------------------------------------
    //  Managing component views and filters
    // ----------------------------------------

    showProfitAs( value )
    {
         this.setState({ showProfitAs: value });
    }

    showPriceAs( value )
    {
         this.setState({ showPriceAs: value });
    }

    showPriceSellAs( value )
    {
         this.setState({ showPriceSellAs: value });
    }

    setComponentShowType( type )
    {
        this.setState({ componentShowType: type });
    }

    toggleComponentMoneyFilter( type )
    {
        let filters = this.state.componentMoneyFilters.slice(0);
        if( filters.includes( type ) )
            filters = filters.filter( f => f !== type )
        else
            filters.push( type );
        this.setState({ componentMoneyFilters: filters });
    }

    toggleComponentCalculatableVisible( type )
    {
        let filters = this.state.componentCalculatableVisible.slice(0);
        if( filters.includes( type ) )
            filters = filters.filter( f => f !== type )
        else
            filters.push( type );
        this.setState({ componentCalculatableVisible: filters });
    }



    // ----------------------------------------------
    //  Tree management functions
    // ----------------------------------------------

    toggleProjectTreeCumulativeDetails()
    {
        this.setState({ selectedDetailCumulative: !this.state.selectedDetailCumulative });
    }

    addSubProjectToTree( tree, project, parent, newComponent = [] )
    {
        this.newCounter++;
        project = {
            ...project,
            project_code: `${parent.project_code}.${project.code}`,
            id: `new${this.newCounter}`,
            parent_id: parent.id,
            components: [],
            children: [],
        };

        tree = mapTree( tree, ( item ) => {
            if( item.id === parent.id )
            {
                item.childsOpen = true;
                item.children.push( project );
            }
            return item;
        });

        if( newComponent.length > 0 )
        {
            newComponent.forEach(( c ) => {
                tree = this.componentAddToTree( tree, c, project.id );
            });
        }

        this.setState({ treeUnsaved: true });

        return tree;
    }

    componentAddToTree( tree, c, subProjectId, atIndex = null )
    {
        // Get value from component, own value overrides related value
        // returns null if nothing is found
        let getValue = ( ownField, relatedField = null ) => {
            if( !relatedField ) relatedField = ownField;

            let tmp = null;
            if( c.component && c.component[ relatedField ]!=null ) tmp = c.component[ relatedField ];
            if( c[ ownField ] ) tmp = c[ ownField ];
            return tmp;
        };

        tree = mapTree( tree, ( item ) => {
            if( item.id == subProjectId )
            {
                this.newCounter++;

                let count = null;
                if( c.count ) count = c.count;

                let name = getValue( 'name' );
                let typeId = getValue( 'type_id' );
                let typeName = getValue( 'type_name' );
                let typeText = getValue( 'type_text' );
                let priceSingle = getValue( 'price_single', 'price' );
                let priceSellSingle = getValue( 'price_sell_single', 'price_sell' );
                if( !typeId || !typeName || !typeText )
                {
                    console.error('componentAddToTree: Adding component to tree without type')
                }

                let profitPercent = null;
                //profitPercent = priceToProfitPercent( priceSingle, priceSellSingle )
                if (priceSingle == null && getValue("profit_percent")==0){
                    priceSingle = priceSellSingle;
                }
                profitPercent =   ( ( ( priceSingle - priceSellSingle ) / priceSellSingle ) * -1 * 100 );
                if( c.profit_percent )
                {
                    profitPercent = c.profit_percent;
                    priceSellSingle = profitPercentToSellPrice( priceSingle, profitPercent )
                }

                let priceAll = null;
                if( priceSingle && count ) priceAll = priceSingle * count;

                let priceSellAll = null;
                if( priceSellSingle && count ) priceSellAll = priceSellSingle * count;

                let profitAll = null;
                if( priceAll && priceSellAll ) profitAll = ( priceSellAll - priceAll );

                let profitSingle = null;
                if( priceSingle && priceSellSingle ) profitSingle = ( priceSellSingle - priceSingle );


                const round = ( value, decimal = 2 ) => {
                    value = roundToDecimals( value, decimal );
                    if( isNaN( value ) ) return null;
                    return value;
                }

                count           = round( count );
                priceSingle     = round( priceSingle );
                priceSellSingle = round( priceSellSingle );
                let profitPercentRounded   = round(profitPercent, 1);
                priceAll        = round( priceAll );
                priceSellAll    = round( priceSellAll );
                profitAll       = round( profitAll );
                profitSingle    = round( profitSingle );

                let newComponent = {
                    id: `new${this.newCounter}`,
                    name: name,
                    project_id: subProjectId,
                    component: c.component,
                    
                    save_as_new: c.save_as_new,
                    
                    type_id: typeId,
                    type_name: typeName,
                    type_text: typeText,

                    count: count,
                    price_single: priceSingle,
                    price_all: priceAll,
                    price_sell_single: priceSellSingle,
                    price_sell_all: priceSellAll,
                    profit_percent: profitPercent,
                    profit_percent_rounded: profitPercentRounded,
                    profit_all: profitAll,
                    profit_single: profitSingle,

                };
                

                if( atIndex )
                    item.components.splice( atIndex, 0, newComponent );
                else
                    item.components.push( newComponent );

            }
            return item;
        });
        this.setState({ treeUnsaved: true });
        return tree;
    }

    massListImported( data )
    {
        let tree = this.state.tree.slice(0);
        const parent = findItemById( tree,  data.projectId, 'children' );

        let subProjects = data.subProjects;
        subProjects.sort(function (a, b) {
            let aCodeLength = 1;
            let bCodeLength = 1;
            if (a.code) {
                aCodeLength = a.code.toString().split(".");
            }
            if (b.code) {
                bCodeLength = b.code.toString().split(".");
            }

            if (aCodeLength < bCodeLength) {
                return -1;
            }
            if (aCodeLength > bCodeLength) {
                return 1;
            }

            // length is equal
            return 0;
        });

        subProjects.forEach((sp) => {
            let codes = [];
            if (sp.code) {
                codes = sp.code.toString().split(".");
            }
            let parentProject = findItemById(tree, data.projectId, 'children');
            for (let i = 0; i < codes.length; i++){
                let findedProject = parentProject.children.find(p => {
                    return p.project_code.split(".").pop() == codes[i];
                });
                if (!findedProject) {
                    let projectData = {
                        code: codes[i],
                        name: tr("new_subproject"),
                        components:[]
                        
                    }
                    //if last
                    if (i === (codes.length - 1)) {
                        
                        projectData.components = sp.components;
                        projectData.name = sp.name;
                        tree = this.addSubProjectToTree(tree, projectData, parentProject, projectData.components);
                    }
                    else {
                        
                        tree = this.addSubProjectToTree(tree, projectData, parentProject);
                        parentProject = findItemById(tree, data.projectId, 'children');
                        i = -1;
                    }
                    
                }
                else {
                    parentProject = findedProject;
                    //if last code
                    if (i === (codes.length - 1)) {
                        sp.components.forEach((c) => {
                            tree = this.componentAddToTree(tree, c, parentProject.id);
                        });
                       
                    }
                }
            }
            //tree = this.addSubProjectToTree(tree, sp, parent, sp.components);
        });
        

        // let allProjects = [];
        // const findProjects = (project) => {
        //     if (typeof (project) == 'object') {
        //         allProjects.push(project);
        //         project.children.forEach(subProject => {
        //             findProjects(subProject);
        //         });
        //     }
        // }
        // findProjects(tree[0]);

        // data.subProjects.forEach(( sp ) => {
        //     tree = this.addSubProjectToTree(tree, sp, parent, sp.components );
        // });

        data.components.forEach(( c ) => {
            tree = this.componentAddToTree( tree, c,  data.projectId );
        });

        this.calculateTree( tree );

        this.syncWorksIfNeeded();

        this.setState({
            massListShow: false,
            massListProjectList: [],
        });
    }



    /*
     * For performance reasons we only update settings to project tree
     * when selected project is changed or when user clicks save button
     *
     * Otherwise we should loop whole tree structure on each change
     */
    updateSelectedProjectSettings()
    {
        const project = this.state.selectedProject;
        if(!project ) return false;

        this.editProject({
            id:                 project.id,
            description:        project.description,
            eMail:              project.eMail,          // JAr
            start: project.start,
            end: project.end,
        });
    }

    selectNode( id )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            item.__selected = false;
            if( id === item.id )
            {
                item.__selected = true;

                if( item.children )
                    item.__open = true;
            }

            return item;
        });

        this.setState({ tree: tree });
    }

    selectProject( project )
    {
        this.updateSelectedProjectSettings();
        this.selectNode( project.id );
        this.setState({ selectedProject: project });
    }

    // ----------------------------------------
    //  Component moving
    // ----------------------------------------

    selectComponent( component )
    {
        if( this.state.locked ) return null;

        let selected = this.state.selectedComponents.slice(0);

        let checked = selected.some( sc => sc.id === component.id );
        if( checked )
            selected = selected.filter( sc => sc.id !== component.id );
        else
            selected.push( component );
        this.setState({ selectedComponents: selected });
    }

    moveSelectedComponents( subProjectId )
    {
        let selectedComponents = this.state.selectedComponents.slice( 0 );
        let tree = this.state.tree.slice(0);

        selectedComponents.forEach( sc => {

            tree = mapTree( tree, ( item ) => {

                // Remove old selectedComponents
                item.components = item.components.filter(c => sc.id !== c.id );

                // Add the new item
                if( item.id === subProjectId )
                {
                    let c = { ...sc };
                    c.project_id = subProjectId;
                    item.components.push(c);
                }
                return item;
            });
        });

        this.calculateTree( tree );
        this.syncWorksIfNeeded();

        this.setState({
            selectedComponents: [],
        });
    }

    /**
     * If all selected components are same type and in same project, they can be merged. Merging components
     * adds the counts of all selected components and save them as single component.
     */
    mergeSelectedComponents()
    {
        const project = this.state.selectedProject;

        let selectedComponents = this.state.selectedComponents.slice( 0 );

        // Get count and calculate price avarages
        let count = 0;
        let priceSingle = 0;
        let priceSellSingle = 0;
        selectedComponents.forEach( sc => {
            count += parseFloat( sc.count );
            priceSingle += parseFloat( sc.price_single );
            priceSellSingle += parseFloat( sc.price_sell_single );
        });
        priceSingle = ( priceSingle / count );
        priceSellSingle = ( priceSellSingle / count );


        let newComponent = {
            component: selectedComponents[ 0 ].component,
            count: count,
            price_single: priceSingle,
            price_sell_single: priceSellSingle,
        }

        // Find the index where new component will be inserted
        const atIndex = project.components.findIndex( c => {
            return Boolean ( selectedComponents.find( sc => sc.id === c.id  ) );
        });

        // Remove components
        this.updateTreeItem( project.id, ( item ) => {
            selectedComponents.forEach( sc => {
                item.components = item.components.filter(c => sc.id !== c.id );
            });
            return item;
        });

        let tree = this.state.tree.slice( 0 );
        this.componentAddToTree( tree, newComponent, project.id, atIndex )
        this.calculateTree( tree );

        this.syncWorksIfNeeded();
        this.setState({ selectedComponents: [] });
    }

    divideOpen( project, component )
    {
        this.setState({
            divideComponentShow: true,
            divideComponent: component,
            divideProject: project,
        });
    }

    divideSubmit( value )
    {
        value = parseFloat( value );

        if( isNaN( value ) )
        {
            window.emitter.emit('popper', {
                type: 'warning',
                content: <strong>{ tr('value_not_numeric') }</strong>
            });
            return null;
        }

        if( parseFloat( value ) >= parseFloat( this.state.divideComponent.count ) )
        {
            window.emitter.emit('popper', {
                type: 'warning',
                content: <strong>{ tr('separately_transferred_quantity_greater') }</strong>
            });
            return null;
        }

        let project = this.state.divideProject;
        let component = this.state.divideComponent;

        let countForNew = round( value );
        let countForOld = round( component.count - countForNew );

        let { id, ...newComponent } = component;
        newComponent.count = countForNew;


        // Find index wheren new component will be inserted
        let newCIndex = project.components.findIndex( c => c.id === component.id );

        let tree = this.state.tree.slice(0);
        tree = this.componentAddToTree( tree, newComponent, project.id, newCIndex + 1 );
        this.calculateTree( tree );

        this.changeCount( project, component, countForOld, true );

        this.syncWorksIfNeeded();
    }

    // ----------------------------------------
    //  Editing component inputs
    // ----------------------------------------

    changeName ( project, component, value )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                    c.name = value;
                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
    }
    
    changeSaveAsNew( project, component )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                    c.save_as_new = !c.save_as_new
                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
    }


    changeCount( project, component, value, blur = false )
    {
        // Work component count cannot be edited if works are synced
        if( this.state.workSynced && component.type_name === 'work' )
        {
            const isSynced = this.state.calculatables.find( calc => {
                if( !component.component ) return false;
                return ( calc.work_id && calc.work_id === component.component.id )
            });

            if( isSynced )
            {
                if( !blur )
                {
                    window.emitter.emit('popper', {
                        type: 'info',
                        content: <div>
                            <div><strong>{ tr('edit_quantity_of_works_info') }</strong></div>
                        </div>
                    });
                }
                return null;
            }
        }

        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {

                if( c.id === component.id )
                {
                    c.count = onlyNumber( value, true );
                    if( blur ) c.count = round( value );

                    c.price_all      = round( c.count * c.price_single );
                    c.price_sell_all = round( profitPercentToSellPrice( c.price_all , c.profit_percent ) );
                    c.profit_all     = round( c.price_sell_all - c.price_all );

                }
                return c;
            })
            return item;
        });
        this.syncWorksIfNeeded();

        this.setState({ treeUnsaved: true });

    }

    changePriceSingle( project, component, value, blur = false )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {

                if( c.id === component.id )
                {
                    c.price_single = onlyNumber( value, true );
                    if( blur ) c.price_single = round( value );

                    c.price_all         = round( c.count * c.price_single );
                    c.price_sell_single = round( profitPercentToSellPrice( c.price_single , c.profit_percent ) );
                    c.price_sell_all    = round( profitPercentToSellPrice( c.price_all , c.profit_percent ) );
                    c.profit_all        = round( c.price_sell_all - c.price_all );
                    c.profit_single     = round( c.price_sell_single - c.price_single );
                }
                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changeDiscount( project, component, value, blur = false )
    {
        /*
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {

                if( c.id === component.id )
                {
                    c.wholeseller_discount = onlyNumber( value, true );
                    if( blur ) c.price_single = round( value );

                    //c.price_single = c.price_single * (100 - c.wholeseller_discount) / 100;

                    c.price_all         = round( c.count * (c.price_single * (100 - c.wholeseller_discount) / 100));
                    c.price_sell_single = round( profitPercentToSellPrice( c.price_single , c.profit_percent ) );
                    c.price_sell_all    = round( profitPercentToSellPrice( c.price_all , c.profit_percent ) );
                    c.profit_all        = round( c.price_sell_all - c.price_all );
                    c.profit_single     = round( c.price_sell_single - c.price_single );
                }
                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
        */
    }

    changePriceAll( project, component, value, blur = false )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {

                if( c.id === component.id )
                {
                    c.price_all = onlyNumber( value, true );
                    if( blur ) c.price_all = round( value );

                    c.price_single      = round( c.price_all / c.count );
                    c.price_sell_single = round( profitPercentToSellPrice( c.price_single , c.profit_percent ) );
                    c.price_sell_all    = round( profitPercentToSellPrice( c.price_all , c.profit_percent ) );
                    c.profit_all        = round( c.price_sell_all - c.price_all );
                    c.profit_single     = round( c.price_sell_single - c.price_single );

                }

                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changeProfitPercent( project, component, value, blur = false )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {

                if( c.id === component.id )
                {
                    c.profit_percent = onlyNumber(value, true);
                    c.profit_percent_rounded = c.profit_percent;
                    let tmpPercent = c.profit_percent;

                    if( blur )
                    {
                        tmpPercent = round( tmpPercent , 1);
                        if( tmpPercent > 99.9 )
                        {
                            window.emitter.emit('popper', {
                                type: 'warning',
                                content: <strong>{ tr('gross_profit_over_99_warning') }</strong>
                            });
                            tmpPercent = 99.9;
                        }
                        c.profit_percent_rounded = tmpPercent;
                    }
                    else if( tmpPercent > 99.9 ) tmpPercent = 99.9;

                    c.price_sell_single = round( profitPercentToSellPrice( c.price_single , tmpPercent ) );
                    c.price_sell_all    = round( profitPercentToSellPrice( c.price_all , tmpPercent ) );
                    c.profit_all        = round( c.price_sell_all - c.price_all );
                    c.profit_single     = round( c.price_sell_single - c.price_single );
                }

                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changePriceSellSingle( project, component, value, blur = false )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {

                if( c.id === component.id )
                {
                    c.price_sell_single = onlyNumber( value, true );
                    if( blur ) c.price_sell_single = round( value );

                    c.price_sell_all    = round( c.price_sell_single * c.count );
                    c.profit_percent = priceToProfitPercent(c.price_all, c.price_sell_all);
                    c.profit_percent_rounded = round(c.profit_percent, 1);

                    if( blur )
                        this.changeProfitPercent( project, component, c.profit_percent, true );
                    else
                    {
                        if( c.profit_percent > 99.9 ) c.profit_percent = 99.9;
                        c.profit_all        = round( c.price_sell_all - c.price_all );
                        c.profit_single     = round( c.price_sell_single - c.price_single );
                    }
                }
                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changePriceSellAll( project, component, value, blur = false )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {

                if( c.id === component.id )
                {
                    c.price_sell_all = onlyNumber( value, true );
                    if( blur ) c.price_sell_all = round( value );

                    c.price_sell_single = round( c.price_sell_all / c.count );
                    c.profit_percent = priceToProfitPercent(c.price_all, c.price_sell_all);
                    c.profit_percent_rounded = round(c.profit_percent, 1);

                    if( blur )
                        this.changeProfitPercent( project, component, c.profit_percent, true );
                    else
                    {
                        if( c.profit_percent > 99.9 ) c.profit_percent = 99.9;
                        c.profit_all        = round( c.price_sell_all - c.price_all );
                        c.profit_single     = round( c.price_sell_single - c.price_single );
                    }
                }
                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changeProfitSingle( project, component, value, blur = false )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.profit_single = onlyNumber( value, true );
                    if( blur ) c.profit_single = round( value );

                    c.price_sell_single = round( parseFloat( c.price_single ) + parseFloat( c.profit_single ) );
                    c.profit_percent = priceToProfitPercent(c.price_single, c.price_sell_single);
                    c.profit_percent_rounded = round(c.profit_percent, 1);

                    if( blur )
                        this.changeProfitPercent( project, component, c.profit_percent, true );
                    else
                    {
                        if( c.profit_percent > 99.9 ) c.profit_percent = 99.9;
                        c.price_sell_all    = round( profitPercentToSellPrice( c.price_all , c.profit_percent ) );
                        c.profit_all        = round( c.price_sell_all - c.price_all );
                    }
                }
                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    changeProfitAll( project, component, value, blur = false )
    {
        this.updateTreeItem( project.id, ( item ) => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.profit_all = onlyNumber( value, true );
                    if( blur ) c.profit_all = round( value );

                    c.price_sell_all    = round( parseFloat( c.price_all ) + parseFloat( c.profit_all ) );
                    c.profit_percent = priceToProfitPercent(c.price_all, c.price_sell_all);
                    c.profit_percent_rounded = round(c.profit_percent, 1);

                    if( blur )
                        this.changeProfitPercent( project, component, c.profit_percent, true );
                    else
                    {
                        if( c.profit_percent > 99.9 ) c.profit_percent = 99.9;
                        c.price_sell_single = round( profitPercentToSellPrice( c.price_single , c.profit_percent ) );
                        c.profit_single     = round( c.price_sell_single - c.price_single );
                    }
                }
                return c;
            })
            return item;
        });
        this.setState({ treeUnsaved: true });
    }

    projectSettingsChange( e, targetName=null )
    {
        let selectedProject = { ...this.state.selectedProject };
        // console.log(e, e.target)
        const name = targetName || e.target.name;
        let value = e?.target?.value;

        // if( name == "travel_budget" || name == "expense_budget" || name == "allowance_budget" )
            // value = onlyNumber( value );
        // if( name == "begin_date" || name == "end_date" )
            // value = dateInputToSql( value );

        if (name == "start" || name == "end") {
            value = e;
            // console.log(value);
            // value = dateInputToSql( value );
        }

        selectedProject[ name ] = value;
        this.setState({ selectedProject });
        this.setState({ treeUnsaved: true });
    }

    changeSettingSetVisibility(users) {
        let project = { ...this.state.project };
        project['project_visible_to_users'] = users;

        this.setState({ project, treeUnsaved: true });
    }

    handleProjectTreeDetailChange( e )
    {
        this.setState({ selectedDetail: e.target.value });
    }

    // ----------------------------------------
    //  Work syncinig functions
    // ----------------------------------------

    syncWorksIfNeeded()
    {
        if( this.state.workSynced ) this.syncWorks();
    }

    toggleWorkSyncClicked()
    {
        const oldWorkSynced = this.state.workSynced
        if( oldWorkSynced )
            this.setState({ workSynced: false });
        else
        {
            const workSynced = this.isWorkSynced();
            if( workSynced )
                this.setState({ workSynced: true });
            else
                this.setState({ workSyncedConfirmShow: true });
        }
    }

    toggleWorkSyncConfirmed()
    {
        this.setState({ workSynced: true });
        this.syncWorks();
    }

    syncWorks()
    {
        let tree = this.state.tree.slice(0);
        let workCalculatables = this.state.calculatables.filter(calc => calc.work_id);

        //delete dublicates
        let uniquesWorkCalculatables = [];
        for (let calculatable of workCalculatables) {
            let unique = true;
            for (let findUnique of uniquesWorkCalculatables) {
                if (findUnique.work_id == calculatable.work_id) {
                    unique = false;
                }
            }
            if (unique) {
                uniquesWorkCalculatables.push(calculatable);
            }
        }
        workCalculatables = uniquesWorkCalculatables;

        iterateTree( tree , ( project ) => {
            workCalculatables.forEach(wc => {

                const count = project.calculated.own.item.calculatables[ wc.work_id ];
                let existingWorks = this.existingWorks(project, wc.work_id);

                if( count === 0 )
                {
                    existingWorks.forEach(ew => {


                        this.removeComponent( ew.id );
                    });

                }
                else if( count > 0 )
                {

                    // If there are no components we need to add one and loop this thing again
                    if( !existingWorks.length )
                    {
                        let newComponent = {
                            count: count,
                            component: wc.work_component,
                        }


                        tree = this.componentAddToTree( tree, newComponent, project.id );
                        existingWorks = this.existingWorks( project, wc.work_id );
                    }
                    else if( existingWorks.length > 1 )
                    {
                        // Remove other components
                        existingWorks.forEach((ew, i) => { if (i > 0) this.removeComponent(ew.id) });


                    }
                    let work = existingWorks[ 0 ];
                    work.count = roundToDecimals(count, 2);
                    work.price_all = round(work.count * work.price_single);
                    work.price_sell_all = round(profitPercentToSellPrice(work.price_all, work.profit_percent));
                    work.profit_all = round(work.price_sell_all - work.price_all)
                    
                }
            });
        });
        this.calculateTree( tree );
    }

    isWorkSynced()
    {
        let workSynced = true;

        let tree = this.state.tree;
        const workCalculatables = this.state.calculatables.filter( calc => calc.work_id );
        iterateTree( tree , ( project ) => {
            workCalculatables.forEach( wc => {
                if( !workSynced ) return null;

                let count = project.calculated.own.item.calculatables[wc.work_id];
                // let existingWorks = project.components.filter( c => c.component.id === wc.work_id )
                let existingWorks = this.existingWorks( project, wc.work_id );

                if( existingWorks.length )
                {
                    let existingCount = 0;
                    existingWorks.forEach( ew => {
                        if( ew.count ) existingCount += parseFloat( ew.count );
                    });
                    workSynced = ( roundToDecimals( count, 2 ) === roundToDecimals( existingCount, 2 ) );
                }
                else if( count > 0 )
                    workSynced = false;
            });
        });
        return workSynced;
    }

    existingWorks( project, findId )
    {
        return project.components.filter( c => {
            if( c.component )
                return ( c.component.id === findId );
            return false;
        });
    }


    // ----------------------------------------
    //  Budget rendering functions
    // ----------------------------------------

    renderSheetSelector()
    {
        const newClass  = ( this.state.loading || this.state.locked ? " disabled" : "" );
        const copyClass = ( this.state.loading || this.state.locked ? " disabled" : "" );
        const removeClass = ( this.state.loading || this.state.locked || this.state.sheets.length <= 1 ? " disabled" : "" );

        const sheetOptions = this.state.sheets.map(( item ) => {
            return { value: item.id, label: item.name }
        });

        return (
            <div className="sheetContainer">
                 <ApInputStack gap="0">
                    <ApAddon noRightBorder width="170px">
                        <SvgIcon className="icon" icon="layer-group" type="solid" />
                        { tr('calculation_version') }
                    </ApAddon>
                    <ApInput
                        type="select"
                        options={ sheetOptions }
                        id="sheet_select"
                        name="sheet_select"
                        value={ this.state.sheetId }
                        onChange={ (e) => { this.selectSheet( parseInt( e.target.value, 10 ) ) } }
                        disabled={ this.state.loading || !this.state.sheets.length }
                        loading={ this.state.loading }
                    />      
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={tr("rename")}>
                            <div className={"apSimpleButton" + newClass} onClick={() => this.setState({ renameSheetShow: true })}>
                                <SvgIcon icon="pen-square" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>

                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('create_new') }>
                            <div className={ "apSimpleButton" + newClass } onClick={ () => this.newSheet() }>
                                <SvgIcon icon="plus-square" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('copy_current_one') }>
                            <div className={ "apSimpleButton" + copyClass } onClick={ () => this.newSheet( this.getSheetName( this.state.sheetId ) + " (kopio)", this.state.sheetId ) }>
                                <SvgIcon icon="clone" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete_current_one') }>
                            <div className={ "apSimpleButton" + removeClass } onClick={ () => this.setState({ removeSheetConfirmShow: true }) }>
                                <SvgIcon icon="trash-alt" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                    

                </ApInputStack>

                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('calculation_version_info') }
                </div>
            </div>
        );
    }


     
    

    renderBudgetProjectTree()
    {
        const tree = this.state.tree.slice();

        if( !tree[0] )
            return false;

        const renderProject = ( project, level = 0 ) => {
            // console.log('project_tree_render', project);
            // console.log('project_tree_level', level);
            const group = ( this.state.selectedDetailCumulative ? "all" : "own" );

            let value = false;

            let selectedItems = 0;
            if( this.state.selectedComponents.length > 0 )
            {
                project.components.forEach( c => {
                    if( this.state.selectedComponents.some( sc => sc.id === c.id ) )
                        selectedItems++;
                });

                value = false;
            }
            else {
                let found = budgetDetailOptions.find(o => o.value === this.state.selectedDetail);
                // console.log('project_budgetDetailOptions_found', found);
                if( found )
                    if (found.value === "work_percent") {
                        value = 100 -
                            priceToProfitPercent(
                                parseFloat(project.calculated[group].work.price_sell_all),
                                parseFloat(project.calculated[group].total.price_sell_all)
                            );
                        value = `${formatMoney(value, found.decimals)} ${found.unit}`;
                    } else if(found.value === "items_percent") {
                        value = 100 -
                            priceToProfitPercent(
                                parseFloat(project.calculated[group].item.price_sell_all),
                                parseFloat(project.calculated[group].total.price_sell_all)
                            );
                        value = `${formatMoney(value, found.decimals)} ${found.unit}`;
                    } else {
                        value = formatMoney( project.calculated[ group ].total[ found.value ], found.decimals ) + " " + found.unit;
                    }
                else
                {
                    console.error('Option not found');
                    value = null;
                }
            }


            return <div className="project">
                <ApTooltip block text={project.calculated && project.invalid ? project.invalid : null}>
                    { project.name }<br />
                    <small>{ project.project_code }</small>
                </ApTooltip>

                { selectedItems > 0 &&
                    <span className="apBadge blue">{ selectedItems }</span>
                }

                { value &&
                    <div className="value">{ value }</div>
                }

                { this.state.selectedComponents.length > 0 &&
                    <div className="moveToButton">
                        <ApTooltip text={ tr('move_selected_components_here') }>
                            <ApButton size="tiny" onClick={ () => { this.moveSelectedComponents( project.id ) } }>
                                <SvgIcon icon="sign-in-alt" type="solid" />
                            </ApButton>
                        </ApTooltip>
                    </div>
                }
            </div>
        };

        return <div className="apProjectTree">
            <div className="detailPicker">
                <ApInputStack gap="0">
                    <ApAddon noRightBorder width="50px">
                        <ApTooltip block text={ this.state.selectedDetailCumulative ? tr('subprojects_included') : tr('subprojects_not_included') }>
                            <div className="apSimpleButton" onClick={ this.toggleProjectTreeCumulativeDetails }>
                                <SvgIcon icon={ this.state.selectedDetailCumulative ? "stream" : "minus" } type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                    <ApInput
                        type="select"
                        options={ budgetDetailOptions }
                        id="projectTreeDetail"
                        name="projectTreeDetail"
                        value={ this.state.selectedDetail }
                        onChange={ this.handleProjectTreeDetailChange }
                        disabled={ this.state.loading }
                        loading={ this.state.loading }
                    />
                </ApInputStack>
            </div>

            <ApTree
                tree={ tree }
                nodeRenderer={ renderProject }
                onTreeChange={ this.calculateTree }
                onNodeClick={ this.selectProject }
            />

            {/*

            <ul className="root">
                { renderProject( tree[0] ) }
            </ul>

            */ }
            <hr />

            <ApButton fullWidth color="white" size="small" onClick={ () => this.addSubProjectClick() } disabled={ this.state.locked }>
                <SvgIcon icon="plus" type="solid" />
                { tr('new_subproject') }
            </ApButton>

            <ApButton fullWidth color="green" size="small" onClick={ this.openMassList } disabled={ this.state.locked }>
                <SvgIcon icon="file-excel" type="solid" />
                { tr('import_mass_list') }
            </ApButton>

            <div className="relatedWorkContainer">
                <div className="rTitle">{ tr('working_hours') }</div>
                <div className="rText">
                    <div className="apSwitchBlock small">
                        <label htmlFor="toggle-work-synced-switch" className="info">
                            <small>{ tr('calculate_working_hours_automatically') }</small>
                        </label>
                        <ApSwitch
                            small
                            id="toggle-work-synced-switch"
                            on={ this.state.workSynced }
                            onChange={ this.toggleWorkSyncClicked }
                            disabled={ this.state.loading || this.state.locked }
                        />
                    </div>

                    <br />
                    <br />
                </div>
            </div>

        </div>
    }

    renderBudgetSelectedProjectPath()
    {
        const currentProject = this.state.selectedProject;
        if( !currentProject )
            return null;

        const withDropdown = ( item ) => {

            const rootProject = ( currentProject.parent_id ? false : true );
            const projectNotRemovable = keyExists(currentProject, 'calculated.all.notRemovable', true, false);

            return (
                <div className={ "inner current" + ( rootProject ? " withIcon" : "" ) }>
                    { rootProject && <SvgIcon className="stepIcon" icon={ item.icon } type="solid" /> }
                    <strong>{ currentProject.name }</strong>
                    <small>{ currentProject.project_code }</small>

                    <ApDropdown
                        actionId={ currentProject }
                        actions={[
                            {
                                label: tr('add_subproject'),
                                icon: "plus",
                                disabled: this.state.locked,
                                action: ( item, closeFunc ) => {
                                    this.addSubProjectClick( item );
                                    closeFunc();
                                },
                            },
                            {
                                label: tr('edit_project'),
                                icon: "edit",
                                // disabled: rootProject || this.state.locked,
                                disabled: this.state.locked,
                                action: ( item, closeFunc ) => {
                                    this.editSubProjectClick( item );
                                    closeFunc();
                                },
                            },
                            {
                                label: tr('copy_project'),
                                icon: "copy",
                                disabled: rootProject || this.state.locked,
                                action: ( item, closeFunc ) => {
                                    this.copyProjectClick( item );
                                    closeFunc();
                                },
                            },
                            {
                                label: tr('copy_project_from_another_project'),
                                icon: "copy",
                                disabled: this.state.locked,
                                action: ( item, closeFunc ) => {
                                    this.copyProjectToAnotherClick( item );
                                    closeFunc();
                                },
                            },  
                            {
                                label: tr('delete_project'),
                                icon: "trash-alt",
                                disabled: rootProject || this.state.locked || projectNotRemovable,
                                action: ( item, closeFunc ) => {
                                    this.removeSubProjectClick( item );
                                    closeFunc();
                                }
                            },
                            {
                                label: tr('get_component_mass_list'),
                                icon: "file-excel",
                                disabled: this.state.treeUnsaved,
                                tooltip: this.state.treeUnsaved ? tr("save_first") : null,
                                action: (item, closeFunc) => {
                                    this.getComponentsMassList(item);
                                    closeFunc();
                                }
                            },
                            {
                                label: tr('update_prices'),
                                icon: "money-bill-wave",
                                disabled: this.state.locked,
                                action: (item, closeFunc) => {
                                    this.updatePrices(item);
                                    closeFunc();
                                }
                            },
                            {
                                label: tr('update_prices_all'),
                                icon: "money-bill-wave",
                                disabled: this.state.locked,
                                action: (item, closeFunc) => {
                                    this.updatePrices(item, true);
                                    closeFunc();
                                }
                            },
                        ]}
                    />
                </div>
            );
        };

        let items = [];
        const iterate = ( id, selected = false ) => {
            const project = findItemById( this.state.tree, id, 'children' );
            if( project )
            {
                items.unshift({
                    icon:           ( !project.parent_id ? "folder" : false ),
                    selected:       selected,
                    onClick:        ( selected ? false : () => this.selectProject( project ) ),
                    name:           project.name,
                    description:    project.project_code,
                    customRenderer: ( selected ? withDropdown : false ),
                });

                if( project.parent_id )
                    iterate( project.parent_id );
            }
        };

        iterate( currentProject.id, true );

        return (
            <ApSteps className="projectPath" steps={ items } />
        )
    }

    updatePrices(project, updateAll = false) {
        const tree = this.state.tree.slice(0);
        let update = false;

        mapTree(tree, (item) => {
            if (updateAll) {
                if (item.id === project.id) {
                    update = true;
                }

                if (update) {
                    item.components.forEach(component => {
                        this.changePriceSingle( item, component, component.component.price, true );
                        this.changeProfitPercent( item, component, component.component.profit_percent, true );
                    })
                }
            }
            else if (project.id === item.id) {
                item.components.forEach(component => {
                    this.changePriceSingle( item, component, component.component.price, true );
                    this.changeProfitPercent( item, component, component.component.profit_percent, true );
                })
            }

            return item;
        })
    }

    renderBudgetComponents()
    {
        if( this.state.componentShowType === 'money' )
            return this.renderBudgetMoneyTable();
        else if( this.state.componentShowType === 'calculatables' )
            return this.renderBudgetComponentCalculatablesTable();
        else
            return <div>{ tr('unknown_tab') }</div>

    }

    renderBudgetFilterBar( detailedFilterDom = null )
    {
        const views = [
            { id: 'money', icon: 'money-bill-wave', label: tr('calculation'), tooltip: tr('show_calculation') },
            { id: 'calculatables', icon: 'ruler', label: tr('meters'), tooltip: tr('show_meters') },
        ];

        return <div className="filterBar">
            <div className="viewsTitle">
                { tr('show') }:
            </div>
            <div className="viewsSelect">
                <div className="apOptionBar smallerIcons">
                    { views.map( ( view ) => {

                        let optionClass = [ 'option', 'pointer' ];

                        if( this.state.componentShowType === view.id )
                            optionClass.push( 'selected' );

                        return <div key={ view.id } className={ optionClass.join(" ") } onClick={ () => this.setState({ componentShowType: view.id  }) }>
                            <ApTooltip text={ view.tooltip } block position="bottom">
                                <SvgIcon icon={ view.icon } type="solid" /> { view.label }
                            </ApTooltip>
                        </div>
                    })}
                </div>
            </div>
            <div className="rightFilter">
            { detailedFilterDom }
            </div>
        </div>
    }

    renderAddComponentInput()
    {
        if( this.state.locked ) return null;

        let menuActions = [];
        menuActions.push({ "divider": tr('add_external_component') });

        componentTypes.forEach( ct => {
            if (ct.name!="storage") {
                menuActions.push({
                    label: ct.text,
                    icon: getTypeIcon( ct.name ),
                    action: ( item, closeFunc ) => {
                        this.componentAddOutsideStorage( ct );
                        closeFunc();
                    },
                });
            }
        });

        return <ApInputStack gap="0">
            <ApSelect
                label={ tr('add_storage_component') }
                loading={ this.state.loading }
                value=""
                optionRenderer="component_detail"
                onChange={ this.componentAdd }
                objKeyId="id"
                objKeyValue="name"
                apiUrl="storage/components"
                apiData={{
                    include: [ 'identifiers' ],
                    formatter: 'offer_calculate',
                }}
            />
            <ApAddon noLeftBorder width="40px">
                <ApDropdown
                    actions={ menuActions }
                    button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
                />
            </ApAddon>
        </ApInputStack>

    }

    renderBudgetMoveHereButton()
    {
        if( this.state.locked ) return null;
        if( this.state.selectedComponents.length === 0 ) return null;
        return <ApButton color="white" onClick={ () => { this.moveSelectedComponents( this.state.selectedProject.id ) } }>
            <SvgIcon icon="sign-in-alt" type="solid" />
        </ApButton>
    }

    handleProjectMeter(item, editNewItem)
    {
        if (!this.state.tree[0]) return;
        const data = JSON.parse(JSON.stringify(this.state.tree));
        if (!editNewItem && item.id) {
            // muokkaa tallennettua projektimittaria
            const foundIndex = data[0].project_meters.findIndex(meter => meter.id == item.id);
            data[0].project_meters[foundIndex] = item;
        } else if (editNewItem) { 
            // muokkaa tallentamatonta projektimittaria
            data[0].project_meters[item.index] = item;
        } else {
            // uusi projektimittari
            data[0].project_meters.push(item);
        }
        this.setState({tree: data});
    }

    deleteProjectMeter(index) {
        if (!this.state.tree[0]) return;

        const data = JSON.parse(JSON.stringify(this.state.tree));
        data[0].project_meters.splice(index, 1);

        this.setState({tree: data});
    }

    renderBudgetStatistic()
    {
        return <BudgetStatistics 
            selectedProject={this.state.selectedProject}
            budgetDetailOptions={budgetDetailOptions}
            handleProjectMeter={this.handleProjectMeter}
            deleteProjectMeter={this.deleteProjectMeter}
            mainProject={this.state.tree[0]}
        />
    }

    openEditComment(commentData) {
        this.setState({ showEditComment: true, commentData: commentData });
    }

    saveComment(comment) {
        this.updateTreeItem(this.state.commentData.projectId, item => {
            let component = this.state.commentData.component
            
            item.components = item.components.map((c) => {
                if (c.id === component.id)
                    c.comment = comment;
                return c;
            });
            return item;

        });

        this.setState({ showEditComment: false });
    }
    groupsChange(groups) {
        //console.log(groups);
        let project = this.state.selectedProject;

        this.setState({ groups: groups });
    }
    openGroup(group) {
        let groups = this.state.groups.slice(0);
        groups = groups.map(g => {
            if (g.id === group.id)
                g.open = !g.open;
            return g;
        });
        this.groupsChange(groups);
    }
    setGroupName(group, value) {
        let groups = this.state.groups.slice(0);
        groups = groups.map(g => {
            if (g.id === group.id)
                g.name = value;
            return g;
        });
        this.groupsChange(groups);
    }

    removeGroup(group) {
        let groups = this.state.groups.slice(0);
        groups = groups.filter(g => {
            return group.id != g.id;
        });
        this.groupsChange(groups);
    }
    addGroup(group) {
        let groups = this.state.groups.slice(0);
        groups.push(group);
        this.groupsChange(groups);
    }

    addToGroup(g) {
        this.updateTreeItem(this.state.selectedProject.id, item => {
            let selected = this.state.selectedComponents.slice(0);
            //console.log("selected!!", selected)
           
                item.components = item.components.map((c) => {
                    for (let s of selected) {
                        if (c.id === s.id)
                            c.group_id = g.id;
                    }
                    return c;

                });
                return item;


        });
        this.clearSelected();

    }

    removeFromGroup(g) {

        this.updateTreeItem(this.state.selectedProject.id, item => {
            let selected = this.state.selectedComponents.slice(0);

           
            item.components = item.components.map((c) => {
                for (let s of selected) {
                    if (c.id === s.id)
                        c.group_id = null;
                }
                return c;
            });
            return item;


        });
        this.clearSelected();

    }

    breakGroup(g) {
        this.updateTreeItem(this.state.selectedProject.id, item => {
            
            item.components = item.components.map((c) => {
                if (g.id === c.group_id)
                    c.group_id = null;
                return c;

            });
            return item;
        });
        this.removeGroup(g);
        this.clearSelected();

    }
    createAndAddToGroup() {
        this.newCounter++;
        const gId = `new${this.newCounter}`;
        let name = tr('new_group');
        let group = {
            id: gId,
            name: name,
            open: true,
            project_id: this.state.project.id
        };
        let groups = this.state.groups.slice(0);
        groups.push(group);
        this.groupsChange(groups);
        this.addToGroup(group);


    }

    renderGroupMenu(g) {
        let actions = [];

        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;

        if (!disabled) {
            actions.push({
                label: tr('disassemble_group'),
                icon: "object-ungroup",
                action: (id, closeFunc) => {
                    this.breakGroup(g);
                    closeFunc();
                },
            });
        }

        if (actions.length)
            return <ApDropdown actions={actions} />
        return null;
    }

    clearSelected() {
        
        this.setState({ selectedComponents: [] });
    }

    //---------------------------------------------------------------
    renderGroup(g, rows, moneyCols, groupData = {}, allRows = [], showRows = [], calcVisible=[]) {

        let  project = this.state.selectedProject;
        
        
        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;

        let rowDoms = [];
        let totalPrice = 0;
        let countError = null;
        let priceError = null;

        if (showRows.length) {
            if (g.open) {
                rowDoms = showRows;
            }
        }
        else {
            
            rows.forEach((r, key) => {
                if (g.open) {
                    
                    rowDoms.push(this.renderComponentRow(project, r, this.getMoneyCols(r, moneyCols, project),"groupRowComponent"));
                }
    
                totalPrice += (r.count * r.order_price);
                if (!countError && !r.count)
                    countError = 'error';
    
                if (!priceError && !r.order_price)
                    priceError = 'error';
            });
        }

        let singlePrice = totalPrice / g.count;
        let trClass = ['groupRow'];
        if (g.open) trClass.push('open')
        if (!g.count) countError = 'error';

        let iconDom = null;
        let groupFunc = () => { };
        if (this.state.selectedComponents.length) {
            iconDom = <SvgIcon icon="plus-square" type="solid" />
            groupFunc = () => this.addToGroup(g);

            if (allRows.every(r => {

                for (let s of this.state.selectedComponents) {
                    if (s.id === r.id) {
                        return (r.group_id === g.id);
                    }
                }
                // if (this.props.selected.includes(r.id))
                //     return (r.group_id === g.id)
                return true;
            })) {
                iconDom = <SvgIcon icon="minus-square" type="solid" />
                groupFunc = () => this.removeFromGroup(g);
            }
        }


        let inputrows = [];
    
        inputrows.push(<td></td>);
       
        
            
        if (groupData.measureMode) {
            for (let calc of calcVisible) {
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{round(groupData.calculatables[calc.id])+" "+calc.unit}</td>);
            }
        }
        else {
            inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{groupData.componentCountSum}</td>);
            inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{groupData.wholesellerDiscountAverage}</td>);
            inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{groupData.price}</td>);
            inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{groupData.profit}</td>);
            inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{groupData.priceSell}</td>);
        }

        return <React.Fragment key={g.id}>
            <tr className={trClass.join(' ')}>
                <td className="selectTd" onClick={groupFunc}>
                    {iconDom}
                </td>
                <td className="groupTd" onClick={() => this.openGroup(g)}>
                    <SvgIcon icon="angle-right" type="solid" />
                </td>
                <td className="nameTd">
                    <ApInputSmall
                        style={{ textAlign: "left" }}
                        type="text"
                        value={g.name ? g.name : ''}
                        onChange={(e) => { this.setGroupName(g, e.target.value) }}
                        disabled={disabled}
                        validationState={(g.name) ? null : 'error'}
                    />
                </td>
                <td style={{ color: groupData.commentCount != 0 ? '#ffa726' : null, fontWeight: "bold" }}>{groupData.commentCount + " " + tr("pcs")}</td>
                {inputrows}


                {/* <td>&nbsp;</td> */}
                {/* {(this.state.type === 'delivery') ? <td key="reserve" className="fixed c-target"></td> : <td key="entry" className="fixed c-entry"></td>} */}

                {/* <td key="vat" className="fixed vat">
                    {"this.renderGroupVat(g)"}
                </td>
                <td key="netvisor-vat" className="fixed vat">
                    {"this.renderOrderNetvisorGroupVat(g)"}
                </td>
                {this.props.costCenterEnabled &&
                    <td key="cost-center" className="fixed vat">
                        {"this.renderGroupCostCenter(g)"}
                    </td>}
                <td key="tota_price" className="fixed total">
                      {formatMoney(totalPrice)} {this.state.currencySign} 
                </td>
                <td key="dispatch_ref" className="fixed dispatch-ref">

                </td>*/}

                <td className="fixed actions">
                    {this.renderGroupMenu(g)}
                </td>
            </tr>
            {rowDoms}
        </React.Fragment>

        /*
        onChange={ ( e ) => { this[ o.funcName ]( c, e.target.value ) } }
        onBlur={   ( e ) => { this[ o.funcName ]( c, e.target.value, true ) } }
        disabled={ o.disabled || this.props.locked }
        unit={ unit }
        noFocusDecimals={ decimal }
        tooltip= { tooltip }
        */
    }
//table alkaa
    renderBudgetMoneyTable()
    {
        const project = this.state.selectedProject;
        let rows = project.components;

        // Apply filter
        const filters = this.state.componentMoneyFilters;
        if( filters.length )
        {
            rows = rows.filter( row  => filters.includes( row.type_name ) );
        }

        const filterOptions = [
            { id: "item", icon: "puzzle-piece", label: tr('components_alt') },
            { id: "work", icon: "user-clock", label: tr("works")},
            { id: "other", icon: "money-bill-wave", label: tr('expenses') },
        ];
        const hiddenRowLength = ( project.components.length - rows.length );
        const filterDom = <div>
            <div className={"filter" + ( this.state.componentMoneyFilters.length > 0 ? " active" : "" ) }>
                <div className="apOptionBar">
                    { filterOptions.map( ( filter ) => {

                        let optionClass = [ 'option', 'pointer' ];

                        if( this.state.componentMoneyFilters.indexOf( filter.id ) == -1 )
                            optionClass.push( "disabled" );

                        return <div key={ filter.id } className={ optionClass.join(" ") } onClick={ () => this.toggleComponentMoneyFilter( filter.id ) } style={{ cursor: "pointer" }}>
                            <ApTooltip text={ filter.label } block position="bottom">
                                <SvgIcon icon={ filter.icon } type="solid" />
                            </ApTooltip>
                        </div>
                    })}
                </div>
            </div>
            <div className="filter title">
                <SvgIcon className="small-inline" icon="filter" type="solid" />
                <span>{ tr('filter') }:</span>
                { hiddenRowLength > 0 &&
                    <span className="apBadge">{ hiddenRowLength }</span>
                }
            </div>
        </div>

                // c.nameError = ( !c.nameError );
                // c.countError = ( !c.count )
                // c.priceWarning = ( c.component && parseFloat( c.price_single ) < parseFloat( c.component.price ) );
                // c.profitWarning = ( parseFloat( c.profit_all) <= 0 );

        let moneyCols = [
            {
                label: tr('amount'),
                value: 'count',
                funcName: 'changeCount',
                countError: true,
                unit: ( c ) => c.component ? c.component.unit: null,
            }, 
            /*     
            {
                label: 'Alkuperäinen hinta',
                value: 'price_single',
                show: false,
                width: 0,
                //format: format(value),
                //funcName: 'changeDiscount',
                //countError: true,
                unit: ( c ) => c.component ? c.component.wholeseller_discount: null,
            },
            */
            {
                label: tr('wholesaler_discount'),
                value: 'wholeseller_discount',
                tooltip: tr('wholesaler_discount_percentage'),
                readOnly: true,
                //format: format(value),
                funcName: 'changeDiscount',
                //countError: true,
                unit: ( c ) => c.component ? c.component.wholeseller_discount: null,
            },
    
            {
                label: tr('cost_price_alt'),
                toggle: 'showPriceAs',
                priceWarning: true,
                options: [
                    {   showAs: 'single',
                        label: tr('one'),
                        tooltip: tr('show_unit_cost_price'),
                        value: 'price_single',
                        funcName: 'changePriceSingle',
                        unit: this.state.currencySign,
                    },{
                        showAs: 'all',
                        label: tr('all'),
                        tooltip: tr('show_cost_price_for_full_quantity'),
                        value: 'price_all',
                        funcName: 'changePriceAll',
                        unit: this.state.currencySign
                    },
                ],
            },
       
            {
                label: tr("gross_profit"),
                toggle: 'showProfitAs',
                profitWarning: true,
                options: [
                    {   showAs: 'percent',
                        label: '%',
                        tooltip: tr('show_gross_profit_as_percentage'),
                        value: 'profit_percent_rounded',
                        funcName: 'changeProfitPercent',
                        unit: '%',
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_single_gross_profit'),
                        value: 'profit_single',
                        funcName: 'changeProfitSingle',
                        unit: this.state.currencySign,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_gross_profit_for_full_quantity'),
                        value: 'profit_all',
                        funcName: 'changeProfitAll',
                        unit: this.state.currencySign,
                    }
                ],
            },{
                label: tr('offer_price'),
                toggle: 'showPriceSellAs',
                options: [
                    {   showAs: 'single',
                        label: tr('one'),
                        tooltip: tr('show_offer_bid_price'),
                        value: 'price_sell_single',
                        funcName: 'changePriceSellSingle',
                        unit: this.state.currencySign,
                    },{
                        showAs: 'all',
                        label: tr('all'),
                        tooltip: tr('show_offer_price_for_full_quantity'),
                        value: 'price_sell_all',
                        funcName: 'changePriceSellAll',
                        unit: this.state.currencySign,
                    },
                ],
            },
        ];

        let table = null;
        if( project.components.length === 0 )
            table = <div className="apMsg">{ tr('no_storage_components_added') }</div>
        else if( rows.length === 0)
            table = <div className="apMsg">{ tr('storage_components_filtered') }</div>
        else
        {
            // Render header colums based on moneyCols array
            let headerCols = moneyCols.map( ( col, i ) => {

                let optionBar = null;

                if( col.options )
                {
                    let doms = col.options.map( ( o, i ) => {
                        const className = ( this.state[ col.toggle ] === o.showAs ) ? 'option selected' : 'option';
                        return <div key={ i }
                            className={ className }
                            onClick={ () => this.setState({ [ col.toggle ]: o.showAs }) }
                        >
                            <ApTooltip text={ o.tooltip ? o.tooltip : null } block position="topright">
                                { o.label }
                            </ApTooltip>
                        </div>
                    })
                    optionBar = <div className={`apOptionBar count-${ col.options.length }`}>
                        { doms }
                    </div>
                }
                return <th key={ i } className="fixed">
                    { col.label }
                    { optionBar }
                </th>
            });

//muutos alkaa
            let componentGroups = [];
            let others = [];


            let cGroups = groupify(rows, "group_id");
            //console.log("cGroups", cGroups);
            //console.log("rows", rows);

            let gIds = [];
            let groups = [];
            //console.log("groups",this.props.groups)

            for (let key in cGroups) {
                if (key !== "undefined") {


                    let groupData = {
                        commentCount: 0,
                        componentCountSum: 0,
                        wholesellerDiscountSum: 0,
                        wholesellerDiscountAverage: 0,
                        price: 0,
                        profit: 0,
                        profitAllSum: 0,
                        priceSellAllSum:0,
                        priceAllSum:0,
                        priceSell: 0,
                        
                    }
                    //cGroups[key].filter(x => x.comment).length;


                    let estimateSum = 0;
                    
                    for (let c of cGroups[key]) {
                        groupData.componentCountSum += parseFloat(c.count ? c.count : 0);
                        groupData.wholesellerDiscountSum += parseFloat(c.wholeseller_discount ? c.wholeseller_discount : 0);
                        groupData.priceSellAllSum += parseFloat(c.price_sell_all ? c.price_sell_all : 0); 
                        groupData.priceAllSum += parseFloat(c.price_all ? c.price_all : 0);
                        
                        if (this.state.showPriceAs === "single") {
                            groupData.price += parseFloat(c.price_single ? c.price_single : 0);
                        }
                        if (this.state.showPriceAs === "all") {
                            groupData.price += parseFloat(c.price_all ? c.price_all : 0);
                        }

                       
                        if (this.state.showProfitAs === "single") {
                            groupData.profit += parseFloat(c.profit_single ? c.profit_single : 0);
                        }
                        if (this.state.showProfitAs === "all") {
                            groupData.profit += parseFloat(c.profit_all ? c.profit_all : 0);
                            
                        }

                        if (this.state.showPriceSellAs === "single") {
                            groupData.priceSell += parseFloat(c.price_sell_single ? c.price_sell_single : 0);
                        }
                        if (this.state.showPriceSellAs === "all") {
                            groupData.priceSell += parseFloat(c.price_sell_all ? c.price_sell_all : 0);
                        }
                            
                        

                        //installedCountSum += parseFloat(c.installed_count ? c.installed_count : 0);

                        if (c.comment) {
                            groupData.commentCount++;
                        }

                    }
                    //laskutoimitukset /muunnokset
                    groupData.wholesellerDiscountAverage = groupData.wholesellerDiscountSum / cGroups[key].length;
                    if (this.state.showProfitAs === "percent") {
                        
                        groupData.profit = 100-groupData.priceAllSum / groupData.priceSellAllSum*100;
                        
                    }
                    
                    
                    




                    if (this.state.showProfitAs === "percent") {
                        
                        groupData.profit = formatMoney(groupData.profit,1) + "%"
                    }
                    else {
                        groupData.profit = formatMoney(groupData.profit, 2) + "€"
                    }
                    //units
                    groupData.wholesellerDiscountAverage = formatMoney(groupData.wholesellerDiscountAverage, 2) + "%";
                    groupData.price = formatMoney(groupData.price, 2) + "€"
                    groupData.priceSell = formatMoney(groupData.priceSell, 2) + "€"
                    
                    
                    


                    groups.push(cGroups[key][0].group);
                    let group = this.state.groups.find(x => x.id == key);
                    
                    componentGroups.push(this.renderGroup(group, cGroups[key] || [], moneyCols, groupData, rows));
                }
            }

            // componentGroups = groups.map(g => {
            //     gIds.push(g.id);
            //     let group=this.props.groups.find(gg=>gg.id==g.id)
            //     return this.renderGroup(group, cGroups[g.id] || []);
            // });
            others = cGroups[undefined] ||[];
            

            // Render component rows colums based on moneyCols array

            const componentRowDoms = others.map( c => {
                const moneyDoms = this.getMoneyCols(c, moneyCols, project);

                return this.renderComponentRow( project, c, moneyDoms );
            });

            table = <table className="componentTable">
                <thead>
                    <tr className="componentHeader">
                        <th></th>
                        <th></th>
                        <th>{tr('name')}</th>
                        <th>{tr('comment')}</th>
                        <th></th>
                        { headerCols }
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {componentGroups}
                    { componentRowDoms }
                </tbody>
            </table>
        }

        return <div>
            { this.renderBudgetFilterBar( filterDom ) }
            <div className="padding">
                { table }
                { this.renderAddComponentInput() }
                { this.renderBudgetMoveHereButton() }
            </div>
        </div>
    }

    getMoneyCols(c, moneyCols, project) {
        return moneyCols.map((col, i) => {

            const o = (col.toggle) ? col.options.find(f => f.showAs === this.state[col.toggle]) : col;
            if (!o) return null;
            const unit = (typeof o.unit === 'function') ? o.unit(c) : o.unit
            const value = c[o.value];
            let tooltip = col.label;

            const iClass = ['componentRowInput'];

            if (col.priceWarning && c.priceWarning) {
                tooltip = tr('cost_price_below_storage_value');
                iClass.push('warning');
            }
            if (col.profitWarning && c.profitWarning) {
                tooltip = tr('no_gross_profit');
                iClass.push('warning');
            }

            if (col.countError && c.countError) {
                tooltip = tr('must_be_defined');
                iClass.push('error');
            }

            return <td key={i} className="fixed">
                <ApTooltip text={tooltip} block position="topright">
                    <input
                        className={iClass.join(' ')}
                        type="text"
                        value={value ? value : ''}
                        onChange={(e) => { this[o.funcName](project, c, e.target.value) }}
                        //onBlur={   ( e ) => { this[ o.funcName ]( project, c, e.target.value, true ) } }
                        disabled={this.state.locked}
                    />
                    <div className="unit">{unit}</div>
                </ApTooltip>
            </td>
        });
    }

    renderComponentDropdown( project, c )
    {
        let canMerge = false;
        let removeSelected = false;
        
        const selectedComponents = this.state.selectedComponents;
        if( selectedComponents.length >= 2 )
        {
            // Show remove selected menu only on selected components
            let found = selectedComponents.find( sc => sc.id === c.id );
            if( found ) removeSelected = true;

            if( c.component && selectedComponents[ 0 ].component )
            {
                let projectId = selectedComponents[ 0 ].project_id;
                let componentId = selectedComponents[ 0 ].component.id;
                if( c.component.id === componentId )
                {
                    canMerge = selectedComponents.every( sc => {
                        if( !sc.component ) return false;
                        return ( sc.project_id === projectId && componentId === sc.component.id );
                    });
                }
            }
        }

        let menuActions = [];

        menuActions.push({
            label: tr('update_price'),
            icon: "money-bill-wave",
            disabled: ( !c.count || this.state.locked || !c.component ),
            action: ( item, closeFunc ) => {
                this.changePriceSingle( project, c, c.component.price, true );
                this.changeProfitPercent( project, c, c.component.profit_percent, true );
                closeFunc();
            },
        });

        menuActions.push({
            label: tr('divide_storage_component'),
            icon: "divide",
            disabled: ( !c.count || this.state.locked ),
            action: ( item, closeFunc ) => {
                this.divideOpen( project, c );
                closeFunc();
            },
        });

        if( canMerge )
        {
            menuActions.push({
                label: tr('combine_selected'),
                icon: "link",
                disabled: ( !c.count || this.state.locked ),
                action: ( item, closeFunc ) => {
                    this.mergeSelectedComponents();
                    closeFunc();
                },
            });
        }
        if (this.state.selectedComponents.filter(s => s.id === c.id).length > 0) {
            menuActions.push({
                label: tr('add_as_group'),
                icon: "object-group",
                action: (item, closeFunc) => {
                    this.createAndAddToGroup();
                    closeFunc();
                },
            });
        }
        

        let work;
        if (project && project.works) {
            work = project.works.find(w => (w.component && c.component) && w.component.id === c.component.id);
        }
        if (work && work.entry_count) {
            c.entry_count = work.entry_count;
        }
        const notRemovable = this.notRemovable( c.type_name, c );
        
        menuActions.push({
            label: tr('delete_storage_component'),
            icon: "trash-alt",
            disabled: this.state.locked || notRemovable,
            action: ( item, closeFunc ) => {
                this.removeComponentClick( c );
                closeFunc();
            }
        });

        if( removeSelected )
        {
            menuActions.push({
                label: tr('delete_selected'),
                icon: "trash",
                disabled: this.state.locked,
                action: ( item, closeFunc ) => {
                    this.removeComponentClick( 'selected' );
                    closeFunc();
                }
            });

        }

        return <ApDropdown actions={ menuActions } />
    }
    renderTdComment(c) {
        const funcs = {
            item: this.changeComponentName,
            work: this.changeWorkName,
            other: this.changeExpenseName,
        }
        return <td className="comment">
            <ApTooltip position={'top'} text={c.comment} >
                <ApButton size="xtiny" color={c.comment ? "orange" : "white"} onClick={(e) => { this.openEditComment({ projectId: this.state.selectedProject.id, component: c, target: e.target }) }}>
                    <SvgIcon icon="plus" type="solid" />
                </ApButton>
            </ApTooltip>
        </td>

    }

    renderComponentRow( project, c, colDoms ,className)
    {
        const checked = this.state.selectedComponents.some( sc => sc.id === c.id );
        let rowClass = ['component'];
        if (className) rowClass.push( className );
        if( checked ) rowClass.push( 'selected' );

        let niClass = [ 'componentNameInput' ];
        if( c.nameError )
        {
            niClass.push( 'error' );
        }
        let saveAsNew=<td className='fixed'></td>;
        if (c.save_as_new!==undefined) {
        	saveAsNew = <td className="saveNew">
	    		<ApTooltip text={ tr('save_as_new_component') } block position="top">
	    		<SvgIcon icon="save" type="solid" />
	                <ApSwitch
		                id={ `save_as_new-${ c.id }-switch` }
		                small
		                on={ c.save_as_new }
		                onChange={ ( ) => { this.changeSaveAsNew( project , c ) } }
		                disabled={ this.props.loading || this.props.locked }
		            />
	                
	              </ApTooltip>
		        </td>
        }

        return <tr key={ c.id } className={ rowClass.join(' ') }>
            <td key="check" className="select" onClick={ () => this.selectComponent(c) }>
                <input
                    type="checkbox"
                    checked={ checked }
                    readOnly
                />
            </td>
            <td key="conncted" className="icon" onClick={ () => this.selectComponent(c) }>
                <ApTooltip position="top" text={ this.renderConnectedComponentTooltip( c.component ) }>
                    <SvgIcon className={ c.component ? '' : 'outsideStorage' } icon={ getTypeIcon( c.type_name ) } type="solid" />
                </ApTooltip>
            </td>
            <td key="name" className="name">
                <input
                    className={ niClass.join( ' ' ) }
                    type="text"
                    value={ c.name ? c.name : '' }
                    onChange={ ( e ) => { this.changeName( project, c, e.target.value ) } }
                />
            </td>
            {this.renderTdComment(c)}
            { saveAsNew }
            { colDoms }
            <td className="fixed menu">
                { this.renderComponentDropdown( project, c ) }
            </td>
        </tr>
    }

    renderConnectedComponentTooltip( component )
    {
        if( !component )
            return <div>{ tr('external_storage_component') }</div>
                return <ComponentTooltip component={ component } />
    }

    renderBudgetComponentCalculatablesTable()
    {
        const project = this.state.selectedProject;
        const rows = project.components.filter(c => c.type_id == 1); // Items
        
        const calculatables = this.state.calculatables;
        const calcVisibleIds = this.state.componentCalculatableVisible;
        const calcVisible = this.state.calculatables.filter( f => calcVisibleIds.includes( f.id ) )

        const filterOptions = calculatables.map( calc => {

            let className = [ 'calcSelect' ];
            let icon = null;
            let tooltip = null;

            if( calcVisibleIds.includes( calc.id ) ) className.push( 'selected' );
            if( calc.work_id )
            {
                icon = 'user-clock';
                tooltip = <div>{ tr('attached_to_work') }: <strong>{ calc.work_component.name }</strong></div>;
            }

            return {
                label: <ApTooltip text={ tooltip } block position="left">
                    <div className={ className.join( ' ' ) }> { calc.name } <div className="unit"> { calc.unit } </div></div>
                </ApTooltip>,

                icon: icon,
                action: ( item, closeFunc ) => {
                    this.toggleComponentCalculatableVisible( calc.id );
                    closeFunc();
                },
            }
        })
        const filterDom = <div className="calcFilter">

            <span className="apBadge"> { calcVisible.length } / { calculatables.length } </span>
            <ApDropdown actions={ filterOptions }
                button={<div className="apOptionBar smallerIcons count-1">
                    <div className="option">
                        <SvgIcon icon="columns" type="solid" /> { tr('meters_to_be_displayed') }
                    </div>
                </div> }
            />
        </div>


        
        let componentGroups = [];
        let others = [];


        let cGroups = groupify(rows, "group_id");
        //console.log("cGroups", cGroups);
        //console.log("rows", rows);

        let gIds = [];
        let groups = [];
        //console.log("groups",this.props.groups)

        for (let key in cGroups) {
            if (key !== "undefined") {


                let groupData = {
                    commentCount: 0,
                    componentCountSum: 0,
                    wholesellerDiscountSum: 0,
                    wholesellerDiscountAverage: 0,
                    price: 0,
                    profit: 0,
                    profitAllSum: 0,
                    priceSellAllSum: 0,
                    priceAllSum: 0,
                    priceSell: 0,
                    measureMode: true,
                    calculatables:{},
                }
                //cGroups[key].filter(x => x.comment).length;


                let estimateSum = 0;

                for (let c of cGroups[key]) {
                    groupData.componentCountSum += parseFloat(c.count ? c.count : 0);
                    groupData.wholesellerDiscountSum += parseFloat(c.wholeseller_discount ? c.wholeseller_discount : 0);
                    groupData.priceSellAllSum += parseFloat(c.price_sell_all ? c.price_sell_all : 0);
                    groupData.priceAllSum += parseFloat(c.price_all ? c.price_all : 0);
                    for (let calc of calcVisible) {
                        
                        if (c.component) {
                            let found = c.component.calculatables.find(f => f.id === calc.id);
                            let count = parseFloat(c.count ? c.count : 0);
                            let value = found ? parseFloat(round(found.pivot.value*count)) : 0;
                            if (!groupData.calculatables[calc.id]) {
                                groupData.calculatables[calc.id] = value;
                            }
                            else {
                                groupData.calculatables[calc.id] += value;
                            }
                        }
                    }
                    
                    if (c.comment) {
                        groupData.commentCount++;
                    }

                }
            

                //groups.push(cGroups[key][0].group);
                let group = this.state.groups.find(x => x.id == key);
                let componentGroupedRows = cGroups[key].map(c => {
                    let checked = this.state.selectedComponents.some(sc => sc.id === c.id);

                    let calcDoms = calcVisible.map(calc => {

                        let value = null;
                        let count = parseFloat(c.count ? c.count : 0);

                        if (c.component) {
                            let found = c.component.calculatables.find(f => f.id === calc.id);
                            value = found ? round(found.pivot.value * count) : null;
                        }
                        return <td key={calc.id} className="fixed calc">
                            <input
                                className={"componentRowInput"}
                                type="text"
                                value={value ? value : ''}
                                disabled
                            />
                            <div className="unit">{calc.unit}</div>
                        </td>
                    });

                    let rowClass = ['component'];
                    if (checked) rowClass.push('selected');

                    return this.renderComponentRow(project, c, calcDoms, "groupRowComponent");
                });
                
                componentGroups.push(this.renderGroup(group, cGroups[key] || [], null, groupData, rows, componentGroupedRows, calcVisible));
            }
        }

        // componentGroups = groups.map(g => {
        //     gIds.push(g.id);
        //     let group=this.props.groups.find(gg=>gg.id==g.id)
        //     return this.renderGroup(group, cGroups[g.id] || []);
        // });
        others = cGroups[undefined] || [];
       
        

        const componentRows = others.map( c => {
            const checked = this.state.selectedComponents.some( sc => sc.id === c.id );

            const calcDoms = calcVisible.map( calc => {

                let value = null;
                let count = parseFloat(c.count? c.count:0);
                
                if (c.component) {
                    let found = c.component.calculatables.find(f => f.id === calc.id);
                    value = found ? round(found.pivot.value*count) : null;
                }
                
                
                return <td key={ calc.id } className="fixed calc">
                    <input
                        className={ "componentRowInput" }
                        type="text"
                        value={ value ? value : '' }
                        disabled
                    />
                    <div className="unit">{ calc.unit }</div>
                </td>
            });

            let rowClass = [ 'component' ];
            if( checked ) rowClass.push( 'selected' );

            return this.renderComponentRow( project, c, calcDoms );
        });

        const cHeaderDoms = calcVisible.map( calc => {

            let icon = null;
            let tooltip = null;
            if( calc.work_id )
            {
                icon = <SvgIcon icon="user-clock" type="solid" />
                tooltip = <div>{ tr('attached_to_work') }: <strong>{ calc.work_component.name }</strong></div>;
            }

            return <th key={ calc.id } className="fixed calcHeader">
                <ApTooltip text={ tooltip } block position="topright">
                    <div>
                        { icon }
                        { calc.name }
                    </div>
                </ApTooltip>
            </th>
        });

        let table = null;
        if( project.components.length === 0 )
            table = <div className="apMsg">{ tr('no_storage_components_added') }</div>
        else if( rows.length === 0)
            table = <div className="apMsg">{ tr('no_components') }</div>
        else
            table = <table className="componentTable">
                <thead>
                    <tr className="componentHeader">
                        <th></th>
                        <th></th>
                        <th>{tr('name')}</th>
                        <th>{tr('comment')}</th>
                        <th></th>
                        { cHeaderDoms }
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {componentGroups}
                    { componentRows }
                </tbody>
            </table>

        return <div>
            { this.renderBudgetFilterBar( filterDom ) }
            <div className="padding">
                { table }
                { this.renderAddComponentInput() }
                { this.renderBudgetMoveHereButton() }
            </div>
        </div>

    }

    

    setProjectContinuous( id )
    {
        let tree = this.state.tree.slice();
        tree = editItemById( tree, id, { 'is_continuous': '!' }, "children" );
        this.calculateTree( tree );

    }
    renderLabel() {
        return <span>{tr('cost_center')}</span>
    };

    renderLabelValue(code) {
        return code.full_name;
    };
    changeCostCenter(cost_center) {
        let project=this.state.project;
        project.cost_center = cost_center;
        if (project.cost_center_is_required && project.cost_center == null) {
            this.setState({ error: tr('cost_center_is_required') })
        }

        this.setState({ project: project , treeUnsaved: true, error: null });
    }

    renderBudgetProjectSettings()
    {
        let project = this.state.project;
        
        
        return <div className="padding">

            <h5>{ tr('project_additional_info') }</h5>

            <ApInput
                type="textarea"
                name="description"
                id="description"
                label={ tr('project_description') }
                value={ this.state.selectedProject.description }
                onChange={ this.projectSettingsChange }
                //loading={ this.state.loading || this.props.loading }
                //disabled={ this.props.disabled }
            />

            {!this.state.project.parent_id 
            ? <div>
                    <ApSelect
                        label={ tr('project_visibility') }
                        value={ this.state.project.project_visible_to_users || [] }
                        optionRenderer="user"
                        onChange={ this.changeSettingSetVisibility }
                        objKeyId="id"
                        objKeyValue="name"
                        clearable={ true }
                        apiUrl="search/user"
                        apiData={{
                            modules: ['projects'],
                            is_active: true,
                            exludeIds: project.manager ? [project.manager.id] : null,
                            active_contracts_only: true
                        }}
                        loading={ this.state.loading }
                        disabled={ this.state.locked || this.state.loading || false }
                        filterNonActives={true}
                        multiselect
                        valueRenderer="user"
                        objKeySearchable="user"
                    />
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        {tr('select_project_visibility')}
                    </div>
                </div>
            : null}

            {this.state.project.cost_center_enabled &&
                <div>
                    <ApNestedSelect
                        label={this.renderLabel}
                        valueRenderer={this.renderLabelValue}
                        value={project.cost_center}
                        parentRenderer="value"
                        parentTooltipRenderer="name"
                        optionRenderer={(item) => {
                            return <div className="codeOption">
                                <span className="name">{item.nameTree}</span>
                                <span className="code">{item.code}</span>
                            </div>
                        }}
                        onChange={(value) => { this.changeCostCenter(value) }}
                        apiUrl="costCenter/search"
                        loading={this.props.loading}
                        disabled={this.props.loading || this.props.skipped}
                    validationState={(project.cost_center_is_required && project.cost_center == null) ? "error" : ""}
                    //tailInput={tailInput}
                    />
                    {project.parent_id &&
                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            {tr('cost_center_child_project_info')}
                        </div>}
                </div>}


            <div className="apSwitchBlock small">
                <label htmlFor="is-continuous-switch" className="info">
                    { tr('continuous_project') }
                    <small>{ tr('continuous_project_info') }</small>
                </label>
                <ApSwitch
                    id="is-continuous-switch"
                    on={ this.state.selectedProject.is_continuous }

                    onChange={ () => { this.setProjectContinuous( this.state.selectedProject.id ) }}
                    disabled={ this.state.loading }
                    // disabled={ true }
                />
            </div>

            <ApInputStack gap="0">
                <ApAddon noRightBorder width="170px">
                    <SvgIcon className="small-inline" icon="calendar" type="solid" />
                    { tr('schedule') }
                </ApAddon>
                <ApInput
                    type="datetimeV2"
                    name="start"
                    id="start"
                    value={ moment( this.state.selectedProject.start ) }
                    label={ tr('project_start_date') }
                    onChange={(date) => this.projectSettingsChange(date, "start")}
                    weekNumbers={true}
                    // loading={ this.state.loading || this.props.loading }
                    // disabled={ this.props.disabled }
                    // disabled={ true }
                    disabled={this.state.loading}

                />

            { !this.state.selectedProject.is_continuous && <ApInput
                    gapLeft="10"
                    type="datetimeV2"
                    name="end"
                    id="end"
                    value={moment( this.state.selectedProject.end ) }
                    label={ tr('project_end_date') }
                    onChange={(date) => this.projectSettingsChange(date, "end")}
                    weekNumbers={true}
                    //loading={ this.state.loading || this.props.loading }
                    //disabled={ this.props.disabled }
                    // disabled={ true }
                    disabled={this.state.loading}

                /> }
            </ApInputStack>
        </div>
    }



                renderProjectEditModal()
                {
                    const currentProject = this.state.selectedProject;
                    let data = this.state.subProjectEdit.data;

                    let title = tr('new_subproject');
                    let name = '';
                    let code = '';
                    let id = null;
                    let label=[tr('name'), tr('project_code')];
                    
                    if (this.state.subProjectEdit.copy) {
                        label=[tr('new_name'), tr('new_project_code')];
                    }
                    
                    if( data )
                    {
                    	if (this.state.subProjectEdit.copy) {
                    		this.state.subProjectEdit.parent=findItemById(this.state.tree.slice(0), data.parent_id);
                    		id = data.id;
                    	
                   		 	title = tr('copy_project');
                   	        name = `${tr('copy_of')} ${data.name}`;
                            if( this.state.subProjectEdit.parent )
                            {
                                const siblings = this.state.subProjectEdit.parent.children;
                                let nextCode = 0;
                                siblings.forEach(( s ) => {
                                    let code = parseInt( getLastCodePart( s.project_code ), 10);
                                    if( code > nextCode ) nextCode = code;
                                });
                                nextCode = String( nextCode + 1 );

                                let defaultLength = this.state.subproject_code_default_length || 0;
                                let prefixLength = ( defaultLength - nextCode.length );
                                code = ( prefixLength > 0 ) ? "0".repeat( prefixLength ) + nextCode : nextCode;
                            }

                   	}
                    	else {
              
            	            title = tr('edit_subproject');
            	            id = data.id;
            	            name = data.name;
            	            code = getLastCodePart( data.project_code );
            	        	}
                    }
                    else
                    {
                        // Get next code
                        if( this.state.subProjectEdit.parent )
                        {
                            const siblings = this.state.subProjectEdit.parent.children;

                            let nextCode = 0;
                            siblings.forEach(( s ) => {
                                let code = parseInt( getLastCodePart( s.project_code ), 10);
                                if( code > nextCode ) nextCode = code;
                            });
                            nextCode = String( nextCode + 1 );

                            let defaultLength = this.state.subproject_code_default_length || 0;
                            let prefixLength = ( defaultLength - nextCode.length );
                            code = ( prefixLength > 0 ) ? "0".repeat( prefixLength ) + nextCode : nextCode;
                        }
                    }


                    return <ApModalInput
                        show={ Boolean( this.state.subProjectEdit.show ) }
                        title={ title }
                        value={[
                            name,
                            code,
                        ]}
                        label={label}
                        required={[
                            true,
                            true,
                        ]}
                        disabled={[
                            false,
                            currentProject?.parent_id ? false : true,
                        ]}
                        onSave={ ( values ) => this.subProjectSubmitted({
                            id:  id,
                            name: values[0],
                            code: values[1],
                            copy: this.state.subProjectEdit.copy,
                            parent: this.state.subProjectEdit.parent,
                        } ) }
                        onClose={ () => this.setState({ subProjectEdit: {} }) }
                    />

            /*
                    return <ApModalInput
                        show={ this.state.subProjectEditShow }
                        title={ this.state.subProjectEditData && this.state.subProjectEditData.id ? "Muokaa aliprojektia" : "Uusi aliprojekti" }
                        value={[
                            ( this.state.subProjectEditData ? this.state.subProjectEditData.name : "" ),
                            ( this.state.subProjectEditData ? getLastCodePart( this.state.subProjectEditData.project_code ) : "" )
                        ]}
                        label={[
                            'Nimi',
                            'Projektikoodi'
                        ]}
                        required={[
                            true,
                            false
                        ]}
                        onSave={ ( values ) => this.subProjectSubmitted({ id: ( this.state.subProjectEditData ? this.state.subProjectEditData.id : null ), name: values[0], code: values[1] } ) }
                        onClose={ () => this.setState({ subProjectEditShow: false }) }
                    />
                    */


                }
                
    renderBudget()
    {
        return <div id="offerBudget" style={{minWidth: 1050}}>
            <div className="offerContent">
                <div className="splitView">
                    <div className="left">
                        { this.renderBudgetProjectTree() }
                    </div>
                    <div className="right">
                        { this.state.selectedProject &&
                            <div className="details">
                                <div className="padding">
                                    { this.renderBudgetSelectedProjectPath() }
                                </div>
                                <ApTabs fullWidth>
                                    <ApTab icon="cubes" label={ tr('storage_components') }>
                                        { this.renderBudgetComponents() }
                                    </ApTab>

                                    <ApTab icon="chart-bar" label={ tr('statistics') }>
                                        { this.renderBudgetStatistic() }
                                    </ApTab>
                                    <ApTab icon="cog" label={ tr('settings') }>
                                        { this.renderBudgetProjectSettings() }
                                    </ApTab>
                                </ApTabs>
                            </div>
                        }
                    </div>
                </div>
            </div>

            { this.renderProjectEditModal() }
            { this.renderProjectCopyToAnotherModal() }

            <MassList
                show={ this.state.massListShow }
                onClose={ this.massListClose }
                onSave={ this.massListImported }

                projectList={ this.state.massListProjectList }
                calculatables={ this.state.calculatables }
                componentTypes={ componentTypes }
            />

            <ApModalInput
                show={ this.state.divideComponentShow }
                title={ tr('components_moved_separately') }
                value={ `${ this.state.divideComponent.count / 2 }` }
                onSave={ this.divideSubmit }
                onClose={ () => { this.setState({ divideComponentShow: false }) } }
            />

            <ApConfirm
                show={ this.state.removeProjectConfirmShow }
                onClose={ () => { this.setState({ removeProjectConfirmShow: false } ) } }
                onConfirm={ this.removeSubProject }
                header={ tr('delete_project_confirm') } 
                body={<div>{ tr('delete_subproject_confirm', [<strong>{this.state.removeProjectConfirmData.name}</strong>]) }</div>}
            />

            <ApConfirm
                show={ this.state.removeComponentConfirmShow }
                onClose={ () => { this.setState({ removeComponentConfirmShow: false } ) } }
                onConfirm={ () => { this.removeComponent( this.state.removeComponentConfirmIds ) } }
                header={ tr('delete_storage_components_confirm') }
                body={ this.state.removeComponentConfirmBody }
            />

            <ApConfirm
                show={ this.state.workSyncedConfirmShow }
                onClose={ () => { this.setState({ workSyncedConfirmShow: false } ) } }
                onConfirm={ () => { this.toggleWorkSyncConfirmed() } }
                header={ tr('sync_working_hours_confirm') }
                body={<div>{ tr('sync_working_hours_info') }</div>}
            />

            
            

        </div>
    }
    
    renderSelectedComponents()
    {
        const selectedComponents = this.state.selectedComponents;
        if( selectedComponents.length === 0 )
            return null;
        let rows = selectedComponents.map((c, index) => {
            return <tr key={ c.id } className="component">
                <td>
                    <input
                        type="checkbox"
                        onClick={() => { this.selectComponent( c ) } }
                        checked={ true }
                        readOnly
                    />
                </td>
                <td>
                    { c.name }
                </td>
            </tr>
        })

        return (
            <div className="selectedRows">
                <div className="button" onClick={ () => this.setState({ selectedComponentsVisible: !this.state.selectedComponentsVisible }) }>
                    { tr('selected_components_count', [rows.length]) }
                </div>
                <div className={ "list" + ( this.state.selectedComponentsVisible ? " show" : "" ) }>
                    <div className="scroller">
                        <table className="componentTable selectedComponentTable">
                            <tbody>
                                { rows }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
    
    
    renderProjectCopyToAnotherModal()
    {
    	let projects = [];
	 	let title = tr('copy_subproject');
 	
    	return <ApModal
        show={ Boolean( this.state.subProjectCopy.show ) }
        handleClose={ () => this.setState({ subProjectCopy: {} }) }
        closeFromBg
        className="padding"
        header={<div className="padding">{ tr('copy_subproject_from_another_project') }</div>}
        body={ <div className="padding">
        <ApSelect
            label={ tr('project_to_be_copied') }
            loading={ this.state.loading }
            value={ keyExists( this.state.subProjectCopy, "origname", true, '' ) }
        	optionRenderer="project"
            onChange={ ( values ) => this.addSubProjectFromExisting(values) }
            objKeyId="id"
            apiUrl="report/projects/find"
            apiData={{
                 formatter: 'select',
                 //withoutOffers : 'true'
                // type: 'work',
                // status: 'all',
            }}
        />
        <ApInput
        type="text"
        name="newname"
        label={ tr('new_name') }
        value={  keyExists( this.state.subProjectCopy, "name", true, '' )  }
        onChange={ ( e ) => { this.handleChange( "name", e.target.value ) }}
        tooltip={ " " }
        validationState={ " " }
        loading={ this.props.loading }
        disabled={ this.props.loading }
        
    /><ApInput
        type="text"
        name="newcode"
        label={ tr('new_project_code') }
        value={ keyExists( this.state.subProjectCopy, "code", true, '' ) }
        onChange={ ( e ) => { this.handleChange( "code", e.target.value )  } }
        tooltip={ " " }
        validationState={ " " }
        loading={ this.props.loading }
        disabled={ this.props.loading }
    />
        <ApButton onClick={() => this.setState({ subProjectCopy: {} }) }>
        <SvgIcon icon="times" type="solid" />
        { tr('cancel') }
        </ApButton>
	    <ApButton color="blue" onClick={ this.addSubProjectFromExistingSelected } disabled={ this.state.loading }>
	        <SvgIcon icon="save" type="solid" />
	        { tr('import_project_copy') }
	    </ApButton>
	        
        </div>}
    />
    }

    // ----------------------------------------
    //  Schedule stuff
    // ----------------------------------------

    onScheduleMove(id, newStart )
    {
        // Calculate move offset in days
        const project = findItemById( this.state.tree, id, "children" );
        const offset = moment( newStart ).diff( moment( project.start ), 'days' );

        // Move current project
        let tree = this.state.tree.slice();

        let edits = {
            'start': moment( project.start ).add( offset, 'days' ).format('YYYY-MM-DD'),
            'end': moment( project.end ).add( offset, 'days' ).format('YYYY-MM-DD')
        };

        tree = editItemById( tree, id, edits, "children" );

        // Move all child projects
        const moveChilds = ( data, children ) => {
            if( children && children.length > 0 )
            {
                for( let i = 0; i < children.length; i++ )
                {
                    data = editItemById( data, children[i].id, {
                        start: moment( children[i].start ).add( offset, 'days').format('YYYY-MM-DD'),
                        end: moment( children[i].end ).add( offset, 'days').format('YYYY-MM-DD'),
                    }, "children" );
                    data = moveChilds( data, children[i].children );
                }
            }
            return data;
        };
        tree = moveChilds( tree, project.children );
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    onScheduleResize( id, time, edge )
    {
        let tree = this.state.tree.slice();
        let edits = {};

        if( edge === "left" )
            edits = { start: moment( time ).format('YYYY-MM-DD') };

        else if ( edge === "right" )
            edits = { end: moment( time ).format('YYYY-MM-DD') };

        tree = editItemById( tree, id, edits, "children" );
        this.calculateTree( tree );
        this.setState({ treeUnsaved: true });
    }

    openAllProjects()
    {
        let tree = this.state.tree.slice();
        tree = editItemById( tree, "!0", { 'childsOpen': true }, "children" );
        this.calculateTree( tree );
    }

    toggleScheduleChilds( id )
    {
        let tree = this.state.tree.slice();
        tree = editItemById( tree, id, { 'childsOpen': "!" }, "children" );
        this.calculateTree( tree );
    }

    renderSchedule()
    {
        if( !this.state.tree.length ) return <div className="apLoader"></div>

        return <div className="padding">
            <button onClick={ this.openAllProjects }>{ tr('open_all') }</button>

            <ApTimeline
                loading={ this.state.loading }
                items={ this.state.tree }
                onGroupClick={ this.toggleScheduleChilds }
                onMove={ this.onScheduleMove }
                onResize={ this.onScheduleResize }
                padding={ 2 }
                childName="children"
            />
        </div>
    }

    // ----------------------------------------
    //  Send stuff
    // ----------------------------------------

    formatTemplates( templates )
    {
        templates = templates.map(( t ) => {
            return {
                id: t.id,
                value: t.id,
                label: t.name,
                description: t.description,
                extension: t.extension
            };
        });

        templates.unshift({
            value: '',
            label: ` - ${tr('select')} - `,
        });
        return templates;
    }

    changeSentStatus()
    {
        const status = this.state.confirmStatus;
        const doProjecting = ( status === 'accepted' && this.state.doProjecting )
        const allDeclined = ( status === 'declined' && this.state.projectDeclined )
        let row = this.state.sents[ this.state.confirmIndex ];
        const data = {
            status: status,
            projecting: doProjecting,
            all_declined: allDeclined,
        }

        this.setState({
            confirmShow: false,
            loading: true,
        });
        return api({
            method: 'post',
            url: `project/offer/send/${ row.id }/status`,
            data: data,
        }).then((response) => {
            this.props.onSave( this.props.projectId );
            this.getOffer();
            this.setState({ loading: false });
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });

        });
    }

    downloadTemplate()
    {
        const time = moment().format( 'YYYY-MM-DD-HHmmss' );

        const selectedTemplate = this.state.templates.find( t => t.id === this.state.selectedTemplateId );
        const selectedProjectOffer = this.state.sendSelectedProject ? this.state.sendSelectedProject : this.state.tree[0];
        this.setState({ loading: true });
        api({
            method: 'post',
            url: `project/offer/sheet/${ this.state.sheetId }/template/${ this.state.selectedTemplateId }`,
            responseType: 'blob',
            data: {
                valid_till: this.state.validTill,
                project_price: selectedProjectOffer.calculated.all.total.price_sell_all,
                project_code: selectedProjectOffer.project_code,
            },
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${tr('offer')}_${ time }.${ selectedTemplate.extension }` );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
                offerTemplateDownloaded: true,
            });

        }).catch( ( error ) => {
            this.setState({ loading: false });

            let message = tr('get_error');
            if( error.status === 432 )
                message = tr('offer_template_file_invalid');

            errorPopper(error, message );
        });


    }

    saveOffer(sendEmail = false)
    {
        //console.log('project: ', this.state.project);
        let selectedProject = this.state.sendSelectedProject ? this.state.sendSelectedProject : this.state.tree[0];
        let data = new FormData();
        data.append( 'valid_till', dateInputToSql( this.state.validTill ) );
        data.append( 'success_probability', this.state.successProbability );
        data.append( 'file', this.state.selectedFile );
        data.append( 'email', this.state.contacts.email);
        data.append( 'emailtext', this.state.eMailText);
        data.append( 'emailotsikko', this.state.eMailOtsikko);
        data.append('price', selectedProject.calculated.all.total.price_sell_all);
        data.append('project_offer_id', selectedProject.id);

        data.append('sendEmail', sendEmail)

        this.setState({ loading: true });
        api({
            method: 'post',
            url: `project/offer/sheet/${ this.state.sheetId }/send/save`,
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(( response ) => {
            this.props.onSave( this.props.projectId );
            this.getOffer();
            this.setState({ loading: false });
        }).catch( ( error ) => {
            console.error( 'error', error );
            errorPopper( error, tr('save_error') );
            this.setState({ loading: false });
        });
    }

    handleValidTillChange( value )
    {
        this.setState({ validTill: value });
    }

    handleTemplateChange( value )
    {
        value = parseInt( value, 10 );
        if( isNaN( value )) value = null;
        this.setState({ selectedTemplateId: value });
    }

    handleProbabilityChange( value )
    {
        if( value !== 'null') parseInt( value, 10 );
        this.setState({ successProbability: value });
    }

    handleEmailChange( value )
    {
        this.setState({ eMail: value });
    }
 
    getProbabilityOptions()
    {
        return [
            { value: 'null', label:  ` - ${tr('select')} - ` },
            { value: 0,    label:   '0%' },
            { value: 10,   label:  '10%' },
            { value: 20,   label:  '20%' },
            { value: 30,   label:  '30%' },
            { value: 40,   label:  '40%' },
            { value: 50,   label:  '50%' },
            { value: 60,   label:  '60%' },
            { value: 70,   label:  '70%' },
            { value: 80,   label:  '80%' },
            { value: 90,   label:  '90%' },
            { value: 100,  label: '100%' },
        ];
    }

    getEmailOptions()
    {

        /*let emails;
        const contacts =  this.state.contacts;
        if(this.state.project.customer)
        {
            emails = contacts.map(( c ) => {
                //if (c.crm_company_id === this.state.project.customer.id)
                //{
                    return {
                        value: c.email,
                        label: c.first_name + ' ' + c.last_name
                    }
    
               // };
            })
            let e2 = emails.filter(function (item) { return item !== undefined });
            const vl = 
                {
                    value: ' ',
                    label: ' - Valitse - '
                }
            e2.unshift(vl);
            //console.log('emails: ', e2);
            return e2;
            }
            const vl = 
            {
                value: ' ',
                label: ' - Valitse - '
            }
        return vl;
*/
        return [
            { value: null, label: ` - ${tr('select')} - `},
            { value: 'joku@firma.fi', label: ' joku@firma.fi'},
            { value: 'jokutoinen@firma.fi', label: ' jokutoinen@firma.fi'},
        ]

    }

    sendNewOfferStatus()
    {
        if( this.props.locked )
            return tr('offer_locked');
        if( !this.state.validTill )
            return tr('last_day_of_validity_missing');
        else if( !this.state.selectedFile )
            return tr('file_missing');
        else if( this.state.successProbability === 'null' )
            return tr('probability_missing');
        else if( !this.state.contacts || (this.state.contacts && this.state.contacts.email && !validateEmail(this.state.contacts.email)) )
            return tr('email_missing');
        return null;
    }

    renderNewSteps()
    {
        /**
         * Function deprecated: 26.1.2021
         */

         
        //console.log('emailit: ', this.state.project.customer_contact.eMail);
        let steps = [];
        const stepNumber = () => { return `${ steps.length + 1 }.` }

        steps.push( <ApInputStack key="validtill" gap="0">
            <ApAddon width="320px" noRightBorder>
                { stepNumber() } { tr('select_last_day_of_validity') }
            </ApAddon>
            <ApInput
                type="date"
                id="valid_till"
                name="valid_till"
                value={ this.state.validTill }
                onChange={ ( e ) => this.handleValidTillChange( e.target.value ) }
                loading={ this.state.loading }
                disabled={ this.state.loading || this.props.locked }
            />

        </ApInputStack> );

        if( this.state.templates.length > 1 )
        {
            steps.push( <ApInputStack key="template" gap="0">
                <ApAddon width="320px" noRightBorder>
                    { stepNumber() } { tr('select_offer_template') }
                </ApAddon>
                <ApInput
                    type="select"
                    id="selected_company"
                    name="selected_company"
                    value={ this.state.selectedTemplateId }
                    onChange={ ( e ) => this.handleTemplateChange( e.target.value ) }
                    options={ this.state.templates }
                    loading={ this.state.loading }
                    disabled={ this.state.loading || this.props.locked }
                />
                <ApAddon custom width="250px">
                    <ApButton style={{ margin: "5px" }} size="small" color="blue" onClick={ this.downloadTemplate } disabled={ !this.state.selectedTemplateId }>
                        <SvgIcon icon="download" type="solid" />
                        { stepNumber() }{ tr('download_template') }
                    </ApButton>
                </ApAddon>
            </ApInputStack> );


            steps.push( <div key="info" className="editOfferStepContainer">
                <ApInputStack gap="0">
                    <ApAddon>
                        { stepNumber() } { tr('edit_offer_and_save') }
                    </ApAddon>
                </ApInputStack>
            </div> );
        }

        steps.push( <div key="sendinfo" className="editOfferStepContainer">
            <ApInputStack gap="0">
                <ApAddon width="320px" noRightBorder>
                    { stepNumber() }  { tr('send_offer_to_person') }:
                </ApAddon>
                <ApInput
                    type="select"   
                    options= { this.getEmailOptions() }
                    //options= {this.state.contacts}
                    id="sel_Mail"
                    name="sel_Mail"
                    value={ this.state.eMail }
                    //onChange={ ( e ) => this.setState( eMail: e.target.value ) }
                    onChange={ ( e ) => this.handleEmailChange( e.target.value ) }
                    disabled={ this.state.loading || this.props.locked }
                />                
            </ApInputStack>
        </div> );

        steps.push( <ApInputStack key="upload" gap="0">
            <ApAddon width="320px" noRightBorder>
                { stepNumber() } { tr('select_offer_to_send') }
            </ApAddon>
            <ApInput
                type="file"
                accept="*"
                id="selectedFile"
                name="selectedFile"
                label={ `${tr('choose_file')}...` }
                value={ this.state.selectedFile }
                onChange={ ( file ) => { this.setState({ selectedFile: file }) } }
                loading={ this.state.loading }
                disabled={ this.state.loading || this.props.locked }
            />
        </ApInputStack> );

        steps.push(<ApInputStack key="uload" gap="0">
        <ApAddon width="320px" noRightBorder>
            { stepNumber() } { tr('title_of_the_offer_message') }
        </ApAddon>
            <ApInput
                label={ tr('title_of_the_offer_message') }
                type="text"
                id="sent_otsikko"
                name="sent_otsikko"
                value={ this.state.eMailOtsikko }
                onChange={ ( e ) => { 
                    this.setState({ eMailOtsikko: e.target.value });
                     } }
                disabled={ this.state.loading || this.props.locked }
            />
         </ApInputStack>   )

        steps.push(<ApInputStack key="pload" gap="0">
            <ApAddon width="320px" noRightBorder>
                { stepNumber() } { tr('text_of_the_offer_message') }
            </ApAddon>
            <ApInput
                label={ tr('text_of_the_offer_message') }
                type="textarea"
                id="sent_txt"
                name="sent_txt"
                value={ this.state.eMailText }
                onChange={ ( e ) => { 
                    this.setState({ eMailText: e.target.value });
                 } }
                disabled={ this.state.loading || this.props.locked }
            />
            </ApInputStack>)

        steps.push( <ApInputStack key="probability"  gap="0">
            <ApAddon noRightBorder width="320px">
                { stepNumber() } { tr('probability_of_acceptance') }
            </ApAddon>
            <ApInput
                type="select"
                options={ this.getProbabilityOptions() }
                id="propability_select"
                name="propability_select"
                value={ this.state.successProbability }
                onChange={ ( e ) => { this.handleProbabilityChange( e.target.value )} }
                disabled={ this.state.loading || this.props.locked }
            />
        </ApInputStack> );

        steps.push(
            <ApInput
                label={ tr('free_comment_on_offer') }
                type="textarea"
                id="sent_description"
                name="sent_description"
                value={ this.state.description }
                onChange={ ( e ) => { this.setState({ description: e.target.value }) } }
                disabled={ this.state.loading || this.props.locked }
            />
        );
        return steps;
    }

    getColumns()
    {
        return [{
            Header: '',
            columns: [
                {
                    id: 'created_at',
                    Header: tr('sent'),
                    accessor: 'crated_at',
                    width: 150,
                    Cell: props => {
                        return moment( props.value ).format( 'DD.MM.YYYY' )
                    },
                }, {
                    id: 'valid_till',
                    Header: tr('valid_until'),
                    accessor: 'valid_till',
                    width: 150,
                    Cell: props => {
                        return moment( props.value ).format( 'DD.MM.YYYY' )
                    },
                }, {
                    id: 'sender',
                    Header: tr('sender'),
                    accessor: 'creator_user_name',
                }, {
                    id: 'sheet_name',
                    Header: tr('calculation_version'),
                    accessor: 'sheet_name',
                }, {
                    id: 'file_id',
                    Header: tr('file'),
                    accessor: 'file_id',
                    width: 50,
                    sortable: false,
                    filterable: false,
                    Cell: props => {
                        const data = props.original;
                        return <File  fileId={ data.file_id } name={`${tr('offer')}-${ data.created_at }.${ data.file_extension }`}>
                            <SvgIcon icon="file-word" type="solid" />
                        </File>
                    },
                }, {
                    id: 'price',
                    Header: tr('offer_price'),
                    accessor: 'price',
                    Cell: props => {
                        return <div className="text-right">
                            { formatMoney( props.value ) } { this.state.currencySign }
                        </div>
                    },
                }, {
                    id: 'success',
                    Header: tr('probability'),
                    accessor: 'success_probability',
                    width: 100,
                    Cell: props => {

                        const value = parseInt( props.value, 10 );
                        if( isNaN( value )) return null;

                        const red =  150 - value;
                        const green = value * 1.5;
                        const color = `rgb( ${ red }, ${ green }, 0)`;
                        return <div className="text-center" style={{ color: color }}>
                            { value } %
                        </div>
                    },
                }, {
                    id: 'status',
                    Header: tr('status'),
                    width: 150,
                    Cell: props => {
                        let sColor = {
                            waiting: 'info',
                            accepted: 'success',
                            declined: 'error',
                        }
                        return <div className={`apStatusBox ${ sColor[ props.original.status_name ] }`}> { tr(props.original.status_name) }</div>
                    },
                }
            ]
        }]
    }

    setSentStatusClick( index, status )
    {
        this.setState({
            confirmShow: true,
            confirmStatus: status,
            confirmIndex: index,
            projectDeclined: false,
            doProjecting: false,
        });
    }

    renderSendSents()
    {
        if( this.state.sents.length === 0) return null;

        const getMenu = ( row, index  ) => {
            let actions = [];
            if( row.status_name !== 'waiting' )
            {
                actions.push({
                    icon: 'pause-circle',
                    label: tr('waiting'),
                    disabled: this.props.locked,
                    action: () => { this.setSentStatusClick( index, 'waiting' ) }
                });
            }
            if( row.status_name !== 'accepted' )
            {
                const someAccepted = this.state.sents.some( s => s.status_name === 'accepted' );
                if( !someAccepted )
                {
                    actions.push({
                        icon: 'check-circle',
                        label: tr('approved'),
                        disabled: this.props.locked,
                        action: () => { this.setSentStatusClick( index, 'accepted' ) }
                    });
                }
            }
            if( row.status_name !== 'declined' )
            {
                actions.push({
                    icon: 'times-circle',
                    label: tr('rejected'),
                    disabled: this.props.locked,
                    action: () => { this.setSentStatusClick( index, 'declined' ) }
                });
            }
            return <ApDropdown
                button={ <SvgIcon className="defaultRowActionIcon" icon="ellipsis-h" type="solid" /> }
                actions={ actions }
                actionId={ 1 }
            />
        }

        return <div className="sentsContainer">
            <h4 className="formHeader">{ tr('sent_offer') }</h4>

            <ApReactTable
                loading={ this.state.loading }
                data={ this.state.sents }
                columns={ this.getColumns() }
                minRows={ 5 }
                rowActions={ getMenu }
            />

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('project_probability_info') }
            </div>
        </div>
    }

    renderSendNew()
    {
        const newShow = this.state.sendNewShow;
        let header = <div style={{paddingBottom: '1.5em'}}>
                { tr('sending_offer_info') }
       </div>
        
        /*
        <h4 className="formHeader">
            Tarjouksen lähettäminen. Valitse ensin oikea laskentaversio ja täytä sitten alla olevat tiedot
        </h4>
        */

        if( this.state.sents.length>0 )
        {
            let headerClass = [ 'formHeader', 'pointer' ];
            if( newShow ) headerClass.push( 'open' );
            header = <h2 className={ headerClass.join(' ') } onClick={ () => { this.setState({ sendNewShow: !newShow }) } }>
                <SvgIcon icon="chevron-right" type="solid" />
                { tr('sending_new_offer') }
            </h2>
        }
        // else if(this.state.sents.length>0)
        // {
        //     header = <h4 className="formHeader">{ tr('only_one_offer_can_be_sent') }</h4>

        // }

        return <div>
            { header }

            <Collapse in={ newShow }>
                <div>
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('sending_new_offer_info') }
                    </div>
                    {this.renderOffer()}
                    { /*this.renderNewSteps()*/ }


                </div>
            </Collapse>
        </div>

    }

    renderOffer()
    {
        return (
            <div id='offerContainer'>
                <div className='offerPanel leftPanel'>
                    <ApInputStack key="uload" gap="0">
                        <ApAddon width="220px" noRightBorder>
                            {tr('title_of_the_offer_message')}
                        </ApAddon>
                        <ApInput
                            label={tr('title_of_the_offer_message')}
                            type="text"
                            id="sent_otsikko"
                            name="sent_otsikko"
                            value={ this.state.eMailOtsikko }
                            validationState={this.state.eMailOtsikko && 'success'}
                            onChange={ ( e ) => { 
                                this.setState({ eMailOtsikko: e.target.value });
                                } }
                            disabled={ this.state.loading || this.props.locked }
                        />
                    </ApInputStack>

                    <ApInputStack key="pload" gap="0">
                        <ApAddon width="220px" noRightBorder style={{height: '86px'}}>
                            {tr('text_of_the_offer_message')}
                        </ApAddon>
                        <ApInput
                            label={tr('text_of_the_offer_message')}
                            type="textarea"
                            id="sent_txt"
                            name="sent_txt"
                            validationState={this.state.eMailText && 'success'}
                            value={ this.state.eMailText }
                            onChange={ ( e ) => { 
                                this.setState({ eMailText: e.target.value });
                            } }
                            disabled={ this.state.loading || this.props.locked }
                        />
                    </ApInputStack>

                    <ApInputStack key="vload" gap="0">
                        <ApAddon width="220px" noRightBorder>
                            {tr('recipient')}
                        </ApAddon>
                        <ApInput
                            label={this.state.contacts ? this.state.contacts.name : tr('email')}
                            type="text"
                            id="sel_Mail"
                            name="sel_Mail"
                            value={ this.state.contacts ? this.state.contacts.email : '' }
                            validationState={(this.state.contacts && this.state.contacts.validEmail && this.state.contacts.validEmail === true) || this.state.contacts && this.state.contacts.name ? 'success' : 'error' }
                            onChange={ ( e ) => { 
                                const contact = {
                                    name: null, //persons email changes so name is removed
                                    email: e.target.value,
                                    validEmail: validateEmail(e.target.value)
                                }
                                this.setState({ contacts: contact })
                            } }
                            disabled={ this.state.loading || this.props.locked }
                        />
                    </ApInputStack>

                    <ApInputStack key="template" gap="0">
                        {/* <ApAddon width="220px"
                            noRightBorder
                            onClick={this.downloadTemplate}
                            disabled={!this.state.selectedTemplateId}
                            style={this.state.selectedTemplateId ? { color: '#0099CC' } : null}>
                            <SvgIcon icon="download" type="solid" /> {tr("project")}
                        </ApAddon> */}
                        <ApAddon width="220px" noRightBorder>
                            {tr('project')}
                        </ApAddon>

                        {
                            // TODO: Korjaa valitun projektin polku luomalla jokaiselle projektille vanhemmat "ancestors",
                            // jossa on kaikki staten tree muuttujan tiedot (childrens,calculated, ...)  
                        }
                        <ApNestedSelect
                            // label={this.renderLabel}
                            valueRenderer={(item) => {
                                return <div className="codeOption">
                                    <span className="name">{item.name}</span>
                                    <span className="code">{item.project_code}</span>
                                </div>
                            }}
                            value={this.state.sendSelectedProject ? this.state.sendSelectedProject : (this.state.tree[0] ? this.state.tree[0]:null)}
                            parentRenderer={(item) => {
                                return <div className="codeOption">
                                    <span className="code">{item.project_code} </span>
                                    <span className="name">{item.name}</span> 
                                </div>
                            }}
                            parentTooltipRenderer="name"
                            optionRenderer={(item) => {
                                return <div className="codeOption">
                                    <span className="code">{item.project_code} </span> 
                                    <span className="name">{item.name}</span>
                                </div>
                            }}
                            onChange={(value) => { this.setState({sendSelectedProject:value}) }}
                            options={this.state.tree}
                            loading={this.props.loading}
                            disabled={this.props.loading || this.props.skipped}
                            //validationState={(project.cost_center_is_required && project.cost_center == null) ? "error" : ""}
                        //tailInput={tailInput}
                        />
                    </ApInputStack>

                    {this.state.templates.length > 1 && <>
                            <ApInputStack key="template" gap="0">
                                <ApAddon width="220px" 
                                        noRightBorder 
                                        onClick={ this.downloadTemplate } 
                                        disabled={ !this.state.selectedTemplateId }
                                        style={this.state.selectedTemplateId ? {color: '#0099CC'} : null}>
                                    <SvgIcon icon="download" type="solid" /> Lataa tarjouspohja
                                </ApAddon>
                                <ApInput
                                    type="select"
                                    id="selected_company"
                                    name="selected_company"
                                    value={ this.state.selectedTemplateId }
                                    validationState={ this.state.offerTemplateDownloaded && 'success' }
                                    onChange={ ( e ) => this.handleTemplateChange( e.target.value ) }
                                    options={ this.state.templates }
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading || this.props.locked }
                                />
                            </ApInputStack>
                        </>}

                        <ApInputStack key="upload" gap="0">
                            <ApAddon width="220px" noRightBorder>
                                {tr('select_offer_to_send')}
                            </ApAddon>
                            <ApInput
                                type="file"
                                accept="*"
                                id="selectedFile"
                                name="selectedFile"
                                label={`${tr('choose_file')}...`}
                                value={ this.state.selectedFile }
                                validationState={this.state.selectedFile && 'success'}
                                onChange={ ( file ) => { this.setState({ selectedFile: file }) } }
                                loading={ this.state.loading }
                                disabled={ this.state.loading || this.props.locked }
                            />
                        </ApInputStack>
                </div>

                <div  className='offerPanel'>
                    <ApInputStack key="validtill" gap="0">
                        <ApAddon width="250px" noRightBorder>
                            {tr('offer_expiration_date')}
                        </ApAddon>
                        <ApInput
                            type="date"
                            id="valid_till"
                            name="valid_till"
                            value={ this.state.validTill }
                            validationState={this.state.validTill && 'success'}
                            onChange={ ( e ) => this.handleValidTillChange( e.target.value ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading || this.props.locked }
                        />
                    </ApInputStack>

                    <ApInputStack key="probability"  gap="0">
                        <ApAddon noRightBorder width="250px">
                            {tr('probability_of_acceptance')}
                        </ApAddon>
                        <ApInput
                            type="select"
                            options={ this.getProbabilityOptions() }
                            id="propability_select"
                            name="propability_select"
                            value={ this.state.successProbability }
                            validationState={this.state.successProbability !== 'null' && 'success'}
                            onChange={ ( e ) => { this.handleProbabilityChange( e.target.value )} }
                            disabled={ this.state.loading || this.props.locked }
                        />
                    </ApInputStack>

                    <ApInput
                        label={tr('free_comment_on_offer')}
                        type="textarea"
                        id="sent_description"
                        name="sent_description"
                        value={ this.state.description }
                        onChange={ ( e ) => { this.setState({ description: e.target.value }) } }
                        disabled={ this.state.loading || this.props.locked }
                    />
                </div>

            </div>)
    }

    renderSendConfirmTypeChange()
    {
        const status = this.state.confirmStatus;

        let body = null;
        if( status === 'accepted' )
        {
            body = <div>
                <div>{ tr('confirm_offer_status_change', [<span className="green">{ tr('accepted') }</span>]) }?</div>
                {/* 
                <div className="padding">
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        Tarjouksen projektointi lukitsee kyseisen projektin tarjouslaskennan, siirtää projetin pois tarjouslaskennasta,
                        luo aliprojektit ja lisää niihin varastonimikkeet käytetyn laskennan perusteella.
                        <br /><br />
                        Mikäli projektointia ei haluta tehdä heti sen tekeminen onnistuu myöhemmin.

                    </div>
                    <div className="apSwitchBlock">
                        <ApSwitch
                            id="project-instantly-switch"
                            on={ this.state.doProjecting }
                            onChange={ () => { this.setState({ doProjecting: !this.state.doProjecting }) } }
                        />
                        <label htmlFor="project-instantly-switch">
                            Projektoi kyseinen tarjous
                        </label>
                    </div>
                    { this.state.doProjecting &&
                    <div className="apInfo warning">
                        <SvgIcon icon="info-circle" type="solid" />
                        Tarjouslaskenta lukitaan!
                    </div> }
                </div>
                */}
            </div>
        }
        else if( status === 'declined' )
        {
            // let noGoDom = null;
            // const allOtherDeclined = this.state.sents.every( ( s, i ) => {
                // if( i === this.state.confirmIndex ) return true;
                // return ( s.status_name === 'declined' );
            // });
            /*
            if( allOtherDeclined )
            {
                noGoDom = <div>
                    <div className="apSwitchBlock failswitch">
                        <ApSwitch
                            id="project-instantly-switch"
                            on={ this.state.projectDeclined }
                            onChange={ () => { this.setState({ projectDeclined: !this.state.projectDeclined }) } }
                        />
                        <label htmlFor="project-instantly-switch">
                            Projektia ei saatu
                        </label>
                    </div>
                    { this.state.projectDeclined &&
                    <div className="apInfo warning">
                        <SvgIcon icon="info-circle" type="solid" />
                        Tarjouslaskenta lukitaan!
                    </div> }
                </div>
            }
            */


            body = <div>
                <div>{ tr('confirm_offer_status_change', [<span className="green">{ tr('rejected') }</span>]) }?</div>
                {/*
                <div className="padding">
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        Mikäli tämä oli viimeinen tarjous kyseiseen projektiin liittyen, voidaan projekti merkitä tilaan ei saatu.
                        Mikäli tätä kuitenkaan ei haluta tehdä projekti merkitään tilaan laskennassa.
                    </div>
                    { noGoDom }
                </div>
                */}
            </div>
        }
        else if( status === 'waiting' )
        {
            body = <div>{ tr('confirm_offer_status_change', [<span className="green">{ tr('waiting') }</span>]) }?</div>
        }

        return <div id="confirmTypeChange" className="ApModalConfirm">
            <ApModal
                show={ this.state.confirmShow }
                handleClose={ () => this.setState({ confirmShow: false }) }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('confirm_status_change') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        { body }
                    </div>
                }

                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={ () => this.setState({ confirmShow: false }) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('no') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ this.changeSentStatus }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('yes') }
                        </ApButton>
                    </div>
                }
            />
        </div>
    }

    renderSend()
    {
        if( this.state.treeUnsaved )
        {
            return <div className="padding">
                <div className="apInfoMsg">
                    <h2>{ tr('recent_changes_not_saved') }</h2>
                    <p>{ tr('save_offer_changes_info') }</p>
                </div>
            </div>
        }
        return <div id="offerSend">
            <div className="padding">
                { this.renderSendSents() }
                { this.renderSendNew() }
            </div>
            { this.renderSendConfirmTypeChange() }
        </div>
    }

    renderEmailButtonToolTip()
    {
        const tooltips = []
        let disabled = false

        if (!this.state.eMailOtsikko){ 
            disabled = true
            tooltips.push(tr('offer_title_missing'))
        }

        if (!this.state.eMailText) {
            disabled = true
            tooltips.push(tr('offer_text_missing'))
        }        

        if (!this.state.contacts || (this.state.contacts && this.state.contacts.email && !validateEmail(this.state.contacts.email))) {
            disabled = true
            tooltips.push(tr('email_missing'))
        }

        if (!this.state.validTill) {
            disabled = true
            tooltips.push(tr('last_day_of_validity_missing'))
        }

        if (this.state.successProbability === 'null') {
            disabled = true
            tooltips.push(tr('probability_missing'))
        }

        if (!this.state.selectedFile) {
            disabled = true
            tooltips.push(tr('file_missing'))
        }

        const tooltipInfo = tooltips.map((tip, index) => <div key={`email-tooltip-${index}`}>{tip}</div>)
        return {info: tooltipInfo, disabled}
    }

    renderFooter()
    {

        let saveButton = null;
        let menu = null;

        if( !this.state.locked )
        {
            const treeSaveButton = <ApTooltip text={ this.state.locked ? tr('offer_locked') : this.state.error ? this.state.error : null }>
                <ApButton
                    color="blue"
                    onClick={this.saveTree}
                    loading={this.state.loading} disabled={this.state.loading || this.state.error}
                    className={this.state.treeUnsaved ? "highlight" : ""}>
                    <SvgIcon icon="save" type="solid" />
                    { tr('save') }
                </ApButton>
            </ApTooltip>

            if( this.props.tab === 'send' )
            {
                if( this.state.treeUnsaved )
                    saveButton = treeSaveButton;
                else if( this.state.sendNewShow )
                {
                    const tooltip = this.sendNewOfferStatus();
                    const emailTooltip = this.renderEmailButtonToolTip()

                    saveButton = <>
                        <span style={{marginRight: '10px'}}>
                            { emailTooltip.info.length > 0 ?
                                <ApTooltip text={ <div>{emailTooltip.info}</div> }>
                                    <ApButton key="send" color="green" disabled={ emailTooltip.disabled } onClick={ () => this.setState({ sendAutomaticEmail: true} ) }>
                                        <SvgIcon icon="at" type="solid" /> { tr('send_offer_email') }
                                    </ApButton>
                                </ApTooltip> : 
                                <ApButton key="send" color="green" disabled={ emailTooltip.disabled } onClick={ () => this.setState({ sendAutomaticEmail: true} ) }>
                                     <SvgIcon icon="at" type="solid" /> { tr('send_offer_email') }
                                </ApButton>
                            }
                        </span>
                        <ApTooltip text={ tooltip }>
                            <ApButton key="send" color="blue" disabled={ Boolean( tooltip ) } onClick={() => this.saveOffer() }>
                                <SvgIcon icon="envelope" type="solid" /> { tr('send_offer_manually') }
                            </ApButton>
                        </ApTooltip>
                    </>
                }
            }
            else if (this.props.tab === 'buy') {
                saveButton = null;
            }
            else
                saveButton = treeSaveButton;

            let projectAction = {
                label: <div className={`apStatusBox success`}> { tr('project_verb') } </div>,
                icon: 'forward',
                disabled: this.state.loading,
                action: ( item, closeFunc ) => {
                    this.setState({ projectingConfirmShow: true} );
                    closeFunc();
                },
            };
            let declinedAction = {
                label: <div className={`apStatusBox error`}> { tr('not_received') } </div>,
                icon: 'times-circle',
                disabled: this.state.loading,
                action: ( item, closeFunc ) => {
                    this.setState({ declinedConfirmShow: true} );
                    closeFunc();
                },
            };

            if( this.state.treeUnsaved )
            {
                projectAction.label = <div className={`apStatusBox`}> { tr('project_verb') } ( { tr('save_first') } )</div>;
                projectAction.disabled = true;
                declinedAction.label = <div className={`apStatusBox`}> { tr('not_received') } ( { tr('save_first') } )</div>;
                declinedAction.disabled = true;
            }

            let menuActions = [];
            menuActions.push( projectAction );
            menuActions.push( declinedAction );

            menu = <ApDropdown
                position="top"
                actions={ menuActions }
                button={ <ApButton className="footerMenu" color="white">
                    <SvgIcon icon="ellipsis-h" type="solid" />
                </ApButton> }
            />
        }
        else if( this.state.project && this.state.project.offer.status === 'declined' )
        {
            saveButton = <ApButton color="red" onClick={ () => this.setState({ restoreConfirmShow: true }) } loading={ this.state.loading } disabled={ this.state.loading }>
                <SvgIcon icon="recycle" type="solid" />
                { tr('return_to_calculation') }
            </ApButton>

        }

            // |{ this.state.treeUnsaved && 'TRUE' }|
        return <div className="saveBar">
            { this.renderSelectedComponents() }
            { saveButton }
            { menu }
        </div>
    }

    infoBarOnChange( type, value )
    {
        if( type === 'customer')
            this.setState({ project: value });
        else if( type === 'manager')
        {
            let project = { ...this.state.project };
            project.manager = value;
            this.setState({ project: project });
        }
        else if( type === 'workhour_approver')
        {
            let project = { ...this.state.project };
            project.workhour_approver = value;
            this.setState({ project: project });
        }
	    else if( type === 'customer_order_number')
        {
            let project = { ...this.state.project };
            project.customer_order_number = value;
            this.setState({ project: project });
        }
        else if( type === 'offer_received')
        {
            let project = { ...this.state.project };
            project.offer.date_received = value;
            this.setState({ project: project });

        }
        else if( type === 'offer_deadline')
        {
            let project = { ...this.state.project };
            project.offer.date_deadline = value;
            this.setState({ project: project });
        }
        this.setState({ treeUnsaved: true });
    }

    onPurchaseOrderOfferSave() {
        this.setState({selectedBuyTab: 1});
    }

    render()
    {
        const project = this.state.project;
        let content = null

        const purchasesTab = hasPermissions('purchases.orders')
            ? (
                <ApTab icon="shopping-cart" label={ tr('purchase') } mountOnSelect>
                    <ApTabs parentTabControl={this.state.selectedBuyTab} onChange={(index) => this.setState({selectedBuyTab: index})}>
                        <ApTab icon="envelope-open-text" label={tr('send')} mountOnSelect>
                            <PurchaseOrderOffer tree={this.state.tree} sheetId={this.state.sheetId} onSave={this.onPurchaseOrderOfferSave} />
                        </ApTab>

                        <ApTab icon="mail-bulk" label={tr('sent')} mountOnSelect disabled={!this.state.tree.length}>
                            <SentPurchaseOrderOffers projectId={this.state.tree.length ? this.state.tree[0].real_project_id : null} sheetId={this.state.sheetId} tree={this.state.tree} />
                        </ApTab>
                    </ApTabs> 
                </ApTab>
            )
            : null;

        if( this.state.sheets.length )
        {
            const selectedTab = this.getTabId( this.props.tab );

            content = <div>
                { this.renderSheetSelector() }
                <ApTabs size="large" fullWidth selected={ selectedTab } onChange={ this.onTabChange } >
                    <ApTab icon="calculator" label={ tr('calculation') }>
                        <div className="padding" id='offer-calculation-container'>
                            { this.renderBudget() }
                        </div>
                    </ApTab>

                    <ApTab icon="calendar" label={ tr('scheduling') }>
                        <div className="padding">
                            { this.renderSchedule() }
                        </div>
                    </ApTab>

                    <ApTab icon="envelope" label={ tr('shipment') }>
                        { this.renderSend() }
                    </ApTab>
                    
                    {purchasesTab}
                </ApTabs>
            </div>
        }
        else if( !this.state.loading )
        {
            content = <div className="padding">
                <div className="apErrorMsg">
                    <h2>{ tr('no_offer_calculation') }</h2>
                    <p>{ tr('no_offer_calculation_info') }</p>
                </div>
            </div>
        }


        return <div id="OfferCalculation">
            <ApFormPage
                className="componentsListForm"
                customFooter={ () => this.renderFooter() }
                unsaved={ this.state.treeUnsaved }
                onSave={ () => {} }
                noPrompt={ nextUrl => {
                    return nextUrl.startsWith( `/projects/${ this.props.projectId }/offer` );
                }}
            >
                <Head
                    form="offer"
                    projectId={ this.props.projectId }
                    project={ project }
                    loading={ this.state.loading }
                    content={ content }
                    onChange={ this.infoBarOnChange }
                    locked={ this.state.locked }
                />
            </ApFormPage>

            <ApConfirm
                show={ this.state.projectingConfirmShow }
                onClose={ () => { this.setState({ projectingConfirmShow: false } ) } }
                onConfirm={ this.doProjecting }
                header={ tr('confirm_offer_projection') }
                body={<div>
                    <div>{ tr('confirm_offer_projection_info') }</div>
                    <div className="apInfo warning">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('offer_calculation_will_be_locked') }
                    </div>
                </div>}
            />

            <ApConfirm
                show={ this.state.sendAutomaticEmail }
                onClose={ () => { this.setState({ sendAutomaticEmail: false } ) } }
                onConfirm={() => this.saveOffer(true) }
                header="Tarjouksen sähköpostilähetys"
                body={<div>
                    <div style={{marginBottom: '1em'}}>Tarjous lähetetään <span style={{color: '#0099CC'}}>automaattisesti asiakkaan sähköpostiin.</span> Haluatko jatkaa?</div>
                    <div className="apInfo" style={{fontSize: '0.85em'}}>
                        <SvgIcon icon="info-circle" type="solid" />
                        Jos haluat lähettää itse, valitse "Lähetä tarjous manuaalisesti".
                    </div>
                </div>}
            />

            <ApConfirm
                show={ this.state.declinedConfirmShow }
                onClose={ () => { this.setState({ declinedConfirmShow: false } ) } }
                onConfirm={ this.doDecline }
                header={ tr('confirm_offer_rejection') }
                body={<div>
                    <div>{ tr('confirm_offer_rejection_info') }</div>
                    <div className="apInfo warning">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('offer_calculation_will_be_locked') }
                    </div>

                    <Collapse in={ this.state.decline_reason_enabled === true}>
                        <div className="padding">

                    <ApSelect
                    label={ tr('rejection_reason') }
                    value={ this.state.decline_reason }
                    optionRenderer="decline_reason"
                    onChange={ this.setDeclineReason }
                    objKeyId="id"
                    objKeyValue="decline_reason"
                    clearable
                    apiUrl='project/declinereason/find'
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    validationState={ !this.state.decline_reason ? "error" : "" }
                    />

                 <ApInput
                    type="textarea"
                    name="decline_description"
                    id="decline_description"
                    label={ tr('additional_info') }
                    value={ this.state.data.decline_description }
                    onChange={ this.handleDeclineDescription  }
                    disabled={ this.state.loading }
                    loading={ this.state.loading }
                   />

                   </div>
                    </Collapse> 

                </div>}
            />

            <ApConfirm
                show={ this.state.restoreConfirmShow }
                onClose={ () => { this.setState({ restoreConfirmShow: false } ) } }
                onConfirm={ this.doRestoreDeclined }
                header={ tr('confirm_offer_return') }
                body={<div>
                    <div>{ tr('confirm_offer_return_info') }</div>
                </div>}
            />
            <ApModalInput
                show={this.state.showEditComment}
                value={this.state.commentData ? this.state.commentData.component.comment : null}
                type={"textarea"}
                onSave={this.saveComment}
                onCancel={() => { this.setState({ showEditComment: false }) }}
                title={tr("comment")}
                info={"info"}
            />
            <ApConfirm
                show={this.state.removeSheetConfirmShow}
                onClose={() => { this.setState({ removeSheetConfirmShow: false }) }}
                onConfirm={this.removeSheet}
                header={tr('delete_spreadsheet_confirm')}
                body={tr('delete_spreadsheet_info')}
            />

            <ApModalInput
                show={this.state.renameSheetShow}
                title={tr("rename_calculation_version")}
                value={this.getSheetName(this.state.sheetId)}
                onSave={(value) => this.renameSheet(value)}
                onClose={() => this.setState({ renameSheetShow: false })}
            />
        </div>
    }
}



export default OfferCalculation;
