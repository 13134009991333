import React from 'react';
import autoBind from 'react-autobind';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import api from 'services/Api/Api.js';
import { errorPopper, tr } from 'services/Helpers/Helpers.js'
import './MassExport.css';

class MassExport extends React.Component {
    constructor(props)
    {
        super(props);

        let directs = [
            { checked: true, item: 'name', label: tr('name') },
            { checked: true, item: 'type', label: tr('type') },
            { checked: true, item: 'status', label: tr('status') },
            { checked: true, item: 'code', label: tr('code') },
            { checked: true, item: 'unit', label: tr('unit') },
            { checked: true, item: 'price', label: tr('value') },
            { checked: true, item: 'profit_percent', label: tr('gross_profit_percent') },
            { checked: true, item: 'price_sell', label: tr('selling_price') },

            // { checked: true, item: 'conversion_factor', label: tr('conversion_factor') },
            // { checked: true, item: 'conversion_unit', label: 'conversion_unit' },
            { checked: true, item: 'description', label: tr('free_text') },
        ];

        let altnames = [
            { checked: false, item: 'altnames', label: tr('unifying_name') },
        ];

        let locations = [
            { checked: false, item: 'code', label: tr('code') },
            { checked: false, item: 'name', label: tr('name') },
            { checked: false, item: 'balance', label: tr('amount') },
            { checked: false, item: 'limit_notification', label: tr('attention_limit') },
            { checked: false, item: 'limit_alarm', label: tr('alert_limit') },
            { checked: false, item: 'order_amount', label: tr('default_order_amount') },
            { checked: false, item: 'balance_free', label: tr('available_balance') },
        ];

        let suppliers = [
            { checked: false, item: 'name', label: tr('name') },
            { checked: false, item: 'purchase_price', label: tr('price') },
        ];

        let filters = [
            { checked: false, item: 'has_storage_location', label: tr('has_storage_location') },
            { checked: false, item: 'has_product_code', label: tr('has_product_code') },

            { checked: false, item: 'is_component', label: tr('component') },
            { checked: false, item: 'is_work', label: tr('work') },
            { checked: false, item: 'is_cost', label: tr('cost') },
        ];

        this.state = {
            loading: false,
            directs: directs,
            altnames: altnames,
            locations: locations,
            suppliers: suppliers,
            filters: filters,

            calculatables: [],
            identifiers: [],
            properties: [],
            counts: {},
            component_types: [],
        }
        autoBind(this);
    }

    componentDidMount()
    {
        this.getRelated();
    }

    getRelated()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'storage/masslist/export',
            errorPopper: tr('get_error')
        }).then((response) => {
            //console.log('response', response );

            const format = ( items ) => {
                return items.map( item => {
                    return { checked: false, item: item.id, label: item.name };
                });
            };
            this.setState({
                loading: false,
                calculatables: format( response.calculatables ),
                identifiers: format( response.identifiers ),
                properties: format( response.properties ),
                component_types: format(response.component_types),
                counts: response.counts,
            });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    getCheckedCols()
    {
        let count = 0;
        const filter = ( field ) => {
            let tmp = [];
            this.state[ field ].forEach( ( r ) => {
                if( r.checked ) {

                    let multiplier = 1;
                    if ( field in this.state.counts )
                        multiplier = this.state.counts[ field ];

                    count += multiplier;

                    tmp.push( r.item );
                }
            });
            return tmp;
        }
        let data = {
            direct: filter( 'directs' ),
            alt_names: filter( 'altnames' ),
            locations: filter( 'locations' ),
            suppliers: filter( 'suppliers' ),
            calculatables: filter( 'calculatables' ),
            identifiers: filter( 'identifiers' ),
            properties: filter( 'properties' ),
            filters: filter( 'filters' ),
            component_types: filter( 'component_types' ),
        };
        return {
            cols: data,
            colCount: count,
        }
    }


    
    export()
    {
        const data = this.getCheckedCols().cols;

        //console.log( 'exporting', data );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'storage/masslist/export',
            responseType: 'blob',
            data: data,
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Varastonimikkeet.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('get_error'));
            this.setState({ loading: false });
        });

    }

    toggle( type, i )
    {
        let data = this.state[ type ].slice();
        data[ i ].checked = !data[ i ].checked;
        this.setState({ type: data });
    }

    toggleGroup( type, checked )
    {
        let data = this.state[ type ].slice();
        data = data.map( i => {
            i.checked = checked;
            return i;
        });
        this.setState({ type: data });
    }

    renderSection( type, title )
    {
        let count = null;
        if ( type in this.state.counts )
        {
            count = this.state.counts[ type ];
            if( !count ) return null;
        }

        const rowDoms = this.state[ type ].map( ( r, i ) => this.renderRow( type , r, i ));
        if( !rowDoms.length ) return null;

        return <React.Fragment>
            { this.renderHeader( type, title, count )  }
            <tbody>{ rowDoms }</tbody>
        </React.Fragment>
    }

    renderHeader( type, title, count )
    {
        let countDom = null;
        if( count ) countDom = <span>({ count })</span>

        let noRead = null;
        if( [ 'locations', 'suppliers' ].includes( type ) )
            noRead = <ApTooltip text={ tr('import_values_cannot_be_updated') } position="topleft">
                <span className="headerSmall">({ tr('no_imports') })</span>
            </ApTooltip>

        let checked = this.state[ type ].some( i => i.checked );
        return <thead key={ type }>
            <tr className="headerrow">
                <th className="thcheck" onClick={ () => { this.toggleGroup( type, !checked ) } }>
                    <input
                        type="checkbox"
                        checked={ checked }
                        readOnly
                    />
                </th>
                <th colSpan="2"> { title } { countDom } { noRead } </th>
            </tr>
        </thead>
    }

    renderRow(type, r, i )
    {
        return <tr className="fieldrow" key={ r.item }>
            <td className="tdindent">
            </td>
            <td className="tdcheck" onClick={ () => { this.toggle( type, i ) } }>
                <input
                    type="checkbox"
                    checked={ r.checked }
                    readOnly
                />
            </td>
            <td className="tdname">
                { r.label }
            </td>
            <td className="tdinfo">
                { r.info }
            </td>
        </tr>
    }

    renderFooter()
    {
        const colCount = this.getCheckedCols().colCount;
        const rowCount = this.state.counts.rows || 0;

        let buttonError = null;
        if( colCount === 0 ) buttonError = tr('no_columns_selected');
        if( rowCount === 0 ) buttonError = tr('no_storage_rows');

        return <div className="footer padding">
            <div className="footerCounts">
                <table>
                    <tbody>
                        <tr>
                            <td>{ tr('rows_count') }:</td>
                            <td>{ rowCount }</td>
                        </tr><tr>
                            <td>{ tr('columns') }:</td>
                            <td>{ colCount }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ApTooltip text={ buttonError }>
                <ApButton
                    className="save"
                    color="blue"
                    onClick={ this.export }
                    disabled={ this.state.loading || buttonError }
                    loading={ this.state.loading }
                >
                    <SvgIcon icon="file-excel" type="solid" />
                    { tr('generate_xlsx') }
                </ApButton>
            </ApTooltip>
        </div>
    }

    render()
    {

        return <div id="storageMassExport">
            <ApFormPage 
                className=""
                unsaved={ false }
                onSave={ () => {} }
                customFooter={ () => this.renderFooter() }
            >
                <div className="topInfo">
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('select_data_to_excel') }
                    </div>
                </div>
                <div className="clear">
                    <div className="apColumn w50">
                        <div className="padding">
                            <table className="fieldsTable">
                                { this.renderSection('directs', tr('storage_component') )  }
                                { this.renderSection('altnames', tr('unifying_names') )  }
                                { this.renderSection('locations', tr('storages') )  }
                                { this.renderSection('suppliers', tr('suppliers') )  }
                            </table>
                        </div>
                    </div>
                    <div className="apColumn w50">
                        <div className="padding">
                            <table className="fieldsTable">
                                { this.renderSection('filters', tr('filters') )  }
                                { this.renderSection('calculatables', tr('meters') )  }
                                { this.renderSection('identifiers', tr('identifiers') )  }
                                { this.renderSection('properties', tr('properties') )  }
                                { this.renderSection('component_types', tr('storage_component_types') )  }
                            </table>
                        </div>
                    </div>
                </div>
            </ApFormPage>
        </div>
    }
}

export default MassExport;
