import React, {Component} from 'react';
import styles from './TaxAnnouncement.module.css';

import ApButton from 'common/ApButton/ApButton';
import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable';
import { errorPopper, getFileById, tr } from 'services/Helpers/Helpers';
import TaxAnnouncementModal from './TaxAnnouncementModal';
import api from 'services/Api/Api';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import ApModal from 'common/ApModal/ApModal';
import moment from 'moment';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';

class TaxAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            workgroups: [],
            groupModalOpen: false,
            deleteAnnouncementModalOpen: false,
            selectedRowId: null,

            personnel: [],
            groups: [],
            announcements:[],

        }
    }

    componentDidMount() {
        this.getTaxAnnouncements();
    }

    getTaxAnnouncements = () => {
        this.setState({loading: true});
        api({
            method: 'get',
            url: 'project/management/getTaxAnnouncement',
        }).then(response => {
            this.setState({ announcements:response, loading: false});
        }).catch(error => {
            errorPopper(error, tr('get_error'));
            console.error(error);
            this.setState({loading: false});
        })
    }

    deleteAnnouncement(id) {
        this.setState({loading: true});
        api({
            method: 'post',
            url: 'project/management/taxAnnouncement/delete',
            data: {
                id
            }
        }).then(( response ) => {
            const announcements = this.state.announcements.filter(row => row.id !== id);
            this.setState({ 
                announcements, 
                deleteAnnouncementModalOpen: false, 
                selectedRowId: null 
            });
            errorPopper(null, tr('deleted'), 7000, 'success');
        }).catch((error) => {
            console.error(error);
            errorPopper(null, tr('delete_error'));
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    setSendedDate(id) {
        this.setState({loading: true});
        api({
            method: 'post',
            url: 'project/management/taxAnnouncement/markSent',
            data: {
                id
            }
        }).then(( response ) => {
            const taxAnnouncements = this.state.announcements.map(row => {
                if (row.id === id) {
                    row.sended = new Date();
                }
                return row;
            });
            this.setState({ taxAnnouncements });
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('save_error'));
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    closeModal = (refresh=false) => {
        this.setState({
            announcementModalOpen: false,
            // deleteGroupModalOpen: false,
            selectedGroup: null
        });

        if(refresh) {
            this.getWorkgroups();
        }
    }

    getTaxAnnouncementRowById(id) {
        return this.state.announcements.find(row => row.id === id);
    }

    render() {
        return (
            <div id="workGroups">
                <div className="apBox">
                    <div className="apBoxHeader">
                        <h1>{tr('tax_announcements')}</h1>
                        {/* <div className="apBoxCorner">
                            <ApButton 
                                onClick={() => this.setState({announcementModalOpen: true})}
                                color="green"
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            >
                                {tr('new_announcement')}
                            </ApButton>
                        </div> */}
                    </div>
                    <div className='padding'>
                        <ApReactTable 
                            loading={this.state.loading}
                            filterable
        					showFiltersInitially={true}
                            columns={[
                                /*{
                                    id: 'status',
                                    Header: tr('status'),
                                    accessor: (row) => {
                                        if (row.status === 'draft') {
                                            return tr('draft');
                                        }
                                        else if (row.status === 'sent') {
                                            return tr('sent');
                                        }
                                        return''
                                    },
                                    onClick: (row) => this.setState({ selectedAnnouncement: row, announcementModalOpen: true}),
                                    // customFilter: {
                                    //     type: "text",
                                    //     placeholder: tr('status'),
                                    // },
                                },*/
                                colPreset({
                                    type: 'date',
                                    id: 'date',
                                    Header: tr('announcement_created_at'),
                                    accessor: 'file.created_at',
                                    filterable: false,
                                }),
                                {
                                    id: 'announcement_date',
                                    Header: tr('announcement_date'),
                                    accessor: 'announcement_date',
                                    filterable: false,
                                    Cell: (row) => {
                                        // check if value is a date
                                        if (moment(row.value, 'YYYY-MM-DD', true).isValid()) {
                                            return moment(row.value).format('YYYY MMMM');
                                        }
                                    }
                                },
                                {
                                    id: 'project',
                                    Header: tr('project'),
                                    accessor: 'project.name',
                                    // onClick: (row) => this.setState({ selectedAnnouncement: row, announcementModalOpen: true}),
                                    customFilter: {
                                        type: "text",
                                        placeholder: tr('project'),
                                    },
                                    Cell: (row) => {
                                        return <div>{row.value}<small style={{display: 'block'}}>{row.original.project.project_code}</small></div>
                                    },
                                    customFilter: {
                                        type: "text",
                                        placeholder: tr('name'),
                                        fields: ["_original.project.name", "_original.project.project_code"]
                                    },
                                },
                                {
                                    id: 'announcement_type',
                                    Header: tr('announcement_type'),
                                    accessor: 'announcement_type',
                                    filterable: false,
                                    Cell: (row) => {
                                        switch(row.value) {
                                            case 'P':
                                                return tr('tax_announcement_type_basic');
                                            case 'K':
                                                return 'Korjausilmoitus';
                                            case 'D':
                                                return 'Poistoilmoitus';
                                            default:
                                                return '';
                                        }
                                    }
                                },
                                {
                                    id: 'sent',
                                    Header: tr('sent'),
                                    accessor: 'sended',
                                    filterable: false,
                                    Cell: (row) => {
                                        return row.value ? moment(row.value).format('L HH:mm') : '';
                                    }
                                },
                                {
                                    id: 'file',
                                    Header: tr('file'),
                                    accessor: 'file',
                                    Cell: (row) => {
                                        return <SvgIcon icon="file" type="solid" onClick={() => getFileById(row.original.file.id, row.original.file.original_name, loading => this.setState({loading}))} className='pointer' />
                                    },
                                    width: 100,
                                    className: 'center',
                                    filterable: false,
                                },
                            ]} 
                            data={this.state.announcements}
                            rowActions={[
                                {
                                    label:  tr('download_file'),
                                    icon: "file",
                                    action: ( rowId, closeFunc ) => {
                                        const taxAnnouncementRow = this.getTaxAnnouncementRowById(rowId);
                                        getFileById(
                                            taxAnnouncementRow.file.id, 
                                            taxAnnouncementRow.file.original_name, 
                                            loading => this.setState({loading})
                                        );
                                    },
                                    closeAfter: true,
                                },
                                // {
                                //     label:  tr('send'),
                                //     icon: "paper-plane",
                                //     action: ( rowId, closeFunc ) => {
                                //         // const taxAnnouncementRow = this.getTaxAnnouncementRowById(rowId);
                                //         // console.log('send', rowId, taxAnnouncementRow);
                                //         this.sendTaxAnnouncement(rowId);
                                //     },
                                //     closeAfter: true,
                                // },
                                {
                                    label:  tr('mark_as_sent'),
                                    icon: "check",
                                    action: ( rowId, closeFunc ) => {
                                        this.setSendedDate(rowId);
                                    },
                                    closeAfter: true,
                                },
                                // Delete action
                                {
                                    label: tr('delete'),
                                    icon: "trash",
                                    action: (rowId, closeFunc) => {
                                        // Show delete confirmation modal
                                        this.setState({
                                            deleteAnnouncementModalOpen: true,
                                            selectedRowId: rowId
                                        });
                                    },
                                    closeAfter: true,
                                }
                            ]}
                        />
                    </div>
                </div>
                <ApConfirm
                    show={this.state.deleteAnnouncementModalOpen}
                    onClose={() => this.setState({ deleteAnnouncementModalOpen: false, selectedRowId: null })}
                    onConfirm={() => this.deleteAnnouncement(this.state.selectedRowId)}
                    header={tr('delete_announcement')}
                    body={
                        <div>
                            <p>
                                <strong>{tr('delete_announcement_confirm')}</strong>
                            </p>

                        </div>
                    }
                />
            </div>
        )
    }
}

export default TaxAnnouncement;
