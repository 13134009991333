/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import axios from 'axios';
import { Prompt } from 'react-router'
import moment from 'moment';
import Measure from 'react-measure';
import _ from 'lodash'
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';
import { connect } from 'react-redux';

import './Billable.css';

import auth from 'services/Authed/Authed.js';
import { errorPopper, groupify, keyExists, formatMoney, sqlToDateInput, validateDate, timeSince, tr, formatCurrency } from 'services/Helpers/Helpers.js';

import ApButton from 'common/ApButton/ApButton.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApReactTable,{ colPreset } from 'common/ApReactTable/ApReactTable.js';
import NewOrder from 'modules/Storage/Orders/NewOrder/NewOrder.js';
import { matchSorter } from 'match-sorter';

import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import { ApInputStack, ApInput, ApAddon } from 'common/ApInput/ApInput';
import ApOptionInput from 'common/ApOptionInput/ApOptionInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import {    rememberStartDate, 
            getStartDate,
            getEndDate,
            rememberEndDate, 
            getContinueWithCompany, 
            setContinueWithCompany } from './billableHelper'

class Billable extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            billedLoading: false,
            entries: [],
            filter: {
                contract_billing: true,
                open: true,
                rejected: true,
                approved: true,
                pending: true,
                billed: true,
                not_billable: true
            },
            filtersFetched: false,

            section: 0, // index of the tab

            company: null,
            date_start: null,
            date_end: null,
            
            showConfirmation: false,
            sendmail: false,

            selectedKeys: [],
            selectedComponentKeys: [],
            selectedProject: null,

            changes: [],
            editModal: {
                show: false,
                ids: false,
                field: null,
                value: null,
                info: false,
                options: [],
                required:true,
            },

            showArchived: false,

            newOrder: {},

            projectStorageEnabled: false,
            projectComponents: [],

            editComponentModal: false,
            componentModalValue: null,
            componentModalIndex: null,
            componentModalOldValue: null,

            poLoading: false,
            purchaseOrderRows: [],

            editPoModal: false,
            poModalIndex: null,
            poModalType: null,
            poModalValue: null,
            poModalOldValue: null,
            poModalRequired:true,

            selectedPoKeys: [],
            billingRowsUpdated:false,
        };
        autoBind(this);

        this.editableFields = {
            "approver_id":          tr('extranet_approver'),
            "amount":               tr('amount'),
            "count":                tr('amount'),
            "unit_price":           tr('unit_price'),
            "price_sell":           tr('unit_price'),
            "customer_work_number": tr('customer_work_number'),
        };;

        this.cancelToken = null;
        this.billedCancelToken = null;
    }

    componentDidMount()
    {
        // this.getCompany()
        this.getFilterOptions()
        this.refreshEntries()
    }

    getCompany()
    {
        // Get company ID and possible date from URL parameter
        let company = parseInt( this.props.match.params.company, 10 );
        const date = this.props.match.params.date ? this.props.match.params.date.split( "_" ) : null;
        
        if(window.sessionStorage.getItem('billingCompanyId')) {
            const storage = window.sessionStorage.getItem('billingCompanyId')
            const item = JSON.parse(storage)

            const sessionLength = moment.duration(moment().diff(item.setDate)).asHours()

            if (sessionLength < 15) company = item.companyId
        }
        
        if( company )
        {
            api({
                method: 'get',
                url: 'billable/company/' + company,
                errorPopper: tr('get_error'),
            }).then(( response ) => {
                this.setState({ 
                    company: response,
                    date_start: ( date && date.length > 0 && validateDate( date[0] ) ? date[0] : null ),
                    date_end: ( date && date.length > 1 && validateDate( date[1] ) ? date[1] : null ),
                    selectedProject: null,
                }, this.fetchData );
            });
        }

        if (getContinueWithCompany()) {
            this.setState({
                date_start: getStartDate(),
                date_end: getEndDate(),
                section: 2
            })
            return
        }

        setContinueWithCompany(false)
    }

    onTabChange( tabIndex )
    {
        this.setState({
            section: tabIndex,
            selectedKeys: [],
        });

        if( this.reactTable )
            this.reactTable.clearSelectedRows();
         
    }

    getFieldName( field )
    {
        if( field in this.editableFields )
            return this.editableFields[ field ];
        return "";
    }

    getSectionStatuses()
    {
        let statuses = {
            0: [ "open" ],
            1: [ "pending", "rejected" ],
            2: [ "approved" ],
            3: [ "not_billable" ],
            4: [ "billed" ],
        };

        if( this.state.showArchived )
            statuses[ 3 ].push( "archived" );

        return statuses[ this.state.section ];
    }

    getSelectedIds()
    {
        const statuses = this.getSectionStatuses();
        const entries = this.state.entries.filter( e => statuses.indexOf( e.status ) != -1 );
        let ids = [];
        this.state.selectedKeys.forEach( i => {
            ids.push( entries[ i ].id )
        });
        return ids;
    }
    
    getSelectedRows()
    {
        const statuses = this.getSectionStatuses();
        const entries = this.state.entries.filter( e => statuses.indexOf( e.status ) != -1 );
        let rows = [];
        this.state.selectedKeys.forEach( i => {
            rows.push( entries[ i ] )
        });
        return rows;
    }

    getBaseActions(){
        return {
            0: [
                {
                    label: this.state.company.extranet_active ? tr('to_customer_acceptance') : tr('to_billing'),
                    icon: "arrow-right",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.changeStatus( this.state.company.extranet_active ? "pending" : "approved" ),
                    closeAfter: true,
                },
                {
                    label: tr('not_billed'),
                    icon: "ban",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.changeStatus( "not_billable" ),
                    closeAfter: true,
                },
                { 
                    divider: true 
                },
                {
                    label: tr('edit_customer_work_numbers'),
                    icon: "flag",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.onCellEdit( "customer_work_number" ),
                    closeAfter: true,
                },
                {
                    label: tr('edit_quantities'),
                    icon: "asterisk",
                    disabled: this.state.selectedKeys.length == 0, 
                    action: () => this.onCellEdit( "amount" ),
                    closeAfter: true,
                },
                {
                    label: tr('edit_unit_prices'),
                    icon: "euro-sign",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.onCellEdit( "unit_price" ),
                    closeAfter: true,
                },
                {
                    label: tr('edit_approvers'),
                    icon: "address-card",
                    disabled: this.state.selectedKeys.length == 0, // TODO: or check if company does not have extranet at all
                    action: () => this.onCellEdit( "approver_id" ),
                    closeAfter: true,
                },
            ],
            1: [
                {
                    label: tr('resend_to_customer'),
                    icon: "redo",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.changeStatus( "pending_again" ),
                    closeAfter: true,
                },
                {
                    label: tr('not_billed'),
                    icon: "ban",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.changeStatus( "not_billable" ),
                    closeAfter: true,
                },
                {
                    label: tr('resend_email_to_customer'),
                    icon: "mail-bulk",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.resendEmail(),
                    closeAfter: true,
                },
            ],
            2: [
                {
                    label: tr('make_sales_order'),
                    icon: "receipt",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.createNewOrder(),
                    closeAfter: true,
                },
                {
                    label: tr('cancel_billing'),
                    icon: "ban",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.cancelSelected(),
                    closeAfter: true,
                },
                
            ],
            3: [
                {
                    label: tr('return_to_billing'),
                    icon: "arrow-left",
                    disabled: this.state.selectedKeys.length == 0,
                    action: () => this.changeStatus( "open" ),
                    closeAfter: true,
                },
            ]
        }
    }

    getComponentSectionActions()
    {
        const actions = this.getBaseActions()

        actions[0].forEach((item) => {
            item.disabled = this.state.selectedComponentKeys.length === 0
        })

        actions[0][0].action = (keys) => {
            if (keys.length === 1) {
                this.changeStatus( this.state.company.extranet_active ? "pending" : "approved",  keys, {isProjectStorageComponent: true})
            } else {
                this.changeStatus( this.state.company.extranet_active ? "pending" : "approved",  false, {isProjectStorageComponent: true})
            }
        }

        actions[0][1].action = (keys) => {
            if (keys.length === 1) {
                this.changeStatus( "not_billable", keys, {isProjectStorageComponent: true} )
            } else {
                this.changeStatus( "not_billable", false, {isProjectStorageComponent: true} )
            }
        }

        // actions[0].splice(3, 1) //remove customer order number edit.
        //if restored, remember to add 1 to indeces below

        actions[0][3].action = (keys) => {
            this.onStorageComponentEdit(keys.length, keys, "customer_work_number" )
        }

        actions[0][4].action = (keys) => {
            this.onStorageComponentEdit(keys.length, keys, "amount" )
        }

        actions[0][5].action = (keys) => {
            this.onStorageComponentEdit(keys.length, keys, "price" )
        }

        if (this.state.company.extranet_active) {
            actions[0][6].action = () => this.onCellEdit( "approver_id", false, true )
        } else {
            actions[0].splice(6, 1)
        }
        

        return actions[0]
    }

    getSectionActions()
    {
        const actions = this.getBaseActions()

        if( auth.isAdmin() )
            actions[3].push({
                label: tr('archive'),
                icon: "archive",
                disabled: this.state.selectedKeys.length == 0,
                action: () => this.changeStatus( "archived" ),
                closeAfter: true,
            });

        return actions[ this.state.section ];
    }

    fetchData()
    {
        if (!this.state.company)
        {
            this.setState({ 
                entries: [],
                selectedKeys: [],
                selectedComponentKeys: [],
                loading: false,
                changes: [],
            });
            this.props.history.replace( '/billable' );  
            return false;
        }

        let pageUrl = '/billable/' + this.state.company.id;
        if( this.state.date_start || this.state.date_end )
            pageUrl += '/' + ( this.state.date_start || '' ) + '_' + ( this.state.date_end || '' );
        
        this.props.history.replace( pageUrl );  

        //console.log( 'COMPANY', this.state.company );

        if( this.cancelToken )
            this.cancelToken.cancel("Only one request allowed at a time.");
        if( this.billedCancelToken )
            this.billedCancelToken.cancel("Only one request allowed at a time.");

        this.cancelToken = axios.CancelToken.source();
        this.billedCancelToken = axios.CancelToken.source();

        this.setState({ loading: true, changes: [] });
        api({
            cancelToken: this.cancelToken.token,
            method: 'post',
            url: `billable/get`,
            data: {
                company:    this.state.company.id,
                date_start: this.state.date_start,
                date_end:   this.state.date_end
            },
        }).then(( response ) => {
            //console.log('ENTRIES', response );
            const billedEntries = this.state.entries.filter((entry) => {
                if (entry.status === 'billed' || entry.status === 'not_billable') return entry
            })
            this.setState({
                entries: [...response, ...billedEntries],
                selectedKeys: [],
                selectedComponentKeys: this.reactTable.getSelectedRows(),
                loading: false,
            }, () => {
                this.setPrimaryApprovers();
            });

            //(request spliced in order to ease the current pressure on back-end request)
            //fetch project storage balances
            // if (this.state.projectStorageEnabled) {
            //     const openProjectEntryIds = response.filter((entry) => {
            //         if (entry.status !== 'billed' && entry.status !== 'not_billable') return entry
            //     }).map(entry => entry.project_id)

            //     if (openProjectEntryIds.length > 0) {
            //         const onlyUnique = (value, index, self) => {
            //             return self.indexOf(value) === index
            //         }

            //         const uniqueIds = openProjectEntryIds.filter(onlyUnique)
            //         api({
            //             method: 'post',
            //             url: `billable/getprojectstorages`,
            //             data: {ids: uniqueIds},
            //         }).then((response) => {
            //             this.setState({ loading: false });
            //             this.setProjectComponents(response)
            //         })
            //         .catch( (error) => {
            //             this.setState({ loading: false });
            //             errorPopper(error, tr('get_error'));
            //         })
            //     }
            // }
        }).catch( ( error ) => {
            if( axios.isCancel( error ) ) return null;
            this.setState({ loading: false });
            errorPopper(error, tr('get_error'));
        });

        if (this.state.projectStorageEnabled) {
            // const openProjectEntryIds = response.filter((entry) => {
            //     if (entry.status !== 'billed' && entry.status !== 'not_billable') return entry
            // }).map(entry => entry.project_id)

            // if (openProjectEntryIds.length > 0) {
            //     const onlyUnique = (value, index, self) => {
            //         return self.indexOf(value) === index
            //     }

            //     const uniqueIds = openProjectEntryIds.filter(onlyUnique)
            // }
            api({
                method: 'post',
                url: `billable/getprojectstorages`,
                data: {
                company: this.state.company.id,
                date_start: this.state.date_start,
                date_end: this.state.date_end
            },
            }).then((response) => {
                this.setState({ loading: false });
                this.setProjectComponents(response)
            })
                .catch((error) => {
                    this.setState({ loading: false });
                    errorPopper(error, tr('get_error'));
                })
        }

        this.setState({ poLoading: true });
        api({
            method: 'post',
            url: `billable/getbillablepurchaseorderrows`,
            data: {
                company:    this.state.company.id,
                date_start: this.state.date_start,
                date_end:   this.state.date_end
            },
        }).then((response) => {
            this.setState({ poLoading: false, purchaseOrderRows: response.data });
        })
        .catch( (error) => {
            this.setState({ poLoading: false });
            errorPopper(error, tr('get_error'));
        })
        
        this.setState({billedLoading: true})
        api({
            cancelToken: this.billedCancelToken.token,
            method: 'post',
            url: `billable/billed/get`,
            data: {
                company:    this.state.company.id,
                date_start: this.state.date_start,
                date_end:   this.state.date_end
            },
        }).then((response) => {
            const notBilledEntries = this.state.entries.filter((entry) => {
                if (entry.status !== 'billed' && entry.status !== 'not_billable') return entry
            })
            this.setState({ billedLoading: false, entries: [...notBilledEntries, ...response] });
        })
        .catch( (error) => {
            if( axios.isCancel( error ) ) return null;
            this.setState({ billedLoading: false });
            errorPopper(error, tr('get_error'));
        })
    }

    setPrimaryApprovers()
    {

        let entries = this.state.entries;
        for (var i = 0; i < entries.length; i++)
        {
            
            let entry = entries[i];

            if (entry.approver_id !== null) return

            let primaryApprover = null;
            
            // search for the primary approver
            for (var j = 0; j < entry.extranet_approvers.length; j++)
            {
                if (entry.extranet_approvers[j].is_primary)
                    primaryApprover = entry.extranet_approvers[j].crm_contact_id;
            }

            // if primary approver was found, set the entry approver value
            if (primaryApprover !== null)
                entry.approver_id = primaryApprover;

        }

        this.setState({ entries });

    }

    setFilter(id)
    {
        const newFilter = {...this.state.filter}

        newFilter[id] = !newFilter[id]

        this.saveFilterOptions(newFilter)
        this.setState({filter: newFilter})
    }

    saveFilterOptions(filter)
    {
        api({
            method: 'post',
            data: JSON.stringify(filter),
            url: 'billable/userfilter/save'
        }).then((response) => {
            //Just goes in the background
        })
    }

    setProjectComponents(data)
    {
        // data.forEach((project) => {
        //     const projectName = this.state.entries.find((entry) => entry.project_id === project.project_id)
        //     if (!projectName) return

        //     let approverId = null

        //     if (this.state.company.extranet_active) {
        //         let approver = projectName.extranet_approvers.find(approver => approver.is_primary === true)
                
        //         if (!approver) {
        //             if (projectName.extranet_approvers.length === 1) {
        //                 approverId = projectName.extranet_approvers[0].contact.id
        //             }
        //         } else {
        //             approverId = approver.contact.id
        //         }
        //     }

        //     project.balance.forEach((entry) => {
        //         entry.storageBalance = entry.balance > 0 ? entry.balance : 0
        //         entry.project_id = project.project_id
        //         entry.projectCode = project.project_code
        //         entry.projectName = projectName.project.name
        //         entry.extranet_approvers = this.state.company.extranet_active ? projectName.extranet_approvers : null
        //         entry.approver_id = approverId
        //         entry.crm_company_id = projectName.crm_company_id
        //         entry.status = 'open'
        //         entry.isProjectStorageComponent = true
        //         entry.balance_free = entry.balance_free > 0 ? entry.balance_free : 0
        //         entry.approval_exception = project.approval_exception
        //     })
            
        // })

        const componentRows = []

        data.forEach((project) => {
            // console.log({ balance: project.balance });

            let approverId = null

            if (this.state.company.extranet_active) {
                let approver = project.extranet_approvers.find(approver => approver.is_primary === true)

                if (!approver) {
                    if (project.extranet_approvers.length === 1) {
                        approverId = project.extranet_approvers[0].contact.id
                    }
                } else {
                    approverId = approver.contact.id
                }
            }
            project.balance.forEach((component) => {
                // component.storageBalance = component.balance > 0 ? component.balance : 0;
                // component.status = 'open';
                // component.isProjectStorageComponent = true
                // component.balance_free = entry.balance_free > 0 ? entry.balance_free : 0
                // component.approval_exception = project.approval_exception
                // component.crm_company_id = this.state.company.id

                
                
                component.storageBalance = component.balance > 0 ? component.balance : 0;
                component.project_id = project.project_id;
                component.projectCode = project.project_code;
                component.projectName = project.name;
                component.extranet_approvers = this.state.company.extranet_active ? project.extranet_approvers : null;
                component.approver_id = approverId;
                component.crm_company_id = this.state.company.id;
                component.status = 'open';
                component.isProjectStorageComponent = true
                component.balance_free = component.balance_free > 0 ? component.balance_free : 0
                component.approval_exception = project.approval_exception


                const hasComponent = componentRows.find(row => row.id === component.id);

                if (!hasComponent) {
                    component.allProjects = [
                        {
                            project_id: project.project_id,
                            projectCode: project.project_code,
                            projectName: project.project_name,
                            billingRows: component.billing_rows
                        }
                    ]
                    componentRows.push(component)
                } else {
                    hasComponent.allProjects.push({
                        project_id: project.project_id,
                        projectCode: project.project_code,
                        projectName: project.project_name,
                        billingRows: component.billing_rows
                    })
                }
            })
        })

        // console.log({ componentRows: componentRows });
        const filteredRows = componentRows.filter((row) => {
            let totalInBilling = 0

            row.allProjects.forEach((project) => {
                totalInBilling += project.billingRows
            })

            return row.storageBalance - totalInBilling > 0 
        })
        // console.log({ filteredRows: filteredRows });

        const sortedRows = _.orderBy(filteredRows, 'name', 'asc')

        sortedRows.forEach( (row, i) => {
            row.index = i
        })
        this.setState({projectComponents: sortedRows})
    }

    getFilterOptions()
    {
        //also fetches the info on whether or not the project storages are used by the company
        api({
            method: 'get',
            url: 'billable/userfilter',
            errorPopper: tr('get_error')
        }).then((response) => {
            this.setState({
                filter: JSON.parse(response.filters),
                projectStorageEnabled: response.project_storage_enabled,
                filtersFetched: true,
            }, this.getCompany)
        }).catch((error) => {
            console.log('failed fetching billing filters', error)
        })
    }

    refreshEntries()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'billable/refresh',
            errorPopper: tr('get_error'),
        }).then(( response ) => {
            //console.log('Refresh DONE', response );
            this.setState({
                loading: false, billingRowsUpdated:true,
            }, this.fetchData );
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    saveChangesWithEmail()
    {

        this.setState({ sendmail: true }, () => {
            this.saveChanges();
        });

    }

    resendEmail()
    {
        
        const statuses = this.getSectionStatuses();
        const entries = this.state.entries.filter( e => statuses.indexOf( e.status ) != -1 );
        const selected = this.state.selectedKeys;

        let approvers = [];
        selected.forEach( key => {
            if (!approvers.find(row => row === entries[ key ].approver_id)) {
                approvers.push({approver_id : entries[ key ].approver_id }) ;
            }
        });
        
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'billable/remindermail',
            data: { ids: approvers },
            errorPopper: tr('save_error'),
        }).then(( response ) => {
            //console.log('Save DONE', response );
            this.setState({
                loading: false,
                selected: [],
            }, this.fetchData );
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    saveChanges()
    {
        const changes = this.state.changes.filter((change) => !change.isProjectStorageComponent && !change.purchaseOrderRow)
        const components = this.state.projectComponents.filter((component) => component.status === 'pending' || component.status === 'approved' || component.status === 'not_billable')
        const purchaseOrderRows = this.state.purchaseOrderRows.filter((po) => po.status === 'pending' || po.status === 'approved' || po.status === 'not_billable')

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'billable/save',
            data: { changes: changes, sendmail: this.state.sendmail, newBillableComponents: components, purchaseOrderRows: purchaseOrderRows },
            errorPopper: tr('save_error'),
        }).then(( response ) => {
            //console.log('Save DONE', response );
            this.setState({
                loading: false,
                changes: [],
                sendmail: false
            }, this.fetchData );
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    cancelSelected()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'billable/cancel',
            data: { ids: this.getSelectedIds() },
            errorPopper: tr('save_error'),
        }).then(( response ) => {
            //console.log('Cancel DONE', response );
            this.setState({ loading: false }, this.fetchData );
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    createNewOrder()
    {
        this.setState({ newOrder: {
            show: true,
            customerId: this.state.company.id,
            type: 'no_delivery',
            components: this.getNewOrderComponents(),
        }});
    }

    getSelectedPrice()
    {
        const statuses = this.getSectionStatuses();
        const entries = this.state.entries.filter( e => statuses.indexOf( e.status ) != -1 );
        const selected = this.state.selectedKeys;

        let price = 0;
        selected.forEach( key => {
            if( key in entries )
            {
                let id = entries[ key ].id;
                const changes = this.state.changes.find( c => c.id === id );
                let amount = changes && "amount" in changes ? changes.amount : entries[ key ].amount;
                let unit_price = changes && "unit_price" in changes ? changes.unit_price : entries[ key ].unit_price;
                price += parseFloat( amount ) * parseFloat( unit_price );
            }     
        });

        return price;
    }

    getNewOrderComponents()
    {
        //if (!this.state.newOrder.show) return [];
        return this.getSelectedRows().map( r => r.id );
    }

    onCellEdit( field, rowIndex = false, isProjectStorageComponent = false )
    {
        const statuses = this.getSectionStatuses();
        
        let entries = this.state.entries.filter( e => statuses.indexOf( e.status ) != -1 );
        let indexes = rowIndex !== false ? [ rowIndex ] : this.state.selectedKeys;
        
        if (isProjectStorageComponent) { 
            entries = this.state.projectComponents.filter( e => statuses.indexOf( e.status ) != -1 );
            indexes = rowIndex !== false ? [ rowIndex ] : this.state.selectedComponentKeys;
        }
        
        

        let ids = [];
        let values = [];
        let options = [];

        indexes.forEach( index => {
            ids.push( entries[ index ].id );
            if( values.indexOf( entries[ index ][ field ] ) == -1 )
                values.push( entries[ index ][ field ] );
            
            if( field === "approver_id" )
            {
                entries[ index ].extranet_approvers.forEach( a => {
                    const optIndex = options.findIndex( o => o.value === a.crm_contact_id );
                    if( optIndex != -1 )
                        options[ optIndex ].count++;
                    else 
                        options.push({
                            value: a.crm_contact_id,
                            label: a.contact.name,
                            count: 1,
                        });
                });
            }   
        });

        const groupedOptions = groupify( options, "count" );
        options = [{ value: null, label: ` - ${tr('select').toUpperCase()} - ` }];
        Object.keys( groupedOptions ).forEach( count => {
            options.push({
                label: `${tr('editable_to_rows', [count, ids.length])}:`,
                options: groupedOptions[ count ],
            });
        });

        let info = false;
        let value = '';
        if( values.length == 1 && field != "approver_id" )
        {
            value = values[0];
            info = `${tr('current_value')}: ${values[0]}`;
        }
        else if ( values.length > 1 && field != "approver_id" )
            info = `${tr('current_values')}: ${values.join(", ")}`; // TODO: more readable format for numbers

        if( ids.length == 1 )
        {
            const changes = this.state.changes.find( c => c.id === ids[0] && field in c );
            if( changes )
                value = changes[ field ];
        }

        let valueRequired = true;

        if (field === 'dispatch_ref') {
            valueRequired=false;
        }
        else if (field === 'customer_work_number') {
            valueRequired = false;
        }

        let editModal = {
            show: true,
            ids: ids,
            field: field,
            value: value,
            info: info,
            options: options,
            required: valueRequired,
        };
        this.setState({ editModal })
    }

    onStorageComponentEdit(amount, index, type) {
        let editModal = {
            show: false,
            ids: false,
            field: null,
            value: null,
            info: false,
            options: [],
            required: true,
        };

        let valueRequired = true;
        if (type === 'customer_work_number') {
            valueRequired = false;
        }
        editModal.required = valueRequired;
   
        this.setState({
            editComponentModal: true,
            componentModalIndex: index,
            componentModalType: type,
            componentModalValue: Array.isArray(index) ? '' : amount,
            componentModalOldValue: amount,
            editModal: editModal,
        })
    }

    saveComponentModalChange(item) {
        
        if (item.toString().includes(',')) {
            item = item.toString().replace(',', '.')
        }
        
        const components = [...this.state.projectComponents]

        if (this.state.componentModalType === 'amount') {
            if (Array.isArray(this.state.componentModalIndex)) {
                this.state.componentModalIndex.forEach((index) => {
                    components[index].balance_free = item
                })
            } else {
                components[this.state.componentModalIndex].balance_free = item
            }
        }

        if (this.state.componentModalType === 'price') {
            if (Array.isArray(this.state.componentModalIndex)) {
                this.state.componentModalIndex.forEach((index) => {
                    components[index].single_price = item
                })
            } else {
                components[this.state.componentModalIndex].single_price = item
            }
        }

        if (this.state.componentModalType === 'customer_work_number') {
            if (Array.isArray(this.state.componentModalIndex)) {
                this.state.componentModalIndex.forEach((index) => {
                    components[index].customer_work_number = item
                })
            } else {
                components[this.state.componentModalIndex].customer_work_number = item
            }
        }

        this.setState({projectComponents: components})
    }

    onEditModalSave( value )
    {
        let changes = JSON.parse(JSON.stringify( this.state.changes ));
        
        if (this.state.editModal.field === "unit_price" || this.state.editModal.field === "amount") {
            value=value.toString().replace(",", ".");
        }
        this.state.editModal.ids.forEach( id => {
            const index = changes.findIndex( c => c.id === id );
            if( index != -1 )
                changes[ index ][ this.state.editModal.field ] = value;  
            else
                changes.push({
                    id: id,
                    [ this.state.editModal.field ]: value,
                });    
        });

        this.setState({ changes });
    }

    onEditModalClose()
    {
        let editModal = { ...this.state.editModal };
        editModal.show = false;
        this.setState({ editModal });
    }

    changeStatus( value, rowIndex = false, options = {} )
    {
        const statuses = this.getSectionStatuses();
        
        let entries = [] 
        
        if (options.isProjectStorageComponent) {
            entries = this.state.projectComponents;
        } else if (options.purchaseOrderRow) {
            entries = this.state.purchaseOrderRows;
        } else {
            entries = this.state.entries.filter( e => statuses.indexOf( e.status ) != -1 );
        }
        
        let indexes
        
        if (options.isProjectStorageComponent) {
            indexes = rowIndex !== false ? [ rowIndex ] : this.state.selectedComponentKeys;
        } else if (options.purchaseOrderRow) {
            indexes = rowIndex !== false ? [ rowIndex ] : this.state.selectedPoKeys;
        } else {
            indexes = rowIndex !== false ? [ rowIndex ] : this.state.selectedKeys;
        }

        let ids = [];
        indexes.forEach( index => {
            if (options.isProjectStorageComponent || options.purchaseOrderRow) {
                ids.push( entries[ index ].id );
                return
            }

            if( entries[ index ].status  ) {
                ids.push( entries[ index ].id );
            }
        });
        
        let changes = JSON.parse(JSON.stringify( this.state.changes ));
        ids.forEach( id => {
            const index = changes.findIndex( c => c.id === id );
            if( index != -1 )
                changes[ index ].status = value;
            else 
                changes.push({
                    id: id,
                    status: value,
                    ...options
                });
        });

        if (options.isProjectStorageComponent) {
            const components = [...this.state.projectComponents]
            changes.forEach((row) => {
                // row.isProjectComponent = true
                const componentIndex = components.findIndex(component => row.id === component.id)
                if (componentIndex === -1) return
                components[componentIndex].status = row.status
            })
        }
        if (options.purchaseOrderRow) {
            const pos = [...this.state.purchaseOrderRows]
            changes.forEach((row) => {
                const poIndex = pos.findIndex(po => row.id === po.id)
                if (poIndex === -1) return
                pos[poIndex].status = row.status
            })
        }

        this.setState({ changes });
    }

    onStatusClick( index, currentStatus, options = {} )
    {
        let statuses = [];
        if( this.state.section === 0 )
        {
            statuses.push( "open" );
            statuses.push(  this.state.company.extranet_active ? "pending" : "approved" );
            statuses.push( "not_billable" );
        }
        else if( this.state.section === 1 )
        {   
            statuses.push( "rejected" );
            statuses.push( "pending_again" );
            statuses.push( "not_billable" );
        }
        else if (this.state.section === 3 )
        {
            statuses.push( "not_billable" );
            statuses.push( "open" );

            if( auth.isAdmin() )
                statuses.push( "archived" );
        }

        const statusIndex = statuses.findIndex( s => s === currentStatus );
        const nextStatus = statusIndex < (statuses.length - 1) ? statuses[ statusIndex + 1 ] : statuses[ 0 ];

        this.changeStatus( nextStatus, index, options );
    }

    onApproverChange( id, value )
    {
        let changes = JSON.parse(JSON.stringify( this.state.changes ));
        const index = changes.findIndex( c => c.id === id );   
        if( index != -1 )
            changes[ index ].approver_id = value;  
        else
            changes.push({
                id: id,
                approver_id: value,
            });   
            
        this.setState({ changes });
    }

    onPoApproverChange( id, value ) {
        let purchaseOrders = JSON.parse(JSON.stringify( this.state.purchaseOrderRows ));
        const index = purchaseOrders.findIndex( c => c.id === id );
        if( index != -1 ) {
            purchaseOrders[ index ].approver_id = value;
            this.setState({ purchaseOrderRows: purchaseOrders});
        }
        
    }

    onComponentApproverChange( id, value )
    {
        const components = [...this.state.projectComponents]

        const index = components.findIndex( c => c.id === id );   
        if( index != -1 )
            components[ index ].approver_id = value;  

        this.setState({projectComponents: components})
    }

    editableCellAccessor( data, column, isNumber = false )
    {
        const changes = this.state.changes.find( c => c.id === data.id && column in c );
        let value = data[ column ];

        if( changes )
            value = changes[ column ];

        if( isNumber )
            value = parseFloat( value );

        return value;
    }

    countEntries()
    {
        const statuses = this.getSectionStatuses();
        return this.state.entries.filter( e => statuses.indexOf( e.status ) != -1 ).length;
    }


    //Duplicate 11.4.22
    // renderStorageComponentEntries()
    // {

    //     const data = this.state.projectComponents

    //     if (data.length==0) return null

    //     const columns = [
    //         {
    //             id: 'material',
    //             Header: tr("material"),
    //             //accessor: 'material',
    //             Cell: row => {
    //                 return (
    //                     <span>
    //                         <SvgIcon className="small-inline" icon='puzzle-piece' type="solid" />
    //                         <strong>{ row.original.name }</strong><br />
    //                         <small>{tr("storage")}: { row.original.location.code }, {row.original.location.name}</small>
    //                     </span>
    //                 )
    //             }
    //         },
    //         {
    //             id: 'project',
    //             Header: tr("project"),
    //             width: 150,
    //             //accessor: 'project.project_code',
    //             Cell: row => {
    //                 return (
    //                     <span>
    //                         <strong>{ row.original.projectCode }</strong><br />
    //                         <small>{ row.original.projectName }</small>
    //                     </span>
    //                 );
    //             },
    //         },
    //         {
    //             id: 'billed',
    //             width: 100,
    //             Header: tr("without_billing"),
    //             /*accessor: (d) => {
    //                 let amount = this.editableCellAccessor( d, "amount", true );
    //                 let unit_price = this.editableCellAccessor( d, "unit_price", true );
    //                 return amount * unit_price;
    //             }, */
    //             Cell: row => {
    //                 return (
    //                     <span>
    //                         {Number(row.original.storageBalance) - (Number(row.original.in_billing) + Number(row.original.not_billable))} {row.original.unit}
    //                     </span>
    //                 )
    //             },
    //             align: 'right',
    //         },
    //         {
    //             id: 'free',
    //             width: 120,
    //             Header: tr("will_be_billed"),
    //             accessor: (d) => this.editableCellAccessor( d, "amount", true ), 
    //             Cell: row => {
    //                 return this.renderEditableCell( 
    //                     {original: 
    //                         {
    //                             amount: row.original.balance_free, 
    //                             total: row.original.balance_free,
    //                             unit: row.original.unit, 
    //                             index: row.original.index, 
    //                             type: 'amount',
    //                             storageComponent: true 
    //                         }}, "amount" )},
    //             align: 'center',
    //         },
    //         {
    //             id: 'unit_price',
    //             width: 120,
    //             Header: tr("unit_price"),
    //             accessor: (d) => this.editableCellAccessor( d, "amount", true ), 
    //             Cell: row => {
    //                 return this.renderEditableCell( 
    //                     {original: 
    //                         {
    //                             amount: Number(row.original.single_price).toFixed(2).replace('.', ','), 
    //                             unit: auth.getCurrencySign(), 
    //                             index: row.original.index,
    //                             type: 'price',
    //                             storageComponent: true 
    //                         }}, "amount" )},
    //             align: 'center',
    //         },
    //         {
    //             id: 'total_price',
    //             width: 100,
    //             Header: tr("price"),
    //             Cell: row => {
    //                 return (
    //                     <span>
    //                         {Number(row.original.single_price * row.original.balance_free).toFixed(2).replace('.', ',')} {auth.getCurrencySign()}
    //                     </span>
    //                 )
    //             },
    //             align: 'right',
    //         },
    //     ]

    //         if (this.state.company.extranet_active) {
    //             columns.push({
    //             id: 'approver',
    //             Header: tr("approver"),
    //             accessor: 'approver_id',
    //             width: 150,
    //             Cell: row => {
    //                 if( this.state.section === 0 ||
    //                     this.state.section === 1 && row.original.status === "rejected" )
    //                 {
    //                     let approvers = [{ value: null, label: "- VALITSE -", primary: false }];
    //                     if (row.original.extranet_approvers) {
    //                         row.original.extranet_approvers.forEach( a => {
    //                             approvers.push({
    //                                 value: a.crm_contact_id,
    //                                 label: a.contact.name,
    //                             });
    //                         });
    //                     }
                        
    //                     const changes = this.state.changes.find( c => c.id === row.original.id && "approver_id" in c );
    //                     const value = changes ? changes.approver_id : row.original.approver_id;
                        
    //                     return (
    //                         <div className="approverSelect">
    //                             <ApInput
    //                                 type="select"
    //                                 id={ "approver" + row.index }
    //                                 name="approver_id"
    //                                 value={ value }
    //                                 onChange={ (e) => this.onApproverChange( row.original.id, e.target.value ) }
    //                                 options={ approvers }
    //                                 validationState={ !value ? "error" : changes ? "warning" : "" }
    //                                 loading={ this.state.loading }
    //                                 disabled={ this.state.loading }
    //                             />
    //                         </div>
    //                     ); 
    //                 }
                    
    //                 // When not editable, just show approver name
    //                 const approver = row.original.extranet_approvers.find( a => a.crm_contact_id === row.original.approver_id );
    //                 return approver ? approver.contact.name : "-";
    //             }
    //             })
    //         }

    //         columns.push({
    //             id: 'status',  
    //             width: 150,
    //             resizable: false,                     
    //             Header: tr("status"),
    //             accessor: (d) => this.editableCellAccessor( d, "status" ),
    //             Cell: this.renderRowStatus
    //         })
        
            
    //     return (
    //         <ApReactTable
    //             key='billing-components-storage'
    //             ref={ node => this.reactTable = node }
    //             onSelectChange={ ( keys ) => { this.setState({ selectedComponentKeys: keys  }) } }
    //             columns={[{
    //                 Header: () => {
                        
    //                     if( this.state.section != 3 || !auth.isAdmin() )
    //                         return false;

    //                     return (
    //                         <div className="tableHeader">
    //                             <div className="toggleSwitch">
    //                                 <label className="switchLabel" htmlFor="show-archived-switch">
    //                                     {tr("show_archived")}
    //                                 </label>
    //                                 <ApSwitch
    //                                     small
    //                                     id="show-archived-switch"
    //                                     on={ this.state.showArchived }
    //                                     onChange={ ( e ) => this.setState({ showArchived: e.target.checked }) }
    //                                     disabled={ this.state.loading }
    //                                 />
    //                             </div>
    //                         </div>
    //                     );
    //                 },
    //                 columns: columns
    //             }]}
    //             data={ data }
    //             loading={ this.state.loading }
    //             filterable
    //             multiselect={ this.getComponentSectionActions() }
    //         />
    //     );
    // }

    renderStorageComponentEntries()
    {

        const data = this.state.projectComponents

        if (!data) return null

        const columns = [
            {
                id: 'material',
                Header: tr('material'),
                //accessor: 'material',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.name",
                        "_original.location.code",
                        "_original.location.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {
                    return (
                        <span>
                            <SvgIcon className="small-inline" icon='puzzle-piece' type="solid" />
                            <strong>{ row.original.name }</strong><br />
                            <small>{ tr('storage') }: { row.original.location.code }, {row.original.location.name}</small>
                        </span>
                    )
                }
            },
            {
                id: 'project',
                Header: tr('project'),
                width: 150,
                accessor: 'projectCode',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.projectCode",
                        "_original.projectName",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {
                    if (row.original.allProjects.length <= 1) {
                        return (
                            <span>
                                <strong>{ row.original.projectCode }</strong><br />
                                <small>{ row.original.projectName }</small>
                            </span>
                        )
                    }

                    const onProjectChange = (projectId) => {
                        const oldComponents = [...this.state.projectComponents]
                        const modifyItem = oldComponents.find(component => component.id === row.original.id)
                        const modifyData = modifyItem.allProjects.find((project) => Number(project.project_id) === Number(projectId))

                        modifyItem.project_id = modifyData.project_id
                        modifyItem.projectCode = modifyData.projectCode
                        modifyItem.projectName = modifyData.projectName

                        const newComponents = this.state.projectComponents.map((component) => {
                            if (component.id === row.original.id) return modifyItem

                            return component
                        })

                        this.setState({
                            projectComponents: newComponents
                        })
                    }
                    
                    const options = []
                    row.original.allProjects.forEach((project) => {
                        options.push({
                            value: project.project_id,
                            componentId: row.original.id,
                            label: `${project.projectCode}: ${project.projectName}`,
                        });
                    })

                    return (
                        <div className="approverSelect">
                            <ApInput
                                type="select"
                                id={ "project" + row.index }
                                name="project_selection"
                                value={ row.original.project_id }
                                onChange={ (e) => onProjectChange(e.target.value) }
                                options={ options }
                                //validationState={ !value ? "error" : changes ? "warning" : "" }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                            />
                        </div>
                    );
                },
            },
            {
                id: 'customer_work_number',
                Header: tr('customer_work_number'),
                accessor: (d) => this.editableCellAccessor(d, "customer_work_number"), 
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.customer_work_number",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {
                    return this.renderEditableCell({
                        original: {
                            amount: row.original.customer_work_number || '',
                            customer_work_number: row.original.customer_work_number || '',
                            unit: '',
                            index: row.original.index,
                            type: 'customer_work_number',
                            storageComponent: true
                        }
                    }, "customer_work_number", true)
                },
            },
            {
                id: 'billed',
                width: 100,
                Header: tr('without_billing'),
                /*accessor: (d) => {
                    let amount = this.editableCellAccessor( d, "amount", true );
                    let unit_price = this.editableCellAccessor( d, "unit_price", true );
                    return amount * unit_price;
                }, */
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.storageBalance",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {

                    return (
                        <span>
                            {row.original.storageBalance} {row.original.unit}
                        </span>
                    )
                },
                align: 'right',
            },
            {
                id: 'free',
                width: 120,
                Header: tr('will_be_billed'),
                accessor: (d) => this.editableCellAccessor(d, "amount", true), 
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.balance_free",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {
                    return this.renderEditableCell( 
                        {original: 
                            {
                                amount: row.original.balance_free, 
                                total: row.original.balance_free,
                                unit: row.original.unit, 
                                index: row.original.index, 
                                type: 'amount',
                                storageComponent: true 
                            }}, "amount" )},
                align: 'center',
            },
            {
                id: 'unit_price',
                width: 120,
                Header: tr('unit_price'),
                accessor: (d) => this.editableCellAccessor(d, "amount", true), 
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.single_price",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {
                    return this.renderEditableCell( 
                        {original: 
                            {
                                amount: Number(row.original.single_price).toFixed(2).replace('.', ','), 
                                unit: auth.getCurrencySign(), 
                                index: row.original.index,
                                type: 'price',
                                storageComponent: true 
                            }}, "amount" )},
                align: 'center',
            },
            {
                id: 'total_price',
                width: 100,
                Header: tr('price'),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        row => row._original.single_price * row._original.balance_free
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {
                    return (
                        <span>
                            {Number(row.original.single_price * row.original.balance_free).toFixed(2).replace('.', ',')} {auth.getCurrencySign()}
                        </span>
                    )
                },
                align: 'right',
            },
            colPreset({
                id: 'purchase_price',
                accessor: 'purchase_price',
                type: 'currency',
                width: 100,
                Header: tr('purchase_price'),
                align: 'right',
            }),
        ]

            if (this.state.company.extranet_active) {
                columns.push({
                id: 'approver',
                Header: tr('approver'),
                accessor: 'approver_id',
                width: 150,
                filterable:false,
                Cell: row => {
                    if( this.state.section === 0 ||
                        this.state.section === 1 && row.original.status === "rejected" )
                    {

                        if (row.original.extranet_approvers && row.original.extranet_approvers.length === 0 && row.original.approval_exception ) return null

                        let approvers = [{ value: null, label: `- ${tr('select').toUpperCase()} -`, primary: false }];
                        if (row.original.extranet_approvers) {
                            const filteredApprovers = row.original.extranet_approvers.filter( a => a.contact && a.contact.deleted_at === null );
                            filteredApprovers.forEach( a => {
                                approvers.push({
                                    value: a.crm_contact_id,
                                    label: a.contact.name,
                                });
                            });
                        }
                        
                        const changes = this.state.changes.find( c => c.id === row.original.id && "approver_id" in c );
                        const value = changes ? changes.approver_id : row.original.approver_id;

                        return (
                            <div className="approverSelect">
                                <ApInput
                                    type="select"
                                    id={ "approver" + row.index }
                                    name="approver_id"
                                    value={ value }
                                    onChange={ (e) => this.onComponentApproverChange( row.original.id, e.target.value ) }
                                    options={ approvers }
                                    validationState={ !value ? "error" : changes ? "warning" : "" }
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading }
                                />
                            </div>
                        ); 
                    }
                    
                    // When not editable, just show approver name
                    const approver = row.original.extranet_approvers.find( a => a.crm_contact_id === row.original.approver_id );
                    return approver ? approver.contact.name : "-";
                }
                })
            }

            columns.push({
                id: 'status',  
                width: 150,
                resizable: false,                     
                Header: tr('status'),
                filterable: false,
                accessor: (d) => this.editableCellAccessor( d, "status" ),
                Cell: this.renderRowStatus
            })
        

        return (
            <ApReactTable
                key='billing-components-storage'
                ref={ node => this.reactTable = node }
                onSelectChange={ ( keys ) => { this.setState({ selectedComponentKeys: keys  }) } }
                columns={[{
                    Header: () => {
                        
                        if( this.state.section != 3 || !auth.isAdmin() )
                            return false;

                        return (
                            <div className="tableHeader">
                                <div className="toggleSwitch">
                                    <label className="switchLabel" htmlFor="show-archived-switch">
                                        { tr('show_archived') }
                                    </label>
                                    <ApSwitch
                                        small
                                        id="show-archived-switch"
                                        on={ this.state.showArchived }
                                        onChange={ ( e ) => this.setState({ showArchived: e.target.checked }) }
                                        disabled={ this.state.loading }
                                    />
                                </div>
                            </div>
                        );
                    },
                    columns: columns
                }]}
                data={ data }
                loading={ this.state.loading }
                filterable
                multiselect={ this.getComponentSectionActions() }
            />
        );
    }

    renderEntries(billedEntries = false)
    {
        const statuses = this.getSectionStatuses(); 
        let data = this.state.entries.filter( e => statuses.indexOf( e.status ) != -1 );

        let columns = [
            {
                id: 'type',
                Header: tr('type'),
                accessor: 'type',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.description",
                        "_original.date",
                        "_original.user.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {
                    let icon = "exclamation-triangle";
                    if( row.original.type === 'timetracking_hour' )
                        icon = "clock";
                    else if( row.original.type === 'timetracking_travel' )
                        icon = "car";
                    else if( row.original.type === 'timetracking_travel_hours' )
                        icon = "route";
                    else if( row.original.type === 'timetracking_expense' )
                        icon = "money-bill-wave";
                    else if( row.original.type === 'timetracking_allowance' )
                        icon = "suitcase";
                    else if( row.original.type === 'project_chargeable' )
                        icon = "money-bill-wave";
                    else if( row.original.type === 'project_installation' )
                        icon = "puzzle-piece";
                    else if (row.original.type === 'purchase_order_expense')
                        icon = "money-bill-wave";
                    else if (row.original.type === 'contract_billing')
                        icon = "redo";

                    return (
                        <span>
                            <SvgIcon className="small-inline" icon={ icon } type="solid" />
                            <strong>{ row.original.description || "Ei kuvausta" }</strong><br />
                            <small>{ sqlToDateInput( row.original.date ) } - { row.original.user.name }</small>
                        </span>
                    );
                },
            }, 
            {
                id: 'project',
                Header: tr('project'),
                accessor: 'project.project_code',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.project.project_code",
                        "_original.project.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {
                    return (
                        <span>
                            <strong>{ row.original.project.project_code }</strong><br />
                            <ApTooltip text={row.original.project?.project_search?.full_name}><small>{ row.original.project.name }</small></ApTooltip>
                        </span>
                    );
                },
				className: "overflowableOnHover",
            },
        ]

        if(statuses.includes('open')) {
            columns.push({
                id: 'assignment_status',
                Header: tr('assignment'),
                accessor: 'assignment_status', 
                width: 50,
                className: 'overflowableOnHover approverComment',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.assignment.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Cell: row => {

                    if (row.original.assignments===undefined || !row.original.assignments) {
                        return null
                    }

                    let unfinishedAssignments = false
                    let onlyOneAssignment = false
                    let recurringAssignments = row.original.assignments.open_recurring_assignments > 0;

                    if (row.original.assignment) {
                        if (!row.original.assignment.is_ready) unfinishedAssignments = true
                    }

                    if (row.original.assignments) {
                        if(row.original.assignments.open_assignments > 0) unfinishedAssignments = true

                        if(row.original.assignments.open_assignments === 1 && !row.original.assignment) onlyOneAssignment = true
                    }
                    
                    const message = <div>
                        {row.original.assignment && <div>
                            <b>{ tr('assignment_in_progress') }:</b><br /> {row.original.assignment.name} <br/>
                            <b>{ tr('status') }:</b> {row.original.assignment.is_ready ? tr('completed') : tr('in_progress')}
                        </div>}

                        {row.original.assignments.open_assignments > 0 && <div style={{margin: '10px 0 10px 0'}}>
                            <b>{ tr('open_assignments_project') }: </b> {row.original.assignments.open_assignments} kpl <br/>
                            {row.original.assignments.open_recurring_assignments > 0 && 
                            <><b>{tr('recurring_assignments')}</b>: {row.original.assignments.open_recurring_assignments}</>}
                        </div>}
                    </div>

                    return (
                        <ApTooltip text={message}>
                            {unfinishedAssignments ? 
                                <SvgIcon className='hover' icon='tasks' type="solid" fill={recurringAssignments ? 'var(--clr-success-main)' :'gray'} 
                                    // onClick Pois käytöstä koska funktio aiheutti ongelmia kun on useampi kuin 1 työmääräys
                                    // onClick={() => {
                                    //     if (onlyOneAssignment) {
                                    //         this.goToAssignments(row.original.assignments.assignment_id_if_one)
                                    //         return
                                    //     }
                                    //     this.goToAssignments(row.original.assignment.id)
                                    // }} 
                                /> :
                                <SvgIcon className='hover' icon='check-circle' type="solid" fill='green' 
                                    // onClick={() => this.goToAssignments(row.original.assignment.id)} 
                                />  }
                            
                        </ApTooltip>
                    );
                },
            })
        }


        ////////////////////////////////
        // using "dispatch_ref" even we have "entry_description" because if we want in the future use entry_description without query
        // entry_description is allways but dispatch_ref only when user want it
        ////////////////////////////////
        if (this.props.apOrderSettings?.show_in_timetracking_description_to_billable) {
            columns.push(
                {
                    id: 'entry_description',
                    Header: tr('entry_description'),
                    accessor: (d) => this.editableCellAccessor(d, "dispatch_ref"),
                    Cell: row => this.renderEditableCell(row, "dispatch_ref"),
    
                },
            );
        }
         
        columns.push(
            {
                id: 'customer_work_number',
                Header: tr('customer_work_number'),
                accessor: (d) => this.editableCellAccessor( d, "customer_work_number" ), 
                Cell: row => this.renderEditableCell(row, "customer_work_number"),
                
            },
            {
                id: 'amount',
                width: 100,
                Header: tr('amount'),
                accessor: (d) => this.editableCellAccessor( d, "amount", true ), 
                Cell: row => this.renderEditableCell( row, "amount" ),
                align: 'center',
            },
            {
                id: 'unit_price',
                width: 100,
                Header: tr('unit_price'),
                accessor: (d) => this.editableCellAccessor( d, "unit_price", true ), 
                Cell: row => this.renderEditableCell( row, "unit_price" ),
                align: 'center',
            },
            {
                id: 'total_price',
                width: 100,
                Header: tr('price'),
                accessor: (d) => {
                    let amount = this.editableCellAccessor( d, "amount", true );
                    let unit_price = this.editableCellAccessor(d, "unit_price", true);
                    return amount * unit_price;
                }, 
                Cell: row => formatMoney(row.value, 2) + " " + auth.getCurrencySign(),
                align: 'right',
            }
        )

        if( this.state.company.extranet_active )
        {
            if( this.state.section === 1 )
                columns.push({
                    id: 'approver_comment',
                    Header: '',
                    accessor: 'approver_comment',
                    width: 50,
                    className: 'overflowableOnHover approverComment',
                    filterable: false,
                    Cell: row => {

                        if( row.original.status != "rejected" )
                            return false;
                        
                        const approver = row.original.extranet_approvers.find( a => a.crm_contact_id === row.original.approver_id );
                        
                        let icon = "times";
                        let comment = (
                            <div>
                                <strong>{ approver ? approver.contact.name : '-' }</strong><br />
                                <small>{ timeSince( row.original.status_changed_at ) }</small>
                            </div>
                        ); 

                        if( row.original.approver_comment )
                        {
                            icon = "comment";
                            comment = (
                                <div>
                                    <strong>{ approver ? approver.contact.name : '-' }</strong><br />
                                    <small>{ timeSince( row.original.status_changed_at ) }</small>
                                    <hr />
                                    { row.original.approver_comment }
                                </div>
                            ); 
                        }
                                
                        return (
                            <ApTooltip text={ comment }>
                                <SvgIcon icon={ icon } type="solid" />
                            </ApTooltip>
                        );
                    }
                })

            columns.push({
                id: 'approver',
                Header: tr('approver'),
                accessor: 'approver_id',
                width: 150,
                filterable: false,
                Cell: row => {

                    if( this.state.section === 0 ||
                        this.state.section === 1 && row.original.status === "rejected" )
                    {

                        if (row.original.extranet_approvers.length === 0 && row.original.project.approval_exception ) return null

                        let approvers = [{ value: null, label: `- ${tr('select').toUpperCase()} -`, primary: false }];
                        const filteredApprovers = row.original.extranet_approvers.filter( a => a.contact && a.contact.deleted_at === null );
                        filteredApprovers.forEach( a => {
                            approvers.push({
                                value: a.crm_contact_id,
                                label: a.contact.name,
                            });
                        });
                        
                        const changes = this.state.changes.find( c => c.id === row.original.id && "approver_id" in c );
                        let value = changes ? changes.approver_id : row.original.approver_id;

                        /*
                        currently removed. changes handles updating data on backend ja is responsible for locking
                        everything in front. we don't want to lock everything in componentMount

                        if (value === null && row.original.extranet_approvers.length === 1) {
                            value = row.original.extranet_approvers[0].contact.id
                            this.onApproverChange( row.original.id, value )
                        }
                        */
                        
                        return (
                            <div className="approverSelect">
                                <ApInput
                                    type="select"
                                    id={ "approver" + row.index }
                                    name="approver_id"
                                    value={ value }
                                    onChange={ (e) => this.onApproverChange( row.original.id, e.target.value ) }
                                    options={ approvers }
                                    validationState={ !value ? "error" : changes ? "warning" : "" }
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading }
                                />
                            </div>
                        ); 
                    }
                    
                    // When not editable, just show approver name
                    const approver = row.original.extranet_approvers.find( a => a.crm_contact_id === row.original.approver_id );
                    return approver ? approver.contact.name : "-";
                }
            });
        }

        columns.push({
            id: 'status',  
            width: 150,
            resizable: false,                     
            Header: tr('status'),
            accessor: (d) => this.editableCellAccessor( d, "status" ),
            Cell: this.renderRowStatus,
            filterable:false,
        })

        if (this.state.section === 0) {
            columns.push({
                id: 'notifications',
                Header: tr('notifications'),
                accessor: 'notifications',
                className: 'overflowableOnHover',
                width: 50,
                filterable: false,
                Cell: row => {
                    if (!row.original.open_purchase_orders && 
                        (!this.state.company.extranet_active || 
                            row.original.extranet_approvers.length !== 0 || 
                            (this.state.company.extranet_active && 
                                row.original.extranet_approvers.length === 0 && 
                                row.original.project.approval_exception)
                            ) &&
                            !row.original.price_may_not_be_set) {
                        return null
                    }

                    const text = <div>
                            {row.original.open_purchase_orders &&
                                <div>
                                    <div><b>{ tr('purchase_orders') }</b></div>
                                    <div>{ tr('project_open_purchase_orders') }</div>
                                </div>
                            }

                            {(this.state.company.extranet_active && row.original.extranet_approvers.length === 0 && !row.original.project.approval_exception) &&
                                <div>
                                    <div><b>{ tr('approvers') }</b></div>
                                    <div>{ tr('no_approvers_defined') }</div>
                                </div>
                            }

                            {row.original.price_may_not_be_set &&
                                <div>
                                    <div><b>{ tr('component') }</b></div>
                                    <div>
                                        <p>{ tr('component_price_info1') }</p>
                                        <p>{ tr('component_price_info2') }</p>
                                    </div>
                                </div>
                            }
                    </div>
                    
                    return (
                        <ApTooltip position='left' text={ text }>
                            <SvgIcon icon="check-circle" type="solid" fill='red'/>
                        </ApTooltip>
                        
                    );
                },
            })
        }
        
        return (
            <ApReactTable
                key={ this.state.section }
                ref={ node => this.reactTable = node }
                onSelectChange={ ( keys ) => {
                    this.setState({ selectedKeys: keys  }, () => {
                        if (this.state.entries[keys]) { this.setState({ selectedProject: this.state.entries[keys].project.id}); }
                    });
                } }
                defaultSorted={ [ { id: 'date', desc: true } ] }
                columns={[{
                    Header: () => {
                        
                        if( this.state.section != 3 || !auth.isAdmin() )
                            return false;

                        return (
                            <div className="tableHeader">
                                <div className="toggleSwitch">
                                    <label className="switchLabel" htmlFor="show-archived-switch">
                                        { tr('show_archived') }
                                    </label>
                                    <ApSwitch
                                        small
                                        id="show-archived-switch"
                                        on={ this.state.showArchived }
                                        onChange={ ( e ) => this.setState({ showArchived: e.target.checked }) }
                                        disabled={ this.state.loading }
                                    />
                                </div>
                            </div>
                        );
                    },
                    columns: columns
                }]}
                data={ data }
                loading={ billedEntries ? this.state.billedLoading : this.state.loading }
                filterable
                multiselect={ this.getSectionActions() }
            />
        );
    }

    saveStartDate(date)
    {
        rememberStartDate(date)
    }

    saveEndDate(date)
    {
        rememberEndDate(date)
    }

    saveBoth(start, end)
    {
        rememberStartDate(start)
        rememberEndDate(end)
    }

    renderEditableCell( data, col )
    {
        const row = data.original;

        let classes = [];
        let onClick = () => false;

        let value = '';
        if( col in row )
            value = row[ col ];

        if( this.state.section === 0 ||
            this.state.section === 1 && row.status === "rejected" )
        {
            classes.push( "editableCell" );

            if (data.original.storageComponent) {
                onClick = () => this.onStorageComponentEdit(data.original.amount, data.original.index, data.original.type)
            } else if (data.original.purchaseOrderRow) {
                onClick = () => this.onPoRowEdit(data.original[col], data.index, col)
            } else {
                onClick = () => this.onCellEdit( col, data.index );
            }

            const changes = this.state.changes.find( c => c.id === row.id && col in c );
            if( changes )
            {
                classes.push( "haveChanges" );
                value = changes[ col ];
            }
        }

        if (col === "unit_price" || col === 'price_sell')
            value = !value ? '0 €' : formatMoney(value.toString().replace(",", "."), 2) + " " + auth.getCurrencySign();

        else if (col === "amount") {
            value = value.toString().replace(".", ",");
            if (row.unit) {
                value += " " + row.unit;
            }
            
        }

        return (
            <div className={ classes.join(" ") } onClick={ onClick }>
                { value }
                <SvgIcon className="indicator" icon="pen" type="solid" />
            </div>
        );
    }

    renderRowStatus( row )
    {
        let statuses;
        
        if( this.state.section === 0 )
            statuses = {
                open:           { name: tr('open2'), icon: "question", class: "" },
                pending:        { name: tr('to_approval'), icon: "arrow-right", class: "success" },
                approved:       { name: tr('billable'), icon: "check", class: "success" },
                not_billable:   { name: tr('not_billed'), icon: "ban", class: "disabled" },
            };

        else if ( this.state.section === 1 )
            statuses = {
                pending:        { name: tr('waiting'), icon: "lock", class: "warning" },
                pending_again:  { name: tr('redo'), icon: "redo", class: "success" },
                rejected:       { name: tr('open2'), icon: "question", class: "" },
                not_billable:   { name: tr('not_billed'), icon: "ban", class: "disabled" },
            };
        
        else if ( this.state.section === 2 )
            statuses = {
                approved:       { name: tr('open2'), icon: "question", class: "" },
                not_billable:   { name: tr('not_billed'), icon: "ban", class: "disabled" },
            };
        
        else if ( this.state.section === 3 )
            statuses = {
                open:           { name: tr('return'), icon: "arrow-left", class: "success" },
                not_billable:   { name: tr('not_billed'), icon: "ban", class: "" },
                archived:       { name: tr('archived'), icon: "archive", class: "disabled" },
            };

        else if ( this.state.section === 4 )
            statuses = {
                billed:         { name: tr('billed'), icon: "file-invoice-dollar", class: "info" },
            };

        let classes = [ "apStatusBox" ];
        let value = row.value;
        let icon = false;
        let onClick = () => null;

        if( row.value in statuses )
        {
            classes.push( statuses[ row.value ].class );
            value = statuses[ row.value ].name;
            icon = statuses[ row.value].icon;
        }

        if( this.state.section === 0 || 
            this.state.section === 1 || 
            this.state.section === 3 )
        {
            classes.push( "editable" );
            if (row.original.isProjectStorageComponent) {
                onClick = () => this.onStatusClick( row.index, row.value, {isProjectStorageComponent: true} );
            } else if (row.original.purchaseOrderRow) {
                onClick = () => this.onStatusClick( row.index, row.value, {purchaseOrderRow: true} );
            } else {
                onClick = () => this.onStatusClick( row.index, row.value );
            }

            const changes = this.state.changes.find( c => c.id === row.original.id && "status" in c );
            if( changes )
                classes.push( "haveChanges" );
        }

        return (
            <div className={ classes.join(" ") } onClick={ onClick }>
                { icon && <SvgIcon icon={ icon } type="solid" /> }
                { value }
            </div>
        );
    }

    renderProjectStorage()
    {
        return this.state.projectStorageEnabled && <div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: '4em'}}>
                <SvgIcon icon="box" type="solid" style={{marginRight: '10px'}}/>
                <h4>{ tr('project_storages') }</h4>
            </div>
            <div style={{margin: '0 0 1.5em 0'}}>
                <small>{ tr('project_storages_info') }</small>
            </div>

            { this.renderStorageComponentEntries() }
        </div>
    }

    renderPurchaseOrders() {
        return <div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: '4em'}}>
                <SvgIcon icon="clipboard-check" type="solid" style={{marginRight: '10px'}}/>
                <h4>{ tr('purchase_orders') }</h4>
            </div>
            <div style={{margin: '0 0 1.5em 0'}}>
                <small>{tr('billable_po_info')}</small>
            </div>

            { this.renderPurchaseOrderRows() }
        </div>
    }

    renderPurchaseOrderRows() {
        const data = this.state.purchaseOrderRows
        if (!data) return null

        const columns = [
            {
                id: 'po_number',
                Header: tr('po_number'),
                accessor: 'po_number',
            },
            {
                id: 'name',
                Header: tr('name'),
                accessor: 'name',
                Cell: (row) => {
                    return <div><SvgIcon type='solid' icon={row.original.type_id === 2 ? 'user' : 'money-bill-wave'} /> {row.value}</div>
                }
            },
            {
                id: 'customer_work_number',
                Header: tr('customer_work_number'),
                accessor: 'customer_work_number',
                Cell: (row) => {
                    row.original.purchaseOrderRow = true;
                    return this.renderEditableCell(row, 'customer_work_number')
                }
            },
            colPreset({
                type: 'number',
                id: 'count',
                Header: tr('without_billing'),
                accessor: 'count_original',
                unit: row => row.unit,
            }),
            {
                id: 'will_be_billed',
                Header: tr('will_be_billed'),
                accessor: 'count',
                filterable: false,
                unit: row => row.unit,
                align: 'center',
                width: 125,
                Cell: (row) => {
                    row.original.purchaseOrderRow = true;
                    return this.renderEditableCell(row, 'count')
                }
            },
            colPreset({
                type: 'currency',
                id: 'price_sell',
                Header: tr('unit_price'),
                accessor: 'price_sell',
                width: 125,
                Cell: (row) => {
                    row.original.purchaseOrderRow = true;
                    return this.renderEditableCell(row, 'price_sell')
                }
            }),
            colPreset({
                type: 'currency',
                id: 'total',
                Header: tr('price_total'),
                accessor: 'total',
                Cell: (row) => {
                    const total = row.original.price_sell * row.original.count
                    return <div>{formatCurrency(total)}</div>
                }
            }),
        ];
        if( this.state.company.extranet_active ) {
            if( this.state.section === 1 )
                columns.push({
                    id: 'approver_comment',
                    Header: '',
                    accessor: 'approver_comment',
                    width: 50,
                    className: 'overflowableOnHover approverComment',
                    Cell: row => {

                        if( row.original.status != "rejected" )
                            return false;
                        
                        const approver = row.original.extranet_approvers.find( a => a.crm_contact_id === row.original.approver_id );
                        
                        let icon = "times";
                        let comment = (
                            <div>
                                <strong>{ approver ? approver.contact.name : '-' }</strong><br />
                                <small>{ timeSince( row.original.status_changed_at ) }</small>
                            </div>
                        ); 

                        if( row.original.approver_comment )
                        {
                            icon = "comment";
                            comment = (
                                <div>
                                    <strong>{ approver ? approver.contact.name : '-' }</strong><br />
                                    <small>{ timeSince( row.original.status_changed_at ) }</small>
                                    <hr />
                                    { row.original.approver_comment }
                                </div>
                            ); 
                        }
                                
                        return (
                            <ApTooltip text={ comment }>
                                <SvgIcon icon={ icon } type="solid" />
                            </ApTooltip>
                        );
                    }
                })

            columns.push({
                id: 'approver',
                Header: tr('approver'),
                accessor: 'approver_id',
                width: 150,
                Cell: row => {

                    if( this.state.section === 0 ||
                        this.state.section === 1 && row.original.status === "rejected" )
                    {

                        if (row.original.extranet_approvers.length === 0 && (row.original.project && row.original.project.approval_exception) ) return null

                        let approvers = [{ value: null, label: `- ${tr('select').toUpperCase()} -`, primary: false }];
                        const filteredApprovers = row.original.extranet_approvers.filter( a => a.contact && a.contact.deleted_at === null );
                        filteredApprovers.forEach( a => {
                            approvers.push({
                                value: a.crm_contact_id,
                                label: a.contact.name,
                            });
                        });
                        
                        const changes = this.state.changes.find( c => c.id === row.original.id && "approver_id" in c );
                        let value = changes ? changes.approver_id : row.original.approver_id;
                        
                        return (
                            <div className="approverSelect">
                                <ApInput
                                    type="select"
                                    id={ "approver" + row.index }
                                    name="approver_id"
                                    value={ value }
                                    onChange={ (e) => this.onPoApproverChange( row.original.id, e.target.value ) }
                                    options={ approvers }
                                    validationState={ !value ? "error" : changes ? "warning" : "" }
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading }
                                />
                            </div>
                        ); 
                    }
                    
                    // When not editable, just show approver name
                    const approver = row.original.extranet_approvers.find( a => a.crm_contact_id === row.original.approver_id );
                    return approver ? approver.contact.name : "-";
                }
            });
        }

        columns.push({
            id: 'status',  
            width: 150,
            resizable: false,                     
            Header: tr('status'),
            accessor: (d) => this.editableCellAccessor( d, "status" ),
            Cell: this.renderRowStatus
        });

        return (
            <ApReactTable
                onSelectChange={ ( keys ) => { this.setState({ selectedPoKeys: keys  }) } }
                columns={[{
                    columns: columns
                }]}
                data={ data }
                loading={ this.state.poLoading }
                filterable
                multiselect={ this.getPoSectionActions() }
            />
        );
    }

    getPoSectionActions()
    {
        const actions = this.getBaseActions()

        actions[0].forEach((item) => {
            item.disabled = this.state.selectedPoKeys.length === 0
        })

        actions[0][0].action = (keys) => {
            if (keys.length === 1) {
                this.changeStatus( this.state.company.extranet_active ? "pending" : "approved",  keys, {purchaseOrderRow: true})
            } else {
                this.changeStatus( this.state.company.extranet_active ? "pending" : "approved",  false, {purchaseOrderRow: true})
            }
        }

        actions[0][1].action = (keys) => {
            if (keys.length === 1) {
                this.changeStatus( "not_billable", keys, {purchaseOrderRow: true} )
            } else {
                this.changeStatus( "not_billable", false, {purchaseOrderRow: true} )
            }
        }

        // actions[0].splice(3, 1) //remove customer order number edit.
        // //if restored, remember to add 1 to indeces below

        actions[0][4].action = (keys) => {
            this.onPoRowEdit(keys.length, keys, "count" )
        }

        actions[0][5].action = (keys) => {
            this.onPoRowEdit(keys.length, keys, "price_sell" )
        }

        if (this.state.company.extranet_active) {
            actions[0][3].action = (keys) => this.onPoRowEdit( keys.length, keys, "customer_work_number" )
            actions[0][6].action = (keys) => this.onPoRowEdit(  keys.length, keys, "approver_id" )
        } else {
            actions[0].splice(6, 1)
        }

        return actions[0]
    }

    onPoRowEdit(amount, poIndex, type) {
        const statuses = this.getSectionStatuses();
        
        let entries = this.state.purchaseOrderRows.filter( e => statuses.indexOf( e.status ) != -1 );
        
        let indexes = Array.isArray(poIndex) ? poIndex : [poIndex];
        
        let ids = [];
        let values = [];
        let options = [];

        indexes.forEach( index => {
            ids.push( entries[ index ].id );
            if( values.indexOf( entries[ index ][ type ] ) == -1 )
                values.push( entries[ index ][ type ] );
            
            if( type === "approver_id" )
            {
                entries[ index ].extranet_approvers.forEach( a => {
                    const optIndex = options.findIndex( o => o.value === a.crm_contact_id );
                    if( optIndex != -1 )
                        options[ optIndex ].count++;
                    else 
                        options.push({
                            value: a.crm_contact_id,
                            label: a.contact.name,
                            count: 1,
                        });
                });
            }   
        });

        const groupedOptions = groupify( options, "count" );
        options = [{ value: null, label: ` - ${tr('select').toUpperCase()} - ` }];
        Object.keys( groupedOptions ).forEach( count => {
            options.push({
                label: `${tr('editable_to_rows', [count, ids.length])}:`,
                options: groupedOptions[ count ],
            });
        });

        let info = false;
        let value = '';
        if( values.length == 1 && type != "approver_id" )
        {
            value = values[0];
            info = `${tr('current_value')}: ${values[0]}`;
        }
        else if ( values.length > 1 && type != "approver_id" )
            info = `${tr('current_values')}: ${values.join(", ")}`; // TODO: more readable format for numbers

  
        let valueRequired = true;
        if (type === 'customer_work_number') {
            valueRequired = false;
        }
        

        this.setState({
            editPoModal: true,
            poModalIndex: poIndex,
            poModalType: type,
            poModalValue: Array.isArray(poIndex) ? '' : amount,
            poModalOldValue: amount ? amount : '',
            poModalOptions: options,
            poModalRequired: valueRequired,
        })
    }

    savePoModalChange(item) {
        if (this.state.poModalType !== 'customer_work_number' &&item.toString().includes(',')) {
            item = item.toString().replace(',', '.')
        }
        
        const purchaseOrders = [...this.state.purchaseOrderRows]

        if (this.state.poModalType === 'amount') {
            if (Array.isArray(this.state.poModalIndex)) {
                this.state.poModalIndex.forEach((index) => {
                    purchaseOrders[index].count = item
                })
            } else {
                purchaseOrders[this.state.poModalIndex].count = item
            }
        }

        // if (this.state.poModalType === 'price_sell') {
        //     if (Array.isArray(this.state.poModalIndex)) {
        //         this.state.poModalIndex.forEach((index) => {
        //             purchaseOrders[index].price_sell = item
        //         })
        //     } else {
        //         purchaseOrders[this.state.poModalIndex].price_sell = item
        //     }
        // }
        if (Array.isArray(this.state.poModalIndex)) {
            this.state.poModalIndex.forEach((index) => {
                purchaseOrders[index][this.state.poModalType] = item
            })
        } else {
            purchaseOrders[this.state.poModalIndex][this.state.poModalType] = item
        }

        this.setState({purchaseOrderRows: purchaseOrders})
    }

    render()
    {
        const entryGroups = groupify( this.state.entries, "status" );
        const badges = {
            open:           keyExists( entryGroups, "open", true, [] ).length,
            rejected:       keyExists( entryGroups, "rejected", true, [] ).length,
            approved:       keyExists( entryGroups, "approved", true, [] ).length,
            not_billable:   keyExists( entryGroups, "not_billable", true, [] ).length,
        };

        return (
            <React.Fragment>
                <Prompt
                    when={ this.state.changes.length > 0 }
                    message={ location => {

                        // This prevents confimation when user have already logged out from the system.
                        // For example when idle timer have triggered
                        if( !auth.isLoggedIn() )
                            return true;

                        return tr('unsaved_changes'); 
                    }}
                />

                <div id="pageBillable">
                    <Measure
                        onResize={ () => {
                            if( this.stickyBar )
                                this.stickyBar.updateBounds();
                        }}
                    >
                    {({ measureRef }) =>
                        <div ref={ measureRef }>
                            <div className="apBox">
                                <div className="apBoxHeader">
                                    <h1>{ tr('billing_work') }</h1>
                                    <p>{ tr('billing_work_info') }</p>

                                    {this.state.billingRowsUpdated && <div className="apBoxCorner" style={{margin: '10px 10px 0 5px', display: 'flex'}}>
                                        <div style={{paddingRight: '10px'}}>{ tr('billing_rows_updated') }</div>
                                        <SvgIcon icon="check" type="solid" fill='green'/>
                                        {/* Deprecated: 5.2.2021
                                        <ApButton onClick={ this.refreshEntries } size="small" color="white" loading={ this.state.loading } disabled={ this.state.loading }>
                                            <SvgIcon icon="redo" type="solid" />
                                            Päivitä laskutusrivit
                                        </ApButton>*/}
                                    </div>}
                                </div>

                                <div className="padding">

                                    <div className="billable-content">
                                    <ApInputStack>
                                        <ApInput 
                                            width="200"
                                            type="dateSafe" 
                                            id="date_start" 
                                            name="date_start" 
                                            value={ this.state.date_start }
                                            onChange={ (e) => this.setState({ date_start: e.target.value }, this.fetchData, this.saveStartDate(e.target.value) ) }
                                            clearable
                                            disabled={this.state.changes.length > 0}
                                            autoComplete="off"
                                        />
                                        <ApInput 
                                            width="200"
                                            type="dateSafe" 
                                            id="date_end" 
                                            name="date_end" 
                                            value={ this.state.date_end }
                                            onChange={ (e) => this.setState({ date_end: e.target.value }, this.fetchData, this.saveEndDate(e.target.value) ) }
                                            clearable
                                            disabled={this.state.changes.length > 0}
                                            autoComplete="off"
                                        />
                                    </ApInputStack>

                                    <ApInputStack>
                                    <ApAddon custom gapLeft="10">
                                        <ApOptionInput 
                                            value={ [ this.state.date_start, this.state.date_end ]}
                                            onChange={ value => this.setState({ date_start: value[0], date_end: value[1] }, this.fetchData, this.saveBoth(value[0], value[1]) )}
                                            options={[ "year-1", "year" ]}
                                            disabled={ this.state.changes.length > 0 }
                                        />

                                        <ApOptionInput 
                                            value={ [ this.state.date_start, this.state.date_end ]}
                                            onChange={ value => this.setState({ date_start: value[0], date_end: value[1] }, this.fetchData, this.saveBoth(value[0], value[1]) )}
                                            options={[ "month-3", "month-2", "month-1", "month" ]}
                                            disabled={ this.state.changes.length > 0 }
                                        />
                                    </ApAddon>
                                    </ApInputStack>
                                    </div>

                                    { this.state.filtersFetched ?
                                        <div className="badgeInfo">
                                            <span className={this.state.filter.open ? 'selector': 'dimmed-selector'} onClick={() => this.setFilter('open')}><span className={`apBadge ${this.state.filter.open ? 'blue' : 'grey'}`}></span> { tr('billable_rows') }</span>
                                            <span className={this.state.filter.contract_billing ? 'selector': 'dimmed-selector'} onClick={() => this.setFilter('contract_billing')}><span className={`apBadge ${this.state.filter.contract_billing ? 'teal' : 'grey'}`}></span> { tr('contract_billing_rows') }</span>
                                            <span className={this.state.filter.rejected ? 'selector': 'dimmed-selector'} onClick={() => this.setFilter('rejected')}><span className={`apBadge ${this.state.filter.rejected ? 'red' : 'grey'}`}></span> { tr('rows_reject_by_customer') }</span>
                                            <span className={this.state.filter.approved ? 'selector': 'dimmed-selector'} onClick={() => this.setFilter('approved')}><span className={`apBadge ${this.state.filter.approved ? 'green' : 'grey'}`}></span> { tr('completed_rows') }</span>
                                            <span className={this.state.filter.pending ? 'selector': 'dimmed-selector'} onClick={() => this.setFilter('pending')}><span className={`apBadge ${this.state.filter.pending ? 'yellow' : 'grey'}`}></span> { tr('acceptable') }</span>
                                            <span className={this.state.filter.billed ? 'selector': 'dimmed-selector'} onClick={() => this.setFilter('billed')}><span className={`apBadge ${this.state.filter.billed ? 'violet' : 'grey'}`}></span> { tr('billed') }</span>
                                            <span className={this.state.filter.not_billable ? 'selector': 'dimmed-selector'} onClick={() => this.setFilter('not_billable')}><span className={`apBadge ${this.state.filter.not_billable ? 'silver' : 'grey'}`}></span> { tr('not_billable_rows') }</span>
                                        </div> :
                                        <div className='apInlineLoader' />
                                    }

                                    <ApInputStack>
                                        <ApSelect
                                            label={ tr('company') }
                                            value={ keyExists( this.state, "company.name" ) ? this.state.company.name : "" }
                                            optionRenderer={ company => 
                                                <div className="companyOption">
                                                    <strong>{ company.name }</strong>
                                                    <div className="badges">
                                                        <span className={"apBadge blue" + ( company.counts.open ? "" : " none" ) }>{ company.counts.open }</span>
                                                        <span className={"apBadge teal" + ( company.counts.contract_billing ? "" : " none" ) }>{ company.counts.contract_billing }</span>
                                                        <span className={"apBadge red" + ( company.counts.rejected ? "" : " none" ) }>{ company.counts.rejected }</span>
                                                        <span className={"apBadge green" + ( company.counts.approved ? "" : " none" ) }>{ company.counts.approved }</span>
                                                        <span className={"apBadge yellow" + ( company.counts.pending ? "": " none")}> {company.counts.pending}</span>
                                                        <span className={"apBadge violet" + ( company.counts.billed ? "": " none")}> {company.counts.billed}</span>
                                                        <span className={"apBadge silver" + ( company.counts.not_billable ? "" : " none" ) }>{ company.counts.not_billable }</span> 
                                                    </div>
                                                </div>
                                            }
                                            onChange={ ( company ) =>
                                            { 
                                                window.sessionStorage.setItem('billingCompanyId', JSON.stringify({companyId: company.id, setDate: moment()}))
                                                this.setState({ company }, this.fetchData )
                                            } }
                                            objKeyId="id"
                                            clearable
                                            apiUrl="billable/companies"
                                            apiData={{
                                                date_start: this.state.date_start,
                                                date_end:   this.state.date_end
                                            }}
                                            disabled={ this.state.changes.length > 0 }
                                            useBillingFilters={true}
                                            billingFilters={this.state.filter}
                                        />
                                        <ApAddon 
                                            width="60"
                                            disabled={ this.state.changes.length == 0 } 
                                            onClick={ () => this.setState({ changes: [] })}
                                            tooltip={ tr('undo_changes') }
                                        >
                                            <SvgIcon icon="backspace" type="solid" />
                                            { this.state.changes.length > 0 && 
                                                <span className="changeCounter apBadge orange">{ this.state.changes.length }</span>
                                            }
                                        </ApAddon>
                                    </ApInputStack>

                                </div>
                            </div>

                            { this.state.company && 
                                <div className="apBox">
                                    <div className="apBoxHeader">
                                        <h3>{ this.state.company.name || tr('unknown') }</h3>
                                        <p>
                                            <SvgIcon className="small-inline" icon="handshake" type="solid" /> 
                                            { this.state.company.extranet_active ? tr('extranet_active') : tr('extranet_unactive') }
                                        </p>
                                        <div className="apBoxCorner">
                                            {this.state.selectedKeys.length > 0 ? <ApButton 
                                                onClick={ `../../projects/${this.state.selectedProject}/management` } size="small" color="white" 
                                                disabled={ this.state.changes.length > 0 }>
                                                <SvgIcon icon="project-diagram" type="solid" />
                                                Projektin tiedot
                                            </ApButton> : ''}

                                            <ApButton onClick={ "/crm/company/" + this.state.company.id } size="small" color="white" disabled={ this.state.changes.length > 0 }>
                                                <SvgIcon icon="building" type="solid" />
                                                { tr('company_info') }
                                            </ApButton>
                                        </div>
                                    </div>
                                    <ApTabs onChange={ this.onTabChange } selected={getContinueWithCompany() ? 2 : null} parent='billable'>
                                        <ApTab icon="list" label={ tr('billable_entries') } badge={ badges.open > 0 ? badges.open : false }>
                                            <div className="padding">
                                                { this.renderEntries() }
                                                { this.renderProjectStorage() }
                                                { this.renderPurchaseOrders() }
                                            </div>
                                        </ApTab>
                                        <ApTab icon="handshake" label={ tr('customer_acceptance') } badge={ badges.rejected > 0 ? badges.rejected : false } badgeColor="red">
                                            <div className="padding">
                                                { this.state.company.extranet_active ? this.renderEntries() : 
                                                    <div className="apInfoMsg">
                                                        <h4>{ tr('extranet_unactive_info1') }</h4>
                                                        <p>{ tr('extranet_unactive_info2') }</p>
                                                        <p>{ tr('extranet_unactive_info3') }</p>
                                                    </div>
                                                }
                                            </div>
                                        </ApTab>
                                        <ApTab icon="file-invoice" label={ tr('ready_for_invoicing') } badge={ badges.approved > 0 ? badges.approved : false } badgeColor="green" disabled={ this.state.changes.length > 0 }>
                                            <div className="padding">
                                                { this.renderEntries() }
                                            </div>
                                        </ApTab>

                                        <ApTab icon="ban" label={ tr('not_billable') } align="right" badge={ badges.not_billable > 0 ? badges.not_billable : false } badgeColor="silver">
                                            <div className="padding">
                                                { this.renderEntries(true) }
                                            </div>
                                        </ApTab>
                                        <ApTab icon="file-invoice-dollar" align="right" label={ tr('billed') } disabled={ this.state.changes.length > 0 }>
                                            <div className="padding">
                                                { this.renderEntries(true) }
                                            </div>
                                        </ApTab>
                                    </ApTabs>

                                    <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar= node }>
                                        <div className="stickyFooter">
                                            <div className="info">
                                                { tr('selected_billable_rows', [this.state.selectedKeys.length, this.countEntries()]) }
                                                { tr('selected_total_price') }: { this.getSelectedPrice() } €
                                            </div>
                                            
                                            { this.state.section != 2 &&
                                                <div className="apButtonGroup">

                                                    <ApDropdown
                                                        position="top"
                                                        actions={ this.getSectionActions() }
                                                    />
                                                    
                                                    <div className="divider" />

                                                    <ApButton 
                                                        color="blue" 
                                                        className={ this.state.changes.length > 0 ? "highlight" : "" }
                                                        disabled={ this.state.changes.length == 0 || this.state.loading }
                                                        loading={ this.state.loading }
                                                        onClick={ this.saveChangesWithEmail }
                                                    >
                                                        <SvgIcon icon="save" type="solid" />
                                                        { tr('save_and_send_email') }
                                                    </ApButton>

                                                    <ApButton 
                                                        color="blue" 
                                                        className={ this.state.changes.length > 0 ? "highlight" : "" }
                                                        disabled={ this.state.changes.length == 0 || this.state.loading }
                                                        loading={ this.state.loading }
                                                        onClick={ this.saveChanges }
                                                    >
                                                        <SvgIcon icon="save" type="solid" />
                                                        { tr('save') }
                                                    </ApButton>

                                                </div>
                                            }
                                            { this.state.section == 2 &&
                                                <div className="apButtonGroup">

                                                    <ApButton 
                                                        color="red" 
                                                        disabled={ this.state.selectedKeys.length == 0 }
                                                        loading={ this.state.loading }
                                                        onClick={ this.cancelSelected }
                                                    >
                                                    <SvgIcon icon="ban" type="solid" />
                                                        { tr('cancel_billing') }
                                                    </ApButton>

                                                    <ApButton 
                                                        color="blue" 
                                                        disabled={ this.state.selectedKeys.length == 0 }
                                                        loading={ this.state.loading }
                                                        onClick={ this.createNewOrder }
                                                    >
                                                        <SvgIcon icon="receipt" type="solid" />
                                                        { tr('make_sales_order') }
                                                    </ApButton>

                                                </div>
                                            }
                                        </div>
                                    </ApStickyBar>

                                    
                                    <NewOrder
                                        show={ Boolean( this.state.newOrder.show ) }
                                        customerId={ this.state.company.id }
                                        type={ this.state.newOrder.type }
                                        components={this.state.newOrder.components }
                                        comesfromBillable={true}
                                        whoUse={"billable"}
                                        onClose={ () => { this.setState({ newOrder: {} }) } }
                                    />
                                    
                                </div>
                            
                            }
                        </div>
                    }
                    </Measure>

                    <ApModalInput 
                        show={ this.state.editModal.show }
                        value={this.state.editModal.value ? ((this.state.editModal.field === "unit_price" || this.state.editModal.field === "amount") ? this.state.editModal.value.toString().replace(".", ",") : this.state.editModal.value) : this.state.editModal.value}
                        onSave={this.onEditModalSave}
                        onClose={ this.onEditModalClose }
                        title={ this.state.editModal.ids.length <= 1 ?
                            tr('edit_row') :
                            tr('edit_rows_info', [this.state.editModal.ids.length])
                        }
                        label={ this.getFieldName( this.state.editModal.field ) }
                        info={ this.state.editModal.info }
                        type={ this.state.editModal.field === "approver_id" ? "select" : null }
                        options={ this.state.editModal.options || [] }
                        required={this.state.editModal.required }
                    />

                    <ApModalInput 
                        show={ this.state.editComponentModal }
                        value={this.state.componentModalValue ? this.state.componentModalValue.toString().replace(".", ",") : this.state.componentModalValue }
                        onSave={ (item) => this.saveComponentModalChange(item) }
                        onClose={ () => this.setState({editComponentModal: false}) }
                        title={Array.isArray(this.state.componentModalIndex) ? tr('edit_rows') : tr('edit_row')}
                        label={this.state.componentModalType === 'amount' 
                            ? tr('amount') 
                            : this.state.componentModalType === 'customer_work_number' 
                                ? tr('customer_work_number') 
                                : tr('unit_price')}
                        info={Array.isArray(this.state.componentModalIndex) ? tr('edit_row_values', [this.state.componentModalIndex.length]) : `${tr('current_value')}: ${this.state.componentModalOldValue}`}
                        options={ this.state.editModal.options || [] }
                        required={this.state.editModal.required}
                    />
                    
                    <ApModalInput 
                        show={ this.state.editPoModal }
                        value={this.state.poModalValue ? this.state.poModalValue.toString().replace(".", ",") : this.state.poModalValue }
                        onSave={ (item) => this.savePoModalChange(item) }
                        onClose={ () => this.setState({editPoModal: false}) }
                        title={Array.isArray(this.state.poModalIndex) ? tr('edit_rows') : tr('edit_row')}
                        label={this.getFieldName( this.state.poModalType )}
                        info={Array.isArray(this.state.poModalIndex) ? tr('edit_row_values', [this.state.poModalIndex.length]) : `${tr('current_value')}: ${this.state.poModalOldValue}`}
                        options={ this.state.poModalOptions || [] }
                        type={this.state.poModalType === "approver_id" ? "select" : null}
                        required={this.state.poModalRequired}
                    />

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    apOrderSettings: state.apOrderSettings,
})

export default connect(mapStateToProps)(Billable);


